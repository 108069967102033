
import "./CustomButtonsComponents.scss";
import MessageStore from "../../../../../managers/ConsoleMessageManager";

import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { designData } from "../../../../../config/fields";

import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward, IoIosArrowUp, IoMdTime, IoIosSpeedometer } from "react-icons/io";
import { MdOutlineSocialDistance, MdBrandingWatermark } from "react-icons/md";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import { BsAlignBottom, BsAlignCenter, BsAlignEnd, BsAlignStart, BsAlignTop } from "react-icons/bs";
import { IoMdSave } from "react-icons/io";
import InteractiveBattles from "../InteractiveScreen/components/BattlesCreator/Battles";

const width = 1920;
const height = 1080;
const tileSize = 30;

const rows = Math.floor(height / tileSize);
const cols = Math.floor(width / tileSize);

const Grid = ({ buttonData }) => {
    const parsedMaxWidth = buttonData?.maxWidth;
    const parsedMaxWidthHeight = buttonData?.maxHeight;
    const parsedLeft = buttonData?.left;
    const parsedTop = buttonData?.top;

    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [elementRef, buttonData]);

    return (
        <div className="graphicsPositionChange-body">
            <div className="graphicsPositionChange-body-inner">
                <svg width={cols * tileSize} height={rows * tileSize}>
                    {Array.from({ length: rows }, (_, rowIndex) => (
                        Array.from({ length: cols }, (_, colIndex) => (
                            <rect
                                key={`rect_${rowIndex}_${colIndex}`}
                                x={colIndex * tileSize}
                                y={rowIndex * tileSize}
                                width={tileSize}
                                height={tileSize}
                                fill={(rowIndex + colIndex) % 2 === 0 ? '#d8d8d8' : '#eaeaea'}
                            />
                        ))
                    ))}
                </svg>
                {
                    buttonData &&
                    <div
                        className="graphics-element"
                        ref={elementRef}
                        style={{left: parsedLeft, top: parsedTop, width: parsedMaxWidth, height: parsedMaxWidthHeight}}
                    >
                        {`[ left: ${buttonData?.left}px, top: ${buttonData?.top}px ]`}
                    </div>
                }
            </div>
        </div>
    );
};


export const GraphicsPositionChange = ({socket}) => {

    const config = useSelector(state => state.config);
    const props = useSelector(state => state.props);

    const buttonData = config.buttons?.[props?.[0]];
    const [position, setPosition] = useState({});
    const [inc, setInc] = useState(45);

    let w;
    let h;

    if (designData[config?.style]?.sizes?.[props?.[0]]) {
        w = designData[config?.style]?.sizes?.[props?.[0]].w;
        h = designData[config?.style]?.sizes?.[props?.[0]].h;
    }

    useEffect(() => {
            setPosition({
                left: buttonData?.left, top: buttonData?.top,
                maxWidth: w || buttonData?.maxWidth, maxHeight: h || buttonData?.maxHeight
            })
    }, [props]);

    const calculateMax = (box, what) => {
        return what / inc - box / inc;
    };

    const saveElement = () => {
        socket.emit("update", {what: 2, data: [props?.[0], position.left, position.top]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    const centerElement = () => {
        const centerX = (1920 / inc) / 2;
        const centerY = (1080 / inc) / 2;

        const elementOffsetX = Math.round((w || buttonData?.maxWidth) / inc / 2);
        const elementOffsetY = Math.round((h || buttonData?.maxHeight) / inc / 2);

        setPosition({
            ...position,
            left: Math.round(((centerX - elementOffsetX) * inc) / inc) * inc,
            top: Math.round(((centerY - elementOffsetY) * inc) / inc) * inc,
        });
    };

    return (
        <div className="graphicsPositionChange-wrapper">
            <div className="graphicsPositionChange-position-buttons">
                <span>{props?.[1]?.name}</span>
                <hr />
                <div className="flex items-center">
                    <div>
                        <label
                            className="m-0"
                            htmlFor="rangex"
                        >Offset x</label>
                        <input
                            className="h-1 bg-[#222222] appearance-none rounded-sm range-sm cursor-pointer accent-[#848484]"
                            id="rangex"
                            type="range"
                            step={1}
                            min={0}
                            max={calculateMax(w || buttonData?.maxWidth, 1920)}
                            onChange={(e) => setPosition({...position, left: e.target.value * inc})}
                            value={position.left / inc}
                        />
                    </div>
                    <span className="bg-[#222222] h-[30px] px-[3px] text-wrap rounded-sm flex items-center text-white mr-2"
                    >{`${position.left} px`}</span>
                </div>
                <div className="flex items-center">
                    <div>
                        <label
                            className="m-0"
                            htmlFor="rangey"
                        >Offset y</label>
                        <input
                            className="h-1 bg-[#222222] appearance-none rounded-sm range-sm cursor-pointer accent-[#848484]"
                            id="rangey"
                            type="range"
                            step={1}
                            min={0}
                            max={calculateMax(h || buttonData?.maxHeight, 1080)}
                            onChange={(e) => setPosition({...position, top: e.target.value * inc})}
                            value={position.top / inc}
                        />
                    </div>
                    <span className="bg-[#222222] h-[30px] px-[3px] text-wrap rounded-sm flex items-center text-white"
                    >{`${position.top} px`}</span>
                </div>
                <hr />
                <div className="flex items-center">
                    <p>Increment</p>
                    <input
                        className="!border-[1px] !border-[#e4e4e7] w-[50px] h-[30px] mx-2 pl-1 rounded-sm bg-[#f9f9fa] text-[#222222]"
                        type="number"
                        value={inc}
                        onChange={(e) => setInc(
                            e.target.value < 0 ? 0 : e.target.value > 100 ? 100 : Number(e.target.value)
                        )}
                    />
                    <p>px</p>
                </div>
                <hr />
                <button onClick={() => saveElement()}><IoMdSave size={"25px"}/></button>
            </div>
            <div className="graphicsPositionChange-controls-screen">
                <div className="graphicsPositionChagne-controls-sliders">
                    <button><BsAlignStart size={"18px"} onClick={() => setPosition({...position, left: 0})}/></button>
                    <button><BsAlignEnd size={"18px"} onClick={() => setPosition({...position, left: ((tileSize * cols) - position.maxWidth)})}/></button>
                    <button><BsAlignTop size={"18px"} onClick={() => setPosition({...position, top: 0})} /></button>
                    <button><BsAlignBottom size={"18px"} onClick={() => setPosition({...position, top: ((tileSize * rows) - position.maxHeight)})}/></button>
                    <button><BsAlignCenter size={"18px"} onClick={() => centerElement()}/></button>
                    <hr />
                    <button onClick={() => setPosition({...position, left: position.left <= 0 ? 0 : position.left - inc})}
                    ><IoIosArrowBack size="25px"/></button>
                    <button
                        onClick={() => setPosition({...position, top: position.top <= 0 ? 0 : position.top - inc})}
                    ><IoIosArrowUp size="25px"/></button>
                    <button
                        onClick={() => setPosition({...position, top: position.top >= (1080 - position.maxHeight) ? (1080 - position.maxHeight) : position.top + inc})}
                    ><IoIosArrowDown size="25px"/></button>
                    <button onClick={() => setPosition({...position, left: position.left >= (1920 - position.maxWidth) ? (1920 - position.maxWidth) : position.left + inc})}
                    ><IoIosArrowForward size="25px"/></button>
                </div>
                <Grid buttonData={position}/>
                <div className="graphicsPositionChange-minihud">
                    <div className="graphicsPositionChange-minihud-grid">
                        <div
                            className="graphicsPositionChange-minihud-item"
                            style={{
                                width: `${position.maxWidth / 12}px`,
                                height: `${position.maxHeight / 12}px`,
                                left: `${position.left / 12}px`,
                                top: `${position.top / 12}px`
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const OpenWeatherSelector = ({socket}) => {
    const mapRef = useRef(null);
    const [coords, setCoords] = useState([]);

    const config = useSelector(props => props.config);

    useEffect(() => {
        const map = L.map(mapRef.current).setView([0,0], 2);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '',
        }).addTo(map);

        const onMapClick = (event) => {
            const { lat, lng } = event.latlng;
            setCoords([lat, lng]);
        };
        map.on('click', onMapClick);
        return () => {
            map.off("click", onMapClick);
            map.remove();
        }
    }, []);

    const fetchData = () => {
        if (coords.length !== 2) { return; }
        socket?.emit("update", {what: 4,
            data: [...coords, document.getElementById("city_input").value]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="w-full h-full p-2 font-geist flex gap-2">
            <div className="w-[calc(50%-4px)] h-full rounded-md" ref={mapRef} />
            <div className="w-[calc(50%-4px)] h-full rounded-md text-[#222222]">
                <div className="w-full h-[calc(45%-4px)] mb-2 rounded-md p-2 border border-[#e4e4e7] flex flex-col items-center">
                    <header className="text-[1.1rem] font-bold">Select your location</header>
                    <span className="py-1 px-3 rounded-md mb-2 text-center bg-[#f9f9fa]">
                        {
                            coords?.length > 0 ?
                            "Coordinates successfully selected 📍"
                            :
                            "Click anywhere in the map to get coordinates of your race location 📍"
                        }
                    </span>
                    <div className="w-full">
                        <label for="city_input">Track locaton name</label>
                        <input
                            className="w-full h-[40px] rounded-md border border-[#e4e4e7] bg-[#f9f9fa] px-2"
                            id="city_input"
                            type="text"
                            placeholder="Name of race location..."
                        />
                    </div>
                    <button
                        className="h-[40px] px-4 rounded-md bg-[#333333] text-white cursor-pointer mt-2"
                        onClick={() => fetchData()}
                    >Save location data</button>
                </div>
                <div className="w-full h-[calc(55%-4px)] p-2 rounded-md border border-[#e4e4e7] flex flex-col items-center">
                    <header className="px-4 py-2 rounded-md bg-[#f9f9fa]">Current location data 🗺️</header>
                    <div className="flex justify-between px-4 py-2 rounded-md bg-[#f9f9fa] w-full mt-1 border border-[#e4e4e7]">
                        <p>City</p><span>{config?.weatherData?.city}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 rounded-md bg-[#f9f9fa] w-full mt-1 border border-[#e4e4e7]">
                        <p>Code</p><span>{config?.weatherData?.weatherCode}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 rounded-md bg-[#f9f9fa] w-full mt-1 border border-[#e4e4e7]">
                        <p>Temperature</p><span>{config?.weatherData?.temperature}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 rounded-md bg-[#f9f9fa] w-full mt-1 border border-[#e4e4e7]">
                        <p>Wind direction</p><span>{config?.weatherData?.windDirection}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 rounded-md bg-[#f9f9fa] w-full mt-1 border border-[#e4e4e7]">
                        <p>Wind speed</p><span>{config?.weatherData?.windSpeed}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const AnnouncerDataSelector = ({socket}) => {

    const config = useSelector(props => props.config);
    const message = config?.announcerMessage;

    const saveMessage = () => {
        socket.emit("update", {what: 5, data:
            ["announcerMessage", document.getElementById("announcerMessage-textarea").value]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    }

    return (
        <div className="w-full h-full p-2 text-[#222222] font-geist">
            <header className="w-full flex items-center justify-center h-[40px] rounded-md bg-[#f9f9fa] mb-2">
                <span>Announcer message</span>
            </header>
            <textarea
                type="text"
                id="announcerMessage-textarea"
                className="border border-[#e4e4e7] w-full h-[400px] rounded-md p-2 bg-[#f9f9fa]"
                placeholder="Enter message..."
                defaultValue={message}
            />
            <button
                className="px-5 h-[40px] bg-[#333333] text-white rounded-md cursor-pointer mt-2 mb-2"
                onClick={() => saveMessage()}
            >Save message</button>
        </div>
    );
};

export const SetResultsByClass = ({socket}) => {

    const config = useSelector(state => state.config);

    useEffect(() => {

    }, [config?.competitorClasses])

    const setClass = (item) => {
        socket.emit("update", {what: 5, data: ["selectedClass", item]}, (callback) => {
            MessageStore.addMessage(callback);
        })
    };

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <header>Class</header>
            <div className="verticalScoreboardDataType-body">
                {
                    config?.competitorClasses ?
                    config?.competitorClasses
                        .filter((item) => item)
                        .map((item, index) =>
                        <button
                            className={config?.selectedClass === item ? "selected" : ""}
                            key={"res_by_class_btn" + index}
                            onClick={() => setClass(item)}
                        ><p>{item}</p></button>
                    )
                    :
                    <p>No classes found..</p>
                }
            </div>
        </div>
    );
};

export const BattlesQuickSelectionWidget = ({ socket }) => {

    const config = useSelector(state => state.config);

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <InteractiveBattles small={true} config={config} socket={socket}/>
        </div>
    );
};

export const VerticalScoreboardDataType = ({socket}) => {

    const config = useSelector(state => state.config);

    const [mode, setMode] = useState(config?.buttons?.verticalScoreboard?.mode);

    const buttons = [
        {
            name: "time",
            description: "Display data based on difference between competitor and the leader.",
            icon: <IoMdTime size={"15px"} />
        },
        {
            name: "speed",
            description: "Display competitors average speed during last lap.",
            icon: <IoIosSpeedometer size={"15px"}/>
        },
        {
            name: "gap",
            description: "Display data based on how much time competitors are behind each other.",
            icon: <MdOutlineSocialDistance size={"15px"}/>
        },
        {
            name: "brand",
            description: "Display competitors vehicle brand.",
            icon: <MdBrandingWatermark size={"15px"} />
        }
    ];

    useEffect(() => {
        setMode(config?.buttons?.verticalScoreboard?.mode);
    }, [config?.buttons?.verticalScoreboard?.mode])

    const handleButtonChange = (name) => {
        socket?.emit("update", {
            what: 6,
            data: ["verticalScoreboard", "mode", name]
        }, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <header>Data type</header>
            <div className="verticalScoreboardDataType-body">
                {
                    buttons.map((btn) =>
                        <button
                            className={mode === btn.name ? "selected" : ""}
                            onClick={() => handleButtonChange(btn.name)}
                            key={`verticalScoreboardDataType_${btn.name}`}
                        >
                            <p>{btn.icon}{btn.name.charAt(0).toUpperCase() + btn.name.slice(1)}</p>
                        </button>
                    )
                }
            </div>
        </div>
    );
};

export const SetStartGridMode = ({ socket }) => {

    const data = [
        {n: "Karting", i: "Karting"},
        {n: "Motocross", i: "Motocross"},
        {n: "Rallycross Latvia", i: "Rallycross_lv"},
        {n: "Rallycross European qualifiers", i: "Rallycross_eu_q"}
    ];

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <header>Grid type</header>
            <div className="verticalScoreboardDataType-body">
                {
                    data.map((item, index) =>
                        <button key={`startGridMode_${index}`} title={item.n}>
                            <p><img src={`/svg/${item.i}.svg`}/></p>
                        </button>

                    )
                }
            </div>
        </div>
    );
}
