
export const Box = ({ className = '', children }) => {
    return (
        <div className={`w-fit h-fit p-[10px] rounded-[5px] ` + className }>
            {children}
        </div>
    );
}

export const BoxHeader = ({ className = '', children}) => {
    return (
        <header className={`w-fit h-fit ` + className }>{children}</header>
    );
}