import { useState } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";
import { useSelector } from "react-redux";

const InteractiveBattles = (props) => {

    const data = useSelector(state => state.data);
    const raceData = data?.data;

    const config = useSelector(state => state.config);

    const [selectedCompetitors, setSelectedCompetitors] = useState(config?.battleCompetitors || []);

    const saveBattleCompetitors = () => {

        if (!selectedCompetitors) return;

        props.socket.emit("update", {what: 3, data: {remove: false, data: selectedCompetitors}}, (response) => {
            MessageStore.addMessage(response);
        });
    };
    const resetBattleCompetitors = () => {
        props.socket.emit("update", {what: 3, data: {remove: true}}, (response) => {
            MessageStore.addMessage(response);
            setSelectedCompetitors(config?.battleCompetitors)
        });
    };

    const toggleCompetitor = (index) => {
        const alreadySelected = selectedCompetitors.includes(index + 1);
        let newSelectedCompetitors;

        if (alreadySelected) {
            newSelectedCompetitors = selectedCompetitors.filter(i => i !== index + 1);
        } else {
            const hasSelectedNeighbors = selectedCompetitors.includes(index) || selectedCompetitors.includes(index + 2);
            if (selectedCompetitors.length === 0 || hasSelectedNeighbors) {
                newSelectedCompetitors = [...selectedCompetitors, index + 1];
                if (newSelectedCompetitors.length > 3) {
                    newSelectedCompetitors.shift();
                }
            } else {
                newSelectedCompetitors = [];
                newSelectedCompetitors.push(index + 1);
            }
        }

        setSelectedCompetitors(newSelectedCompetitors);
    };

    // No data, dont display anything
    if (!raceData)
        return (
            <div className="w-full h-full font-geist text-[#222222] flex flex-col items-center justify-center">
                <p className="text-[1.3rem] font-bold">No data received</p>
                <span>Check your race connection or incomming data feed</span>
                {
                    config?.battleCompetitors?.length > 0 &&
                    <button
                        className="w-[200px] h-[40px] flex items-center justify-center rounded-[5px] bg-[#5d60ef] text-white mt-3 shadow-md"
                        onClick={() => resetBattleCompetitors()}
                    >Reset selected battle</button>
                }
            </div>
        )

    if (props?.small) {
        return (
            <div className="w-full h-full font-geist">
                <div className="w-full h-[110px] overflow-y-auto text-[#222222]">
                {
                    raceData && raceData?.allCompetitors?.map((competitor, index) =>
                        <div
                            className={`w-[55px] h-[25px] bg-[#e4e4e7] rounded-[5px] mb-1
                            flex flex-shrink-0 items-center justify-center cursor-pointer hover:bg-[#222222] hover:text-white
                            text-[1.1rem] ${selectedCompetitors.includes(index + 1) ? '!bg-[#5d60ef] text-white' : ''}`}
                            key={`battle_competitor_${index}${competitor.nr}`}
                            onClick={() => toggleCompetitor(index)}
                        >
                            {index + 1}
                        </div>
                    )
                }
                </div>
                <div className="w-full h-[45px] mt-1">
                    <button
                        className="w-full flex items-center justify-center rounded-[5px] bg-[#333333] text-white mb-1"
                        onClick={() => saveBattleCompetitors()}
                    >Save</button>
                    <button
                        className="w-full flex items-center justify-center rounded-[5px] bg-[#333333] text-white"
                        onClick={() => resetBattleCompetitors()}
                    >Clear</button>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-full font-geist text-[#222222]">
            <div className="w-full h-fit p-[10px]">
                <header className="w-full flex flex-col gap-2 text-center">
                    <span className="mt-2 text-[1.2rem]">Select positions for battle</span>
                    <span className="mb-2 text-[.85rem]">
                        You can select up to 3 competitors for battle by clicking on them.
                        Clicking on a competitor that is not next to already selected will
                        reset the selection.
                    </span>
                </header>
                <div className="w-full flex flex-wrap gap-3">
                    {
                        raceData && raceData?.allCompetitors?.map((competitor, index) =>
                            <div
                                className={`w-[90px] h-[90px] bg-[#e4e4e7] rounded-[5px]
                                flex flex-shrink-0 items-center justify-center cursor-pointer hover:bg-[#222222] hover:text-white
                                text-[1.1rem] ${selectedCompetitors.includes(index + 1) ? '!bg-[#5d60ef] text-white' : ''}`}
                                key={`battle_competitor_${index}${competitor.nr}`}
                                onClick={() => toggleCompetitor(index)}
                            >
                                {index + 1}
                            </div>
                        )
                    }
                </div>
                <hr className="border-none w-full h-[1px] bg-[#e4e4e7] my-[10px]" />
            </div>
            <div className="w-full h-fit p-[10px] flex justify-center gap-5">
                <button
                    className="w-[200px] h-[40px] flex items-center justify-center rounded-[5px] bg-[#333333] text-white"
                    onClick={() => saveBattleCompetitors()}
                >Save battle</button>
                <button
                    className="w-[200px] h-[40px] flex items-center justify-center rounded-[5px] bg-[#333333] text-white"
                    onClick={() => resetBattleCompetitors()}
                >Remove battle</button>
            </div>
        </div>
    );
};

export default InteractiveBattles;