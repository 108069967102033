
import { useEffect, useState } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager"
import { useSelector } from "react-redux";
import { Button } from "../../../../../../../components/ui/button";

// Icons
import { MdOutlineSearchOff, MdEdit, MdDelete } from "react-icons/md";
import { IoIosColorPalette } from "react-icons/io";

import { ConfirmationNewPalette } from "../../../ConfirmAction";


const InteractiveMyDesigns = ({socket}) => {

    const config = useSelector(state => state.config);

    const [ designs, setDesigns ] = useState([]);

    const [ createPalette, setCreatePalette ] = useState(false);
    const [ editPalette, setEditPalette ] = useState(false);
    const [ editablePalette, setEditablePalette ] = useState(null);

    const getDesigns = async () => {
        await socket.emit('design', { action: "GET_ALL" }, (response) => {
            setDesigns(response?.data);
        });
    };

    const deleteDesign = async (id) => {
        await socket.emit('design', { action: "DELETE", data: id }, async (response) => {
            await getDesigns();
        });
    }

    const setDesign = async (design) => {
        await socket.emit('update', { what: 5, data: ['palette', design]}, (response) => {

        });
    }


    useEffect(() => {
        getDesigns();
    }, []);

    const colorItemMap = [
        {name: "Primary", key: "primary"},
        {name: "Secondary", key: "secondary"},
        {name: "Accent", key: "accent"},
        {name: "Row Even", key: "even"},
        {name: "Row Odd", key: "odd"},
    ];

    return (
        <div className="w-full h-full flex font-geist text-[#222222]">
            {
                createPalette && <ConfirmationNewPalette socket={socket} callback={() => setCreatePalette(false)} reload={getDesigns} colors={{}}/>
            }
            {
                editPalette && <ConfirmationNewPalette socket={socket} callback={(colorObject) => { setEditPalette(false); colorObject === typeof Object && setDesign(colorObject)}} newPalette={false} colors={editablePalette} reload={getDesigns}/>
            }
            <div className="w-full h-full flex flex-col items-center p-[15px] relative">
                <div className="h-[full] w-full flex gap-[5px] overflow-x-auto overflow-scrollbar-h">
                {
                    designs?.length ?
                    <>
                    {
                        [...designs].sort((a, b) => b._id === config?.palette?._id ? 1 : -1).map((design, index) =>
                            <div
                                className="w-[250px] h-fit p-[10px] border-[1px] border-[#e4e4e7] rounded-md shadow-md flex-shrink-0 mb-2"
                                key={`design_item_${index}`}
                            >
                                <h1 className="text-center text-[1.2rem] font-[500]">{design?.name}</h1>
                                <hr className="mb-4 mt-2"/>
                                {
                                    colorItemMap.map((colorItem, index2) =>
                                        <div
                                            className="w-full h-[40px] mb-2 flex items-center justify-between"
                                            key={`color_item_${index2}_${index}`}
                                        >
                                            <p>{`${colorItem.name}:`}</p>
                                            <p
                                                className="w-[80px] h-[40px] rounded-md border-[1px] border-[#e4e4e7]"
                                                style={{ background: design.palette[colorItem.key]}}
                                            />
                                        </div>
                                    )
                                }
                                <Button
                                    className={`w-full mt-2 hover:bg-[#5d60ef] ${
                                        config?.palette?._id === design?._id ? "bg-[#5d60ef]" : "bg-[#333333]"
                                    }`}
                                    onClick={() => setDesign(design)}
                                >{config?.palette?._id === design?._id ? "Selected palette" : "Use this palette"}</Button>
                                <div className="w-full flex gap-[5px] mt-2">
                                    <Button
                                        className='bg-[#333333] hover:bg-[#5d60ef]'
                                        onClick={() => {
                                            setEditPalette(true);
                                            setEditablePalette(design);
                                        }}
                                    ><MdEdit size={"20px"}/></Button>
                                    <Button
                                        onClick={() => deleteDesign(design?._id)}
                                        className='bg-[#333333] hover:bg-[#5d60ef]'
                                        disabled={config?.palette?._id === design?._id ? true : false}
                                    ><MdDelete size={"20px"}/></Button>
                                </div>
                            </div>
                        )
                    }
                    </>
                    :
                    <div className="no-designs-placeholder">
                        <MdOutlineSearchOff size={"30%"}/>
                        <p>No designs found to display</p>
                        <Button className='bg-[#333333] hover:bg-[#5d60ef] mt-2 px-9'>Reload</Button>
                    </div>
                }
                </div>
                <div className="absolute bottom-[10px] left-[10px] flex items-center gap-[5px]">
                    <Button
                        className='gap-[5px] bg-[#333333] hover:bg-[#5d60ef]'
                        onClick={() => setCreatePalette(true)}
                    >
                        <IoIosColorPalette size={"18px"}/>
                        <p>Create new color palette</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InteractiveMyDesigns;