import "./Admin.css";

import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/useAuth";

// Components for admin panel
import ControlPanelComponent from "./AdminPanelComponents/ControlPanel/ControlPanel";
import PageNotFound from "./AdminPanelComponents/NotFound/NotFound";
import { getRaceBackendAddress } from "../../Functions";

import Timestamps from "./AdminPanelComponents/ControlPanel/HorizontalBar/GraphTimestamps";

const GRAPHICS_SERVER_ADDRESS = getRaceBackendAddress();

const Admin = React.memo(() => {

    const { auth } = useAuth();

    const dispatch = useDispatch();
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState({});

    useEffect(() => {

        const connectToSocketServer = async () => {
            const sock = io.connect(GRAPHICS_SERVER_ADDRESS, {
                query: {
                    userType: "Admin Panel",
                    userId: auth.userId,
                    userName: auth.userName
                }
            });
            setSocket(sock);
            setLoading(false);
        }

        connectToSocketServer();
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on("getApi", (data) => {
                dispatch({ type: "SET_DATA", payload: data });
                Timestamps.addTimestamp(Date.now());
            });
            socket.on("getConfig", (data) => {
                dispatch({ type: "SET_CONFIG", payload: data });
                setConfig(data);
            });
            socket.on("sendErrors", (data) => {
                dispatch({ type: "SET_ERROR", payload: data });
            });
        }
    }, [socket]);

    const ret = {
        loading: <div>Loading...</div>,
        ok: <div className="app__admin">
                <Routes>
                    <Route path={"/controlPanel"} element={ <ControlPanelComponent socket={socket} config={config} /> } />
                    <Route path="*" element={<PageNotFound socket={socket}/>} />
                </Routes>
            </div>
    }

    return loading ? ret.loading : ret.ok;
});
export default Admin;