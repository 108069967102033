import { useState, useEffect, createContext, useContext } from "react";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ScheduleContext = createContext();

export const useSchedule = () => {
    return useContext(ScheduleContext);
}

export const ScheduleProvider = ({ children }) => {
    const [loadingSchedule, setLoadingSchedule] = useState(true);
    const [schedule, setSchedule] = useState([]);

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const getSchedule = async () => {
        setLoadingSchedule(true);
        try {
            const response = await axiosPrivate.post('/getSchedule', { userId: auth.userId });
            setSchedule(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingSchedule(false);
        }
    }

    const setNewSchedule = async (newSchedule) => {
        setLoadingSchedule(true);
        try {
            await axiosPrivate.post('/setSchedule', { userId: auth.userId, schedule: newSchedule });
            await getSchedule();
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingSchedule(false);
        }
    };

    useEffect(() => {
        getSchedule();
    }, []);

    const value = { schedule, loadingSchedule, setNewSchedule };

    return (
        <ScheduleContext.Provider value={value}>
            {children}
        </ScheduleContext.Provider>
    );
}