
import "./ReplayWidget.scss";

const ReplayWidget = (props) => {

    const palette = props?.config?.palette?.palette;

    switch (props.config?.style) {
        case "v2":
            return (
                <div
                    className="app__replay_v2_outer"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.replay?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.replay?.left,
                        background: palette?.primary,
                        borderBottom: `8px solid ${palette?.accent}`
                    }}
                >
                    <p>REPLAY</p>
                    <div className="blinking_circle" />
                </div>
            );
        case "k1":
            return (
                <div
                    className="k1_replay"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.replay?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.replay?.left
                    }}
                >
                    <p>REPLAY</p>
                    <div className="blinking_circle" />
                </div>
            );
    }
};
export default ReplayWidget;
