import { useEffect, useState, useCallback } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";

import { useSelector } from "react-redux";

// Configuration settings for setting up grids correctly

const gridConfigs = {
    karting: { mode: '1', columns: '2', lines: '2', side: "right" },
    moto: { mode: '5', columns: '2', lines: '3', side: "right" },
    rally_lv: { mode: '2', columns: '3', lines: '3', side: "right" },
    rally_eu_q: { mode: '3', columns: '2', lines: '2', side: "right" }
};

const gridTemplateItems = [
    {name: "Karting", img: "Karting", options: gridConfigs.karting, desc: "Starting grid configured in two columns where two competitors are placed next to each other."},
    {name: "Motocross", img: "Motocross", options: gridConfigs.moto, desc: "Grid configured in a single collumn more where competitors are displayed in a tower in pages of 15."},
    {name: "Rallycross Latvia", img: "Rallycross_lv", options: gridConfigs.rally_lv, desc: "3 columns where in each row competitors are displayed in a diagonal pattern depending on first turn."},
    {name: "Rallycross EU Q", img: "Rallycross_eu_q", options: gridConfigs.rally_eu_q, desc: "Rallycross qualifier specific grid where competitors are displayed in a row of 5 max."}
];

const InteractiveStartGrid = ({ socket }) => {

    const config = useSelector(state => state.config);

    const [gridOptions, setGridOptions] = useState(null);
    const [selectedButton, setSelectedButton] = useState(
        config?.buttons?.startGrid?.configuration?.mode
    );

    useEffect(() => {
        const configuration = config.buttons?.startGrid?.configuration;
        setGridOptions(configuration);
    },[]);


    const updateGridOptions = useCallback((newOptions) => {
        setSelectedButton(newOptions.mode);
        setGridOptions(prevOptions => ({...prevOptions, ...newOptions}));
    }, []);

    const setSelectedSettings = useCallback(() => {
        socket.emit("update", {what: 1, data: gridOptions}, (response) => {
            MessageStore.addMessage(response);
        });
    }, [socket, gridOptions]);

    const isActive = (mode) => {
        return mode === config?.buttons?.startGrid?.configuration?.mode;
    }
    const isSelected = (mode) => {
        return mode === selectedButton;
    }

    return (
        <div className="w-full h-full font-geist p-3 text-[#222222]">
            <header>
                <span className="text-[1.4rem] font-bold">Start grid settings</span>
            </header>
            <p>Select a grid template that best suits your race to configure and start using your starting grid graphic.</p>
            <div className="w-full flex items-center justify-center gap-2 py-3">
                {
                    gridTemplateItems.map((item, index) =>
                        <div
                            className={
                                `w-[240px] p-3 h-[300px] border border-[#e4e4e7] rounded-md shadow-md relative cursor-pointer
                                    ${isActive(item.options.mode) ? 'bg-[#e4e4e7] text-[#222222]' : 'bg-white text-[#222222]'}
                                `
                            }
                            key={`grid_template_item_${index}`}
                        >
                            <header className="w-full flex justify-between pb-1">
                                <span className="text-[1.1rem] font-bold">{item.name}</span>
                                <img className={`
                                        w-8 h-8 p-2 rounded-md shadow-sm ${isActive(item.options.mode) ? 'bg-white text-[#333333]' : 'bg-[#333333] text-white'}
                                    `} src={`/svg/${item.img}.svg`}/>
                            </header>
                            <h4 className="mt-2">{item?.desc}</h4>
                            <button
                                className={
                                    `w-[calc(100%-20px)] h-[40px] absolute bottom-2 text-white ${
                                        !isSelected(item.options.mode) ? 'bg-[#333333]' : 'bg-[#5d60ef]'
                                    } rounded-md cursor-pointer
                                    }`
                                }
                                onClick={() => updateGridOptions(item.options)}
                            >
                                {isActive(item.options.mode) ? "Selected" : "Select"}
                            </button>
                        </div>
                    )
                }
            </div>
            <hr className="w-full h-[1px] border-none bg-[#e4e4e7] my-3"/>
            <div>
                {
                    gridOptions && [1,2,3].includes(Number(gridOptions.mode)) &&
                    <div className="mt-4">
                        <p className="text-center mb-3">Select the direction of first turn after start line</p>
                        <div className="w-full flex justify-center gap-2 text-white">
                            <button
                                className={`w-1/4 h-[40px] rounded-md ${gridOptions?.side === "left" ? "bg-[#5d60ef]" : " bg-[#333333]"}`}
                                onClick={() => updateGridOptions({side: "left"})}
                            >Left</button>
                            <button
                                className={`w-1/4 h-[40px] rounded-md ${gridOptions?.side === "right" ? "bg-[#5d60ef]" : " bg-[#333333]"}`}
                                onClick={() => updateGridOptions({side: "right"})}
                            >Right</button>
                        </div>
                    </div>
                }
            </div>
            <hr className="w-full h-[1px] border-none bg-[#e4e4e7] my-5"/>
            <div className="w-full flex justify-center">
                <button
                    className="w-1/4 h-[40px] bg-[#333333] text-white rounded-md"
                    onClick={() => setSelectedSettings()}
                >Save grid template</button>
            </div>
        </div>
    );
};

export default InteractiveStartGrid;