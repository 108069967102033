import useTokenBalance from "../../../../../../hooks/useTokenBalance";
import useTokenHistory from "../../../../../../hooks/useTokenHistory";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../../../hooks/useAuth";
import { Confirmation } from "../../ConfirmAction";
import { useState } from "react";
import Button from "../../../../../../components/custom/Button";
import HistoryTable from "../../../../../../components/custom/HistoryTable";

import { RiCopperCoinFill } from "react-icons/ri";

const TokenBalance = () => {

    const { balance, loading, reloadBalance } = useTokenBalance();
    const { tokenHistory, tokenLoading, reloadTokenHistory } = useTokenHistory();

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [error, setError] = useState();

    const handlePress = async () => {
        if (balance < 1) {
            setError('Not enough tokens to redeem');
            return;
        }
        setShowConfirmation(true);
    }

    const redeemToken = async (confirmed) => {
        if (confirmed) {
            try {
                await axiosPrivate.post('/useToken', { userId: auth.userId });
                // reload history and balance
                reloadTokenHistory();
                reloadBalance();
            } catch (err) {
                console.error(err);
                setError('Error redeeming token');
            }
        }
        setShowConfirmation(false);
    }

    const getConfirmationMessage = () => {
        const isTokenActive = tokenHistory.find(token => token.isActive);
        if (isTokenActive) {
            return 'You already have an active token. Are you sure you want to extend your time by another 48 hours?';
        }
        return 'Are you sure you want to use a token and gain graphics access for 48 hours?';
    }

    const getButtonMessage = () => {
        const isTokenActive = tokenHistory.find(token => token.isActive);
        if ( isTokenActive && balance ) return 'Extend access by 24 hours';
        if ( balance ) return 'Use Token';
        return 'No Tokens Available';
    }


    return (
        <div className="token-balance-component">
            {showConfirmation && (
                <Confirmation
                    message={getConfirmationMessage()}
                    callback={redeemToken}
                />
            )}
            <div
                className="token-balance"
                style={{backgroundImage: 'url(/svg/Oval.svg)'}}
            >
                {
                    loading && <div>Loading...</div>
                }
                {
                    balance !== null && balance !== undefined &&
                    <div className="available-tokens">
                        <header>Available balance</header>
                        <p><RiCopperCoinFill color="#f7d00a" size={"30px"}/><h2>{balance}</h2><span>Tokens</span></p>
                        <Button className="w-full" onClick={handlePress} disabled={balance <= 0}>{getButtonMessage()}</Button>
                    </div>
                }
            </div>
            {/* Make sure this button is only able to be clicked when there are tokens available.  */}

            <div className="token-history">
            {
                tokenLoading && <div>Loading...</div>
            }
            {
                tokenHistory &&
                <div className="token-history-table">
                    <HistoryTable
                        data={tokenHistory}
                        settings={[
                            {name: "Action", w: "50px", type: "Arrow"},
                            {name: "ID", w: "350px"},
                            {name: "Issued", w: "300px"},
                            {name: "Duration", w: "200px"}
                        ]}
                        header="Recently used tokens history."
                        fields={["isDurationExtended", "id", "redeemedTime", "durationTime"]}
                    />
                </div>
            }
            </div>
            {
                error && <div>{error}</div>
            }
        </div>
    );

}

export default TokenBalance;