import {
    flagToColor,
    getSuffix,
    getJoker,
    getPositionArrow,
    getCompetitorTimeByMode,
    getCompetitorDataSidecar,
    getRaceTime,
    getParsedRaceTime,
    getJokerLarge,
    getCompetitorUpdateColor
} from "../../Functions";
import { classNameColors, classNameColorsM1 } from "../../config/const";
import "./VerticalScoreboard.scss";
import React, { useState, useEffect } from "react";

import { AnimatePresence, motion } from "framer-motion";

const ITEMS_PER_PAGE = 10;
const MAX_TEXT_LENGTH = 25;


// Framer configuration
const container = {
    hidden: {opacity: 0},
    show: {
        opacity: 1,
        transition: {
        staggerChildren: 0.05,
        bounce: 0
        }
    },
    exit: {opacity: 0, transition: {
        staggerChildren: 0.05,
        bounce: 0
        }}
};
const container_k1 = {
    hidden: {opacity: 0},
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            bounce: 0,
            delayChildren: 0.9
        }
    },
    exit: {opacity: 1, transition: {
            staggerChildren: 0.05,
            bounce: 0,
            duration: 1,
            staggerDirection: -1
        }}
};
const containerChildren = {
    hidden: { opacity: 0 , x: -500, transition: {delay: 0.5}},
    show: { opacity: 1, x: 0, transition: {bounce: 0}},
    exit: { opacity: 0, x: -200, transition: {duration: 0.2, delay: 1}}
};
const containerChildren_k1 = {
    hidden: { opacity: 0 , clipPath: 'inset(0 100% 0 0)', transition: {delay: 0.5}},
    show: { opacity: 1, clipPath: 'inset(0 0% 0 0)'},
    exit: { opacity: 0, clipPath: 'inset(0 100% 0 0)'}
};

const containerChildrenWinner = {
    hidden: { opacity: 0 , scaleY: 0, transition: {delay: 0.5}},
    show: { opacity: 1, scaleY: 1, transition: {bounce: 0}},
    exit: { opacity: 0, scaleY: 0, transition: {duration: 0.2, delay: 1}}
};
const containerChildrenWinner_k1 = {
    hidden: { opacity: 0 , x: -300, transition: {delay: 0.5}},
    show: { opacity: 1, x: 0, transition: {bounce: 0}},
    exit: { opacity: 0, x: -300}
};
const containerChildrenBattle = {
    hidden: { opacity: 0, scale: 0},
    show: {opacity: 1, scale: 1, transition: {duration: 0.6}},
    exit: { opacity: 0, scale: 0}
};
const containerChildrenBattle_k1 = {
    hidden: { opacity: 0, y: -100},
    show: {opacity: 1, y: 0, transition: {duration: 0.1}},
    exit: { opacity: 0, y: -100}
};

//



const battleHeadersByType = {
    "v2": (item, index, background) => {
        return (
            <motion.li
                className="app__vertical_v2_battle_header"
                key={item?.nr  + 'bhdr'}
                variants={containerChildrenBattle}
                style={{background: background || "#080808"}}
            >
                <span className="app__vertical_v2_battle_header-name">
                    {`BATTLE FOR ${index + 1}${getSuffix(index + 1)}`}
                </span>
                <div className="app__vertical_v2_battle_header-content">
                    { getJokerLarge(item?.joker) }
                    <div className="app__vertical_v2-battle-pos">
                        {index + 1}
                    </div>
                    <div className="app__vertical_v2-battle-flag">
                    {item.state ?
                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                        : <div className="img_placeholder" />
                    }
                    </div>
                    <div className="app__vertical_v2-battle-name">
                    {`${item?.firstname?.substring(0, 1)}. ${item?.lastname}`}
                    </div>
                </div>
            </motion.li>
        );
    },
    "k1": (item, index, background) => {
        return (
            <motion.li
                className="k1_battle_header"
                variants={containerChildrenBattle_k1}
                key={item?.nr  + 'bhdr'}
            >
                <span className="k1_battle_header_name">
                {`BATTLE FOR ${index + 1}${getSuffix(index + 1)}`}
                </span>
                <div className="k1_battle_header_content">
                    <div className="k1_battle_header_pos">
                        {index + 1}
                    </div>
                    <div className="k1_battle_header_className" style={{background: background}}/>
                    <div className="k1_battle_header_flag">
                    {item.state ?
                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                        : <div className="img_placeholder" />
                    }
                    </div>
                    <div className="k1_battle_header_name">
                        {item?.lastname}
                    </div>
                </div>
            </motion.li>
        );
    },
};

const battleCompetitorByType = {
    "v2": (item, index, raceType, accentColor, background) => {
        return (
            <motion.li
                className="app__vertical_v2_battle"
                key={item?.nr + 'b'}
                variants={containerChildrenBattle}
                style={{background: background || "#080808"}}
            >
                <div className="app__vertical_v2_battle-diff">
                    {raceType === 2 ?
                        `+${item?.gap_by_lap || "0.000"}`
                        :
                        `+${item?.gap_by_time || "0.000"}`
                    }
                </div>
                <div className="app__vertical_v2_battle_header-content">
                    { getJokerLarge(item?.joker, accentColor) }
                    <div className="app__vertical_v2-battle-pos">
                        {index + 1}
                    </div>
                    <div className="app__vertical_v2-battle-flag">
                    {item.state ?
                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                        : <div className="img_placeholder" />
                    }
                    </div>
                    <div className="app__vertical_v2-battle-name">
                    {`${item?.firstname?.substring(0, 1)}. ${item?.lastname}`}
                    </div>
                </div>
            </motion.li>
        );
    },
    "k1": (item, index, raceType, background, isLast) => {
        return (
            <motion.li
                className="k1_battle"
                key={item?.nr + 'b'}
                variants={containerChildrenBattle_k1}
                style={{borderBottom: isLast ? "3px solid #ea324d" : "none"}}
            >
                <div className="k1_battle_diff">
                    <p>GAP</p>
                    <span>{raceType === 2 ?
                        `+${item?.gap_by_lap || "0.000"}`
                        :
                        `+${item?.gap_by_time || "0.000"}`
                    }</span>
                </div>
                <div className="k1_battle_content">
                    <div className="k1_battle_pos">
                        {index + 1}
                    </div>
                    <div className="k1_battle_className" style={{background: background}}/>
                    <div className="k1_battle_flag">
                    {item.state ?
                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                        : <div className="img_placeholder" />
                    }
                    </div>
                    <div className="k1_battle_name">
                        {item?.lastname}
                    </div>
                </div>
            </motion.li>
        );
    }
};

const competitorFinishedAndLeaderByType = {
    "v2": (item, color) => {
        return (
            <motion.li
                className="app__vertical_v2-finish-winner"
                key={"v2_vert_finish_leader" + item?.nr}
                variants={containerChildrenWinner}
                style={{background: color}}
            >
                <div className="app__vertical_v2-finish-winner-data">
                    {
                        item?.isSidecar ?
                        <div className="app__vertical_v2-finish-winner-flag-sidecar">
                            {item.state ?
                            <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                            : <div className="img_placeholder" />
                            }
                            {item.state2 ?
                            <img src={`https://flagcdn.com/${item.state2.toLowerCase()}.svg`} alt="-" />
                            : <div className="img_placeholder" />
                            }
                        </div>
                        :
                        <div className="app__vertical_v2-finish-winner-flag">
                        {item.state ?
                            <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                            : <div className="img_placeholder" />
                        }
                        </div>
                    }
                </div>
                <div className="app__vertical_v2-finish-winner-top">
                    RACE WINNER
                </div>
                <div className="app__vertical_v2-finish-winner-bottom">
                    {
                        item?.isSidecar ? <h4>{item?.firstname.substring(2).slice(0, -1)}</h4> : <p>{item?.firstname}</p>
                    }
                    {
                        item?.isSidecar ? <h4>{item?.lastname.substring(2)}</h4> : <h4>{item?.lastname}</h4>
                    }
                </div>
            </motion.li>
        );
    },
    "k1":  (item, background, isFastest) => {
        return (
            <motion.div
                className="k1_finish_winner"
                variants={containerChildrenWinner_k1}
            >
                <div
                    className="k1_finish_top"
                    style={{backgroundImage: `url(/svg/Confetti.svg)`}}
                >
                    RACE WINNER
                </div>
                <div className="k1_finish_bottom">
                    <div
                        className="k1_finish_pos"
                        style={isFastest ? {background: "#7c20c8", color: "#f6f6f6"} : {background: "#f6f6f6", color: "#080808"}}
                    >1</div>
                    <div className="k1_finish_className" style={{background: background}}/>
                    <div className="k1_finish_flag">
                    {item.state ?
                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                        : <div className="img_placeholder" />
                    }
                    </div>
                    <div className="k1_finish_name">
                        {item?.lastname}
                    </div>
                </div>
            </motion.div>
        );
    }
};

const parseSidecarData = (item) => {
    return (
        <>
            {
                item?.state &&
                <div className="tower_m1_item_std_flag">
                    <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                </div>
            }
            {
                item?.state2 &&
                <div className="tower_m1_item_std_flag">
                    <img src={`https://flagcdn.com/${item.state2.toLowerCase()}.svg`} alt="-" />
                </div>
            }
            {
                <motion.div
                    className="tower_m1_item_std_sidecar_name"
                >
                    <p>{`${item?.firstname.slice(2, 5)}/${item?.lastname?.slice(2, 5)}`}</p>
                </motion.div>
            }
        </>
    );
};

const verticalScoreboardItemByType = {
    "v2": (topPage, idx, currentPage, item, fastestLapDriver, raceType, config, ...args) => {
        const index = topPage ? idx : idx + 10 * currentPage;
        // Sidecars are not allowed to be displayed as battle in vertical scoreboard
        if (args[0].includes((index + 1)) && args[1] && !item?.isSidecar) {
            if (args[0][0] === (index + 1)) {
                return battleHeadersByType["v2"](item, index, config?.palette?.palette?.odd);
            }
            return battleCompetitorByType["v2"](item, index, raceType,
                config?.palette?.palette?.accent,
                config?.palette?.palette?.odd
                );
        }
        // Leader after finish
        // Only for leader after finish flag and race type is not qualification or training
        // and competitor has finished
        if (item?.position === "1" && args[3] && raceType === 2 && item?.status === "F") {
            return competitorFinishedAndLeaderByType["v2"](item, config?.palette?.palette?.odd);
        }
        return (
            <motion.li
                className="app__vertical_v2_item"
                key={item?.nr + 'std'}
                variants={containerChildren}
            >
                <div className="app__vertical_v2_item-left"
                        style={{
                            background: `${idx % 2 === 0 ?
                                config?.palette?.palette?.even :
                                config?.palette?.palette?.odd}`
                        }}
                >
                    { getJoker(item?.joker, config?.buttons?.verticalScoreboard?.colors?.accentColor?.color) }
                    <div
                        className="app__vertical_v2-pos"
                        style={
                            (parseFloat(item.best_lap_time?.replaceAll(":", "")) === fastestLapDriver  && fastestLapDriver) ?
                            {background: '#b55de8', color: 'white'} :
                            {background: 'white', color: 'black'}
                        }
                    >
                        {item?.position}
                    </div>
                    <div className="app__vertical_v2-nr">
                        {item?.nr}
                    </div>
                    { getCompetitorDataSidecar(item, "v2", "#fff") }
                    <div className="app__vertical_v2-position">
                        { getPositionArrow(item?.status, "100%") }
                    </div>
                </div>
                <div className={`app__vertical_v2_item-right ${item?.update ? "animate" : ""}`}>
                    <div className={`app__vertical_v2_item-diff`}>
                        { getCompetitorTimeByMode(args[2], item, raceType, index, fastestLapDriver, topPage, "Time") }
                    </div>
                </div>
            </motion.li>
        );
    },
    "k1": (topPage, idx, currentPage, item, fastestLapDriver, raceType, config, ...args) => {
        const index = topPage ? idx : idx + 10 * currentPage;
        if (args[0].includes((index + 1)) && args[1] && !item?.isSidecar) {
            if (args[0][0] === (index + 1)) {
                return battleHeadersByType["k1"](item, index, classNameColors?.[config?.competitorClasses?.indexOf(item?.class)]);
            }
            return battleCompetitorByType["k1"](item, index, raceType,
                classNameColors?.[config?.competitorClasses?.indexOf(item?.class)],
                args[0].indexOf(index + 1) === args[0]?.length - 1
            );
        }
        if (item?.position === "1" && args[3] && raceType === 2 && item?.status === "F") {
            return competitorFinishedAndLeaderByType["k1"](item,
                classNameColors?.[config?.competitorClasses?.indexOf(item?.class)],
                (parseFloat(item.best_lap_time?.replaceAll(":", "")) === fastestLapDriver && fastestLapDriver)
            );
        }
        return (
            <motion.li
                className={`tower_k1_item_std ${item?.update ? "animate" : ""}`}
                key={item?.nr}
                variants={containerChildren_k1}
            >
                <motion.div
                    className="tower_k1_item_left"
                    style={{
                        background:
                            ["DQ", "DNS", "DNF"].includes(item?.gap) ? "#000" :
                            item?.update ? `${
                        getCompetitorUpdateColor(item?.status)
                        }` : item?.status === "F" ? "#0b0c0ff9" : "#1b1e23f9",
                        outline: ["DQ", "DNS", "DNF"].includes(item?.gap) ? "3px solid #ed4747" : "none"

                    }}
                    // TODO make it so if position changes we will animate depending on position
                >
                    <div
                        className="tower_k1_pos"
                        style={(parseFloat(item.best_lap_time?.replaceAll(":", "")) === fastestLapDriver  && fastestLapDriver) ?
                                {background: "#7c20c8", color: "#ffffff"} : {background: "#f6f6f6", color: "#080808"}
                            }
                    >
                        {item?.position}
                    </div>
                    <div className="w-[40px] overflow-hidden ml-3">{item?.nr}</div>
                    <div className="tower_k1_className" style={{background: classNameColors?.[config?.competitorClasses?.indexOf(item?.class)]}}/>
                    <div className="tower_k1_name">
                        {item?.lastname}
                    </div>
                </motion.div>
                <motion.div
                    className="tower_k1_diff"
                    initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#ea324d"}}
                    animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#1f2229cb", transition: {bounce: 0}}}
                    key={args?.[4]}
                >
                    <p>{ getCompetitorTimeByMode(args[2], item, raceType, index, fastestLapDriver, topPage, "Time") }</p>
                    {
                        item?.status === "F" && <div className="tower_k1_finish_flag"/>
                    }
                </motion.div>
            </motion.li>
        );
    },
    "m1": (topPage, idx, currentPage, item, fastestLapDriver, raceType, config, ...args) => {
        const index = topPage ? idx : idx + 10 * currentPage;
        return (
            <motion.li
                className={`tower_m1_item_std`}
                key={item?.nr + 'std'}
                variants={containerChildren_k1}
            >
                <motion.div
                    className="tower_m1_item_std_left"
                    style={{
                        background:
                        ["DQ", "DNS", "DNF"].includes(item?.gap) ? "#000" :
                        item?.update ? `${
                            getCompetitorUpdateColor(item?.status)
                        }` : item?.status === "F" ? "#0b0c0ff9" : "#222222"
                    }}
                >
                    <motion.div className="tower_m1_item_std_class" style={{background: classNameColorsM1?.[config?.competitorClasses?.indexOf(item?.class)]}}/>
                    <motion.div
                        className="tower_m1_item_std_pos"
                        style={(parseFloat(item.best_lap_time?.replaceAll(":", "")) === fastestLapDriver  && fastestLapDriver) ?
                                {background: "#7c20c8", color: "#ffffff"} : {background: "linear-gradient(to bottom, #f6f6f6, #b5b5b5)", color: "#080808"}
                            }
                    >{item?.position}</motion.div>
                    <motion.div className="tower_m1_item_std_nr">{item?.nr}</motion.div>
                    {
                        item?.isSidecar ?
                        parseSidecarData(item)
                        :
                        <>
                            <div className="tower_m1_item_std_flag">
                                {item?.state && <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />}
                            </div>
                            <motion.div
                                className="tower_m1_item_std_name"
                            >
                                <p>{item?.lastname}</p>
                            </motion.div>
                        </>
                    }
                </motion.div>
                <motion.div
                    className={`tower_m1_item_std_right ${item?.update ? "animate" : ""}`}
                    key={args?.[4]}
                    // style={{
                    //     background: args[2] === "time" && item?.make ?
                    //     makeMap[item?.make?.toUpperCase()]?.background || "#222222e1" : "#222222e1"
                    // }}
                >
                {
                    // args[2] === "time" ?
                    // item?.make ? <img src={`/makes/${makeMap[item?.make?.toUpperCase()]?.img}`} alt="-" /> : <></>
                    // :
                    <p>{ getCompetitorTimeByMode(args[2], item, raceType, index, fastestLapDriver, topPage, "Time") }</p>
                }
                </motion.div>
            </motion.li>
        );
    },
};

const getVerticalScoreboard = (type, topPage, array, raceType, fastestLapDriver, currentPage, config, ...args) => {
    if (!array || array?.length === 0) { return null; }
    return (
        array.map((item, index) =>
            verticalScoreboardItemByType[type](topPage, index, currentPage, item, fastestLapDriver, raceType, config, ...args)
        )
    );
};

const textToMode = {
    gap: "Gap between",
    time: "Difference",
    speed: "Lap speed [km/h]",
    brand: "Vehicle brand"
};

const VerticalScoreboard = (props) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(
        props.data.data?.remainingCompetitors?.length / ITEMS_PER_PAGE
    );

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const pageItems = props.data.data?.remainingCompetitors?.slice(
        startIndex,
        endIndex
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPage((currentPage) => (currentPage % totalPages) + 1);
        }, 8000);
        return () => clearInterval(interval);
    }, [currentPage, totalPages]);



    // Color palette varialbe initialization
    const palette = props?.config?.palette?.palette;

    // Increment the key in order to force a re-render of component [ for competitor updates or data type update ]
    const [key, setKey] = useState(0);
    useEffect(() => {
        setKey(prevKey => prevKey + 1); // increment key to trigger re-render
    }, [props?.config?.buttons?.verticalScoreboard?.mode]);

    const raceTime = getRaceTime(props.data?.data);
    const raceType = props.data?.data?.raceDetails?.race_type_id;
    const raceName = props?.data?.data?.raceDetails?.name;
    const raceFlag = props.data?.data?.raceDetails?.flag;
    const fastestLapDriver = parseFloat(props.data?.data?.competitorWithFastestLap?.best_lap_time?.replaceAll(":", ""));

    if (raceFlag === "None") {
        return <></>
    }

    switch (props.config?.style) {
        case "v2":
            return (
                <motion.div
                    className="app__vertical_v2_frame"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.verticalScoreboard?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.verticalScoreboard?.left
                    }}
                >
                    <motion.div
                        className="app__vertical_v2_clock"
                        initial={{opacity: 0, y: -500}}
                        animate={{opacity: 1, y: 0, transition: {bounce: 0, delay: 0.1}}}
                        style={{background: palette?.primary}}
                    >
                        { getParsedRaceTime(raceTime, true, "app_v2_timer")}
                    </motion.div>
                    {
                        props.data?.data?.raceDetails?.flag &&
                        <AnimatePresence>
                            <motion.div
                                initial={{opacity: 0, y: -50}}
                                animate={{opacity: 1, y: 0, transition: {bounce: 0}}}
                                exit={{opacity: 0, y: -50}}
                                className="app__vertical_v2_race_flag"
                                style={{background:
                                    props.data?.data?.raceDetails?.flag === "Finish" ?
                                    "repeating-conic-gradient(#2a2a2ae7 0% 25%, #f4f4f4e7 0% 50%) 50% / 8px 8px"
                                    : flagToColor(props.data?.data?.raceDetails?.flag)}}
                            />
                        </AnimatePresence>
                    }
                    <motion.div
                        className="app__vertical_v2_race_info"
                        style={{background: palette?.secondary}}
                    >
                        <p className={raceName?.length > MAX_TEXT_LENGTH ? 'scrolling-text' : ''}>{raceName}</p>
                    </motion.div>
                    <motion.div
                        key={key}
                        className="app__vertical_v2_data_type"
                        style={{background: palette?.secondary}}
                    >
                        <p>{textToMode?.[props?.config?.buttons?.verticalScoreboard?.mode]}</p>
                    </motion.div>
                    <motion.ol
                        className="app__vertical_v2_items"
                        key={"items_top"}
                        variants={container}
                        initial="hidden"
                        animate="show"
                        exit="exit"
                    >
                        <AnimatePresence>
                            {
                                getVerticalScoreboard("v2", true, props.data.data?.topTenCompetitors,
                                    raceType, fastestLapDriver, currentPage, props?.config,
                                    props?.config?.battleCompetitors,
                                    props?.config?.enableVerticalBattles, props?.config?.buttons?.verticalScoreboard?.mode,
                                    (["Finish", "None"].includes(props.data?.data?.raceDetails?.flag))
                                )
                            }
                        </AnimatePresence>
                    </motion.ol>
                    <motion.ol
                        className="app__vertical_v2_items"
                        key={"items_btm"}
                        variants={container}
                        initial="hidden"
                        animate="show"
                        exit="exit"
                    >
                        <AnimatePresence>
                            {
                                getVerticalScoreboard("v2", false, pageItems,
                                    raceType, fastestLapDriver, currentPage, props?.config, props?.config?.battleCompetitors,
                                    props?.config?.enableVerticalBattles, props?.config?.buttons?.verticalScoreboard?.mode,
                                    false
                                )
                            }
                        </AnimatePresence>
                    </motion.ol>
                </motion.div>
            );
        case "k1": {
            const flags = {
                "Warmup": <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 3.5}}} exit={{opacity: 0}} className="tower_k1_flag" style={{background: flagToColor("Warmup")}} >Warmup flag</motion.div>,
                "Yellow": <div className="tower_k1_flag" style={{background: flagToColor("Yellow"), color: "black"}} >Yellow flag</div>,
                "Red": <div className="tower_k1_flag" style={{background: flagToColor("Red")}} >Red flag</div>
            };
            return (
                <div
                    className="tower_k1"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.verticalScoreboard?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.verticalScoreboard?.left
                    }}
                    animate={{x: [-200, 0], opacity: 1}}
                    initial={{opacity: 0, x: -200}}
                    transition={{duration: 0.5}}
                    exit={{opacity: 0, x: [0, -200]}}
                >
                    <motion.div
                        className="tower_k1_head"
                        initial={{opacity: 0, y: -200}}
                        animate={{y: 0, opacity: 1}}
                        transition={{duration: 0.5}}
                        exit={{opacity: 0, y: -200, transition: {delay: 1.2}}}
                    >
                        <div className="tower_k1_head_item">
                            {props?.data?.data?.raceDetails?.race_type}
                        </div>
                        <div className="tower_k1_head_item">
                            {getParsedRaceTime(raceTime, true, "app_v2_timer", "k1")}
                        </div>
                        {
                            flags?.[props.data?.data?.raceDetails?.flag]
                        }
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0, y: -50}}
                        animate={{y: 0, opacity: 1}}
                        transition={{duration: 0.2, delay: 0.5}}
                        exit={{opacity: 0, y: -50, transition: {delay: 1.1}}}
                        className={`tower_k1_race_name`}
                        style={{justifyContent: raceName?.length > MAX_TEXT_LENGTH ? ' flex-start' : 'center'}}
                    >
                        <p className={raceName?.length > MAX_TEXT_LENGTH ? 'scrolling-text' : ''}>{raceName}</p>
                    </motion.div>
                    <motion.div
                        key={key}
                        className="tower_k1_data_type"
                        initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#ea324d"}}
                        animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#16171bf9", transition: {duration: 0.2}}}
                        exit={{opacity: 0, y: -70, transition: {delay: 1, duration: 0.1}}}
                        transition={{duration: 0.2, delay: 0.8}}
                    >
                        <p>{textToMode?.[props?.config?.buttons?.verticalScoreboard?.mode]}</p>
                    </motion.div>
                    <motion.ol
                        className="tower_k1_body"
                        variants={container_k1}
                        initial="hidden"
                        animate="show"
                        exit="exit"
                    >
                        <AnimatePresence>
                            {
                                getVerticalScoreboard("k1", true, props.data.data?.topTenCompetitors,
                                    raceType, fastestLapDriver, currentPage, props?.config,
                                    props?.config?.battleCompetitors,
                                    props?.config?.enableVerticalBattles, props?.config?.buttons?.verticalScoreboard?.mode,
                                    (["Finish", "None"].includes(props.data?.data?.raceDetails?.flag)), key
                                )
                            }
                            {
                                getVerticalScoreboard("k1", false, pageItems,
                                    raceType, fastestLapDriver, currentPage, props?.config, props?.config?.battleCompetitors,
                                    props?.config?.enableVerticalBattles, props?.config?.buttons?.verticalScoreboard?.mode,
                                    false, key
                                )
                            }
                        </AnimatePresence>
                    </motion.ol>
                </div>
            );
        }
        case "m1": {
            return (
                <>
                    <motion.div
                        className="tower_m1"
                        style={{
                            top: props?.preview ? "30px" : props?.config?.buttons?.verticalScoreboard?.top,
                            left: props?.preview ? "30px" : props?.config?.buttons?.verticalScoreboard?.left
                        }}
                    >
                        <motion.div
                            className="tower_m1_head"
                            initial={{ scale: 0, backgroundColor: "#c81111"}}
                            animate={{ scale: 1, backgroundColor: "#0c0a0a", transition: {bounce: 0}}}
                            exit={{ scale: 0, transition: {delay: 0.8}}}
                        >
                            {getParsedRaceTime(raceTime, true, "app_v2_timer")}
                        </motion.div>
                        {
                            ["Finish", "None"].includes(props?.data?.data?.raceDetails?.flag) &&
                            <motion.div
                                className="tower_m1_flag"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1, transition: {bounce: 0}}}
                                exit={{ scale: 0, transition: {delay: 0.8}}}
                            >
                                <img src="/flags/finish.gif" alt="-" />
                            </motion.div>
                        }
                        <motion.div
                            className="tower_m1_race_name"
                            initial={{ scale: 0 }}
                            animate={{ scale: 1, transition: {bounce: 0}}}
                            exit={{ scale: 0, transition: {delay: 0.8}}}
                            style={{justifyContent: raceName?.length > MAX_TEXT_LENGTH ? "flex-start" : "center"}}
                        >
                            <p
                                className={raceName?.length > MAX_TEXT_LENGTH ? 'scrolling-text' : ''}
                            >{raceName}</p>
                        </motion.div>
                        <motion.div
                            key={key}
                            className="tower_m1_data_type"
                            initial={{ opacity: 0, x: "-100%" }}
                            animate={{ opacity: 1, x: "0%", transition: {bounce: 0}}}
                            exit={{ opacity: 0, transition: {delay: 0.8}}}
                        >
                            <p>{textToMode?.[props?.config?.buttons?.verticalScoreboard?.mode]}</p>
                        </motion.div>
                        <motion.ol
                            className="tower_m1_items_ol"
                            variants={container_k1}
                            initial="hidden"
                            animate="show"
                            exit="exit"
                        >
                        {
                            getVerticalScoreboard("m1", true, props.data.data?.topTenCompetitors,
                                raceType, fastestLapDriver, currentPage, props?.config,
                                props?.config?.battleCompetitors,
                                props?.config?.enableVerticalBattles, props?.config?.buttons?.verticalScoreboard?.mode,
                                (["Finish", "None"].includes(props.data?.data?.raceDetails?.flag)), key
                            )
                        }
                        {
                            getVerticalScoreboard("m1", false, pageItems,
                                raceType, fastestLapDriver, currentPage, props?.config, props?.config?.battleCompetitors,
                                props?.config?.enableVerticalBattles, props?.config?.buttons?.verticalScoreboard?.mode,
                                false, key
                            )
                        }
                        </motion.ol>
                    </motion.div>
                </>
            );
        }
        default:
        return <div></div>;
    }
};

export default VerticalScoreboard;
