import { Route, Routes } from "react-router-dom";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Admin from "./pages/Admin/Admin";
import Graphics from "./pages/Graphics/Graphics";
import Login from "./pages/Login/Login";
import Register from "./pages/Login/Register/Register";
import Activate from "./pages/Activation/Activate";
import Marshall from "./pages/Marshall/Marshall";
import PageNotFound from "./pages/Admin/AdminPanelComponents/NotFound/NotFound";
import Home from "./pages/Home/Home";
import PersistLogin from "./pages/PersistLogin";
import RequireAuth from "./pages/RequireAuth";
import RequireMAuth from "./pages/RequireMAuth";
import Forgot, { ResetPassword } from "./pages/Login/Forgot";

const ROLES = {
  "User": 2001,
  "Admin": 5566
};

function App() {
  return (
      <div className="App">
        <Routes>
          {/* Public routes */}
          <Route path={"/register"} element={<Register />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/home"} element={<Home />} />
          <Route path={"/forgot"} element={<Forgot />} />
          <Route path={"/forgot/:token"} element={<ResetPassword />} />
          <Route path={"/graphics/:hash"} element={<Graphics/>} />
          <Route path={"/activate/:hash"} element={<Activate />} />
          {/* // Auth routes */}
          <Route element={<RequireMAuth />}>
            <Route path={"/marshall/*"} element={
              <Marshall />
            } />
          </Route>
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path={"/user/*"} element={
                <Admin/>
              } />
            </Route>
          </Route>
          <Route path='*' element={<PageNotFound/>} />
        </Routes>
      </div>
  );
};
export default App;
