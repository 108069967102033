import React, { useState, useEffect } from 'react';
import useAuth from "../../../../../../../hooks/useAuth";
import useXmlUpload from '../../../../../../../hooks/useXmlUpload';

import { MdDelete } from "react-icons/md";
import { useSelector } from 'react-redux';

const XML = ({ socket }) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [docName, setDocName] = useState('');
    const [points, setPoints] = useState(null);

    const [ verifyFile, setVerifyFile ] = useState(true);
    const [ verifyDocName, setVerifyDocName ] = useState(true);

    const config = useSelector(state => state.config);

    const { auth } = useAuth();
    const { upload, error } = useXmlUpload();

    useEffect(() => {
        socket?.emit("points", {command: "LOAD"}, (resp) => {
            setPoints(resp);
        });
    }, []);


    const onFormSubmit = async (e) => {
        e.preventDefault();

        if (!docName || !selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('docName', docName);
        formData.append('userId', auth.userId);

        await upload(formData);
        if (!error) {
            // got a positive resp, reload the list
            socket.emit("points", {command: "LOAD"}, (resp) => { setPoints(resp); });
        }
    }

    const deletePointsItem = (id) => {
        socket.emit("points", {command: "DELETE", id: id}, (resp) => {
            setPoints(resp);
        });
    }

    const setPointsItem = (id) => {
        socket.emit("update", {what: 5, data: ["selectedPointsData", id]}, (response) => {

        });
    }

    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const onDocNameChange = (e) => {
        setDocName(e.target.value);
    }

    return (
        <div className="w-full h-full text-[#222222]">
            <form onSubmit={onFormSubmit} className="flex items-center gap-2 w-full px-3 border-b border-b-[#e4e4e7] pb-3">
                <div>
                    <label
                        className="mb-1"
                        htmlFor="xmlfile"
                    >Select xml file</label>
                    <input
                        className="block w-[300px] h-[40px] border border-[#e4e4e7] rounded-md cursor-pointer bg-[#f9f9fa]
                         focus:outline-none file:border-none file:h-full file:bg-[#333333] file:text-white file:px-3 file:cursor-pointer"
                        aria-describedby='file-upload-help'
                        type="file"
                        name="file"
                        required
                        accept=".xml"
                        onChange={onFileChange}
                    />
                    <p
                        className="mt-1"
                        id="file-upload-help"
                    >XML files only (MAX 20MB).</p>
                </div>
                <div>
                    <label
                        htmlFor="docName"
                        className="mb-1"
                    >Event name</label>
                    <input
                        className="py-2 h-[40px] w-[300px] bg-[#f9f9fa] border border-[#e4e4e7] rounded-md mb-[23px]"
                        type="text"
                        name="docName"
                        required
                        value={docName}
                        onChange={onDocNameChange}
                    />
                </div>

                <button
                    className="px-7 h-[40px] bg-[#333333] text-white rounded-md cursor-pointer mt-4"
                    type="submit"
                >Upload</button>
            </form>
            <div className="w-full h-fit overflow-y-auto flex gap-2 p-3">
            {
                points && Object.values(points?.data).map((item, index) =>
                    <div className='w-[250px] h-fit p-3 border border-[#e4e4e7] rounded-md shadow-md' key={`xml_item_${index}`}>
                        <p>{item?.data?.name}</p>
                        <p>{item.data?.data?.header?.race_name}</p>
                        <p>{item.data?.data?.header?.race_track}</p>
                        <p>{item.data?.data?.header?.race_date}</p>
                        <div className='flex items-center gap-2 pt-3'>
                            <button
                                disabled={config?.selectedPointsData === item._id ? true : false}
                                className={
                                    `px-5 h-[40px] ${
                                        config?.selectedPointsData === item._id ?
                                        'bg-[#5d60ef]' : 'bg-[#333333]'
                                    } text-white rounded-md cursor-pointer`
                                }
                                onClick={() => setPointsItem(item._id)}
                            >{
                                config?.selectedPointsData === item._id ? "Selected" : "Select"
                            }</button>
                            <button
                                className={
                                    `px-3 h-[40px] bg-[#333333] text-white rounded-md cursor-pointer`
                                }
                                onClick={() => deletePointsItem(item._id)}
                            ><MdDelete size={"20px"} /></button>
                        </div>
                    </div>
                )
            }
            </div>
        </div>
    );
}

export default XML;