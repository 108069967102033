import "./Schedule.scss";
import { FaClock } from "react-icons/fa";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

export const ScheduleActive = ({ config }) => {

    const data = config?.scheduleItem;

    if (!data) return <></>;

    switch ( config?.style ) {
        case "k1": return (
            <motion.div
                className="k1_schedule_active absolute text-white"
                style={{
                    left: config?.buttons?.scheduleNow?.left,
                    top: config?.buttons?.scheduleNow?.top
                }}
            >
                <motion.header
                    initial={{clipPath: 'inset(0 100% 0 0)'}}
                    animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.4 }}}
                    exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}
                >
                    UP NEXT
                </motion.header>
                <motion.hr
                    initial={{clipPath: 'inset(0 100% 0 0)'}}
                    animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.4, delay: 0.05 }}}
                    exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}
                />
                <motion.div
                    className="k1_schedule_active_content"
                    initial={{clipPath: 'inset(0 0 100% 0)'}}
                    animate={{clipPath: 'inset(0 0 0% 0)', transition: { duration: 0.4, delay: 0.5 }}}
                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                >
                <motion.span
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: { delay: 1 }}}
                >
                    <FaClock />
                    <>{data?.time.split(':')[0]}<h4 className="flashing-colon">:</h4>{data?.time.split(':')[1]}</>
                </motion.span>
                    <motion.p
                        initial={{clipPath: 'inset(0 100% 0 0)'}}
                        animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.2, delay: 1 }}}
                        exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}
                    >
                        {data?.name}
                    </motion.p>
                </motion.div>
            </motion.div>
        );
    }
};

export const ScheduleFull = ({ config, socket, raceData }) => {

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const scheduleIndex = config?.scheduleIndex;
    const filteredData = data?.scheduleData?.filter((_, index) => index >= scheduleIndex);

    useEffect(() => {
        socket.emit("schedule", { command: "GET" }, (response) => {
            setData(response.data);
        });
    }, [config?.scheduleIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPage(prevPage => (prevPage + 1) % Math.ceil(filteredData?.length / 6));
        }, 2000);
        return () => clearInterval(interval);
    }, [filteredData]);

    if (!data) return <></>;

    switch ( config?.style ) {
        case "k1": return (
            <div
                className="k1_schedule_full absolute w-[1200px]"
                style={{
                    left: config?.buttons?.scheduleAll?.left,
                    top: config?.buttons?.scheduleAll?.top
                }}
            >
                <header className="w-full h-[100px] flex items-center justify-between text-white text-2xl px-8">
                    <span>RACE SCHEDULE</span>
                    <p>{raceData?.championship}</p>
                </header>
                <div className="w-full h-fit max-h-[100px] flex px-8 relative">
                    <div className="w-[150px]">
                        {
                            config?.raceCountry ?
                            <img
                                src={`https://flagcdn.com/${config?.raceCountry}.svg`}
                                className="w-full h-full bg-cover"
                            />
                            : <div className="w-full h-full" />
                        }
                    </div>
                    <div className="w-[calc(100%-130px)] h-full cool-shape bg-[#191921] absolute left-[110px] pl-10 flex items-center text-white text-2xl">
                        {raceData?.track_name}
                    </div>
                </div>
                <div className="w-full h-[5px]">
                    <div className="loader h-full"/>
                </div>
                <div className="w-full max-h-[900px] overflow-hidden p-[10px] px-4">
                {
                    filteredData
                        ?.slice(currentPage * 6, (currentPage + 1) * 6)
                        .map((item, index) =>
                            <div className="w-full h-[100px] flex items-center justify-between mb-[5px] text-white border-[#ea324d] border-b-[4px]" key={`schedule_full_${index}`}>
                                <p className="mr-[20px] text-[1.8rem] font-bold uppercase">{item?.name}</p>
                                <span
                                    className="h-full w-[200px] px-3 flex gap-[5px] items-center justify-center bg-[#ea324d] text-[1.8rem]"
                                >
                                    <FaClock className="mr-2"/>
                                    <>{item?.time.split(':')[0]}<h4 className="flashing-colon">:</h4>{item?.time.split(':')[1]}</>
                                </span>
                            </div>
                        )
                }
                </div>
                <div className="w-full h-[100px] bg-[#1b1e23] text-white flex items-center px-4">
                    {
                        config?.scheduleItem &&
                        <>
                            <p>NEXT EVENT</p>
                        </>
                    }
                </div>
            </div>
        );
    }

};