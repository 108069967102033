import { RiArrowUpDoubleLine } from "react-icons/ri";
import { FaMoneyCheckAlt } from "react-icons/fa";
import "./HistoryTable.scss";

const HistoryTable = ({ data, settings, header='', fields}) => {

    const getValueByType = (type, value) => {
        if (!type) return value;
        switch (type) {
            case 'Arrow': {
                return value === true ? <RiArrowUpDoubleLine size={"25px"} color="#1cba34"/> : <FaMoneyCheckAlt size={"25px"}/>;
            }
        }
    }

    return (
        <div className="table-responsive p-[10px] select-none rounded-[5px] text-[#1e1e1f]">
            <header className="w-full py-[10px] text-center text-[#71717a]">{header}</header>
            <table className="table table-striped table-hover">
                <thead>
                    {settings.map((setting, index) => 
                        <td className="p-[7px]" key={`table-head-${index}`} style={{width: setting?.w}}>{setting.name}</td>
                    )} 
                </thead>
                <tbody className="h-max-[300px] overflow-y-auto">
                    {data?.map((row, index) =>
                        <tr key={`table-row-${index}`} style={{ borderLeft: row.isActive ? "3px solid #1cba34" : "" }}>
                            {fields.map((field, index2) =>
                                <td
                                    key={`table-data-${index}-${index2}`}
                                    style={{width: settings[index]?.w}}
                                    className="p-[7px]"
                                >
                                    {getValueByType(settings[index2]?.type, row[field])}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
            <footer className="bg-[#f9f9fa] flex justify-between p-[10px]">
                <p>Total used tokens</p>
                <span>{data?.length}</span>
            </footer>
        </div>
    );
}

export default HistoryTable;