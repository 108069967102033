import axios from "../api/axios";
import useAuth from "./useAuth";

const REFRESH_URL = '/refresh';
let refreshTokenRequest = null;

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async (location = '') => {

        if (!refreshTokenRequest) {
            refreshTokenRequest = axios.get(
                REFRESH_URL,
                { withCredentials: true }
            );
        }

        const response = await refreshTokenRequest;
        refreshTokenRequest = null;

        setAuth(prev => {
            return {
                ...prev,
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                user: response.data.user,
                email: response.data.email,
                userId: response.data.userId
            }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;