import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate";

const useSetRaceKey = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const set = async (apiKey) => {
        await axiosPrivate.post('/setApiKey', { userId: auth.userId, apiKey: apiKey });
    }
    return set;
}

export default useSetRaceKey;