import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh("persistLogin");
            } catch (err) {
                console.error(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;

    }, [auth?.accessToken, persist, refresh]);

    const ret = {
        noPersist: <Outlet />,
        loading: <p>Loading...</p>,
        finishedLoading: <Outlet />
    };

    return !persist ? ret.noPersist : isLoading ? ret.loading : ret.finishedLoading;
}

export default PersistLogin;