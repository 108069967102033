import "./HorizontalBar.scss";
import MessageStore from "../../../../../managers/ConsoleMessageManager";
import { FaFlag } from "react-icons/fa";
import { IoMdSettings, IoMdHelp, IoIosLogOut } from "react-icons/io";
import { FiServer } from "react-icons/fi";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Line } from 'react-chartjs-2';
import {CategoryScale} from 'chart.js';
import Chart from 'chart.js/auto';
import Timestamps from "./GraphTimestamps";
import { BsStopwatchFill } from "react-icons/bs"
import { RiCopperCoinFill } from "react-icons/ri";

import { pageNames } from "../../../../../config/namespaces";

import { flagToColor } from "../../../../../Functions";
import { useNavigate } from "react-router-dom";
import useLogout from "../../../../../hooks/useLogout";

import TokenStatus from "./components/TokenStatus";
import useAuth from "../../../../../hooks/useAuth";

import Switch from "../../../../../components/custom/Switch";

import Select from "react-select";
import countryList from "react-select-country-list";

Chart.register(CategoryScale);


export const HorizontalAvailableRaces = () => {

    const dispatch = useDispatch();
    const raceData = useSelector(state => state.data);

    return (
        <div className="horizontalBar-component-race-selector-inner">
            <header>
                <p>Races</p>
            </header>
            <div className="horizontalBar-component-race-selector-selected">
                {
                    raceData && raceData?.status === 400 &&
                    <>
                        <span>Authentification error</span>
                    </>
                }
                <button onClick={() => {
                    dispatch({type: "SET_PAGE", payload: pageNames.AvailableRaces});
                }}>
                    View available
                </button>
            </div>
        </div>
    );
};

const TimeGraph = ({ timestamps }) => {
    // Calculate the time differences

    const timeDifferences = timestamps.slice(1).map((timestamp, index) => timestamp - timestamps[index]);
    const data = {
        labels: timestamps.map((_, index) => `- ${index + 1}f`).reverse(),
        datasets: [
            {
                label: 'Time difference',
                data: timeDifferences,
                fill: false,
                backgroundColor: '#3d993c',
                borderColor: '#3d993c',
                spanGaps: true,
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 10, // Set the font size to 10
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 10, // Set the font size to 10
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const getTimeDiffColor = (timeDiff) => {
        const optimalTimeDiff = 1000;
        const diff = Math.abs(timeDiff - optimalTimeDiff);
        const maxDiff = 1000;

        // Calculate the red and green components of the color
        const red = Math.min(255, Math.round((diff / maxDiff) * 255));
        const green = Math.min(255, Math.round(((maxDiff - diff) / maxDiff) * 255));
        return (
            <span style={{color: `rgb(${red}, ${green}, 0)`}}>{timeDiff || 0}</span>
        );
    };

    return (
        <div className="time-differences-graph-wrapper">
            <div className="time-differences-graph">
            {
                timeDifferences.length ?
                <Line data={data} options={options}/>
                :
                <span>No race selected</span>
            }
            </div>
            <div className="time-differences-graph-info-wrapper">
                <div className="time-differences-graph-info">
                    <p>Last fetch</p>
                    <div className="time-differences-graph-fetch">
                        {getTimeDiffColor(timeDifferences[timeDifferences.length - 1])}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HorizontalDataGraph = () => {

    const [timestamps, setTimestamps] = useState(Timestamps.getTimestamps());

    useEffect(() => {
        const updateTimestamps = () => {
            setTimestamps([...Timestamps.getTimestamps()]);
        };

        Timestamps.addListener(updateTimestamps);
        return () => {
            Timestamps.removeListener(updateTimestamps);
        };
    }, []);

    return <TimeGraph timestamps={timestamps} />;
};


export const KeyboardPressListener = ({config, socket}) => {
    const [isListening, setIsListening] = useState(false);

    const checkKeyEvent = (event) => {
        if (config?.buttons === undefined) {
            return;
        }
        Object.keys(config?.buttons).map(async (item) => {
            if (config?.buttons?.[item]?.key === event.key && config?.buttons?.[item]?.enabled) {
                const value = config?.buttons?.[item]?.show;
                socket.emit("update", {what: 6, data: [item, "show", !value]}, (callback) => {
                    MessageStore.addMessage(callback);
                });
            }
        })
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isListening) { checkKeyEvent(event); }
        }
        window.addEventListener('keyup', handleKeyDown);
        return () => {
            window.removeEventListener('keyup', handleKeyDown);
        }
    }, [isListening, config?.buttons]);
    return (
        <div className="keyboardPressListener-component">
            <p>Keypress</p>
            <Switch
                lvalue={"Standby"}
                rvalue={"Listening"}
                onChange={(value) => {setIsListening(value)}}
                defaultValue={false}
            />
        </div>
    );
};

export const EventCountry = ({ config, socket }) => {

    const [value, setValue] = useState(null);
    const options = useMemo(() => countryList().getData(), []);

    useEffect(() => {
        setValue(config?.raceCountry || '');
    }, [config?.raceCountry]);

    const changeHandler = value => {
        setValue(value.value.toLowerCase())
        socket.emit("update", { what: 5, data: ["raceCountry", value.value.toLowerCase()]}, (response) => {
            MessageStore.addMessage({status: 200, message: "Event country changed to " + value.label});
        })
    }

    return (
        <div className="keyboardPressListener-component">
            <p className="w-[23%]">
            {
                value ?
                <img
                    src={`https://flagcdn.com/${value}.svg`}
                    className="h-[30px] w-auto shadow-md"
                />
                : "None"
            }
            </p>
            <div className="w-[65%]">
                <Select options={options} value={value} onChange={changeHandler} className="z-50 text-[#222222]" />
            </div>
        </div>
    );
}


const Avatar = () => {
    const { auth } = useAuth();

    return (
        <div className="bg-red-500 w-[40px] h-[40px] mt-[10px] rounded-full border-[1px] border-[#e4e4e7] flex items-center justify-center text-white text-2xl select-none">
            {auth?.user?.[0]?.toUpperCase()}
        </div>
    );
}

const SettingsPopout = () => {

    const { auth } = useAuth();
    const dispatch = useDispatch();

    return (
        <div className="absolute bottom-[-300px] right-[-10px] bg-white w-[290px] h-[298px] z-[10000] rounded-[5px] border-[#e4e4e7] border-[1px] p-[20px] pt-[10px] text-[#222222]">
            <header className="flex items-center h-[55px] gap-2 justify-between">
                <div className=" mt-[10px]">
                    <p className="text-[1.2rem]">{auth?.user}</p>
                    <p className="text-[#888888]">{auth?.email || "dummy@dummy.com"}</p>
                </div>
                <Avatar/>
            </header>
            <p className="w-fit py-[3px] px-[10px] rounded-[3px] bg-[#3d993c] text-white">Verified user</p>
            <hr className="!w-full !h-[1px] !border-none !bg-[#e4e4e7] my-2" />
            <button className="setings-popout-btn" onClick={() => dispatch({type: "SET_PAGE", payload: pageNames.Settings})}>Edit Settings</button>
            <button className="setings-popout-btn" onClick={() => dispatch({type: "SET_PAGE", payload: pageNames.TokenBalance})}>My Tokens</button>
            <button className="setings-popout-btn">Logout</button>
        </div>
    );
}


const HorizontalUserData = ({socket}) => {

    const [ toggleSettings, setToggleSettings ] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const log = useLogout();


    const logout = async () => {
        await log();
        navigate('/login');
    };
    return (
        <div className="relative flex flex-shrink-0">
            <Avatar />
            <TokenStatus socket={socket}/>
            <div className="horizontalBar-component-user-data-buttons">
            <p
                    style={{ background: "#f7d00a", color: "#0c111c" }}
                    title="Balance"
                    onClick={() => {
                        dispatch({type: "SET_PAGE", payload: pageNames.TokenBalance})
                    }}
                >
                    <RiCopperCoinFill size="20px"/>
                </p>
                <p
                    title="Help"
                    onClick={() => {
                        dispatch({type: "SET_PAGE", payload: pageNames.Help})
                    }}
                >
                    <IoMdHelp size="20px"/>
                </p>
                <p
                    onClick={() => window.open("https://stream.racegraphics.eu", '_blank')}
                    title="Open data server"
                >
                    <FiServer size="20px"/>
                </p>
                {/* <ConsoleComponentSupport /> */}
                <p
                    title="Settings"
                    onClick={() => setToggleSettings(prev => !prev)}
                >
                    <IoMdSettings size="25px"/>
                </p>
                <p title="Logout" onClick={logout}><IoIosLogOut size={"20px"}/></p>
            </div>
            {
                toggleSettings && <SettingsPopout />
            }
        </div>
    );
};


const HorizontalRaceInfo = () => {
    const data = useSelector(state => state.data);
    const config = useSelector(state => state.config);

    if (!data || data?.status === 400) {
        return <div className="horizontalBar-raceInfo-box">Authentification error</div>
    }
    if (!config || !data?.data?.raceDetails) {
        return (
            <div className="horizontalBar-raceInfo-box">
                <p>Waiting for race..</p>
            </div>
        );
    }
    return (
        <div className="horizontalBar-raceInfo-box">
            <p>{data?.data?.raceDetails?.name}</p>
            <p>{`type: ${data?.data?.raceDetails?.race_type}`}</p>
            <p><FaFlag color={flagToColor(data?.data?.raceDetails?.flag)} size={"15px"}/></p>
            <p>
                <BsStopwatchFill size={"15px"} color="#f7d00a"/>
                <span>{data?.data?.raceTimer?.name}</span>
                <span>{data?.data?.raceTimer?.data}</span>
            </p>
        </div>
    );
};

const HorizontalBarComponent = ({socket}) => {

    return (
        <div className="horizontalBar-component">
            <div className="horizontalBar-logo">
                <img src="/logo/RGLogo-new.png" alt="logo" />
            </div>
            <HorizontalRaceInfo />
            <div className="horizontalBar-component-user-data">
                <HorizontalUserData socket={socket}/>
            </div>
        </div>
    );
};

export default HorizontalBarComponent;