import { useState, useEffect } from "react";

const Switch = ({lvalue, rvalue, onChange, defaultValue}) => {
    const [isToggled, setIsToggled] = useState(defaultValue);

    useEffect(() => {
        setIsToggled(defaultValue);
    }, [defaultValue]);

    const onToggle = () => {
        setIsToggled(!isToggled);
        onChange(!isToggled);
    };

    return (
        <div
            className={`switch ${isToggled ? 'switch--right' : 'switch--left'}`}
            onClick={onToggle}
        >
            <div className="switch-text" style={{color: isToggled ? "#8f8f8f" : "#fff"}}>{lvalue}</div>
            <div className="switch-toggle"></div>
            <div className="switch-text" style={{color: isToggled ? "#fff" : "#8f8f8f"}}>{rvalue}</div>
        </div>
    );
};

export default Switch;