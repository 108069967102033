import "./Home.scss";
import {
    motion,
    useAnimation
}
from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

// icons
import { ImConnection } from "react-icons/im";
import { IoGameController, IoImagesSharp } from "react-icons/io5";
import { FaRocket, FaDiscord } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaFacebookSquare } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

const scrollTo = id => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
};

const Navbar = () => {
    const [open, isOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" });

    const navigate = useNavigate();
    return (
        <div className="rg-navbar">
            <div className="rg-navbar-inner">
                <div className="rg-navbar-logo flex items-center gap-3">
                    <img src="/logo/RGlogo-new.png" alt="RG Logo" />
                </div>
                {
                    isTabletOrMobile ?
                    <div className="rg-navbar-hamburger">
                        <GiHamburgerMenu size={"30px"} color="white"/>
                    </div>
                    :
                    <>
                        <ul>
                            <li onClick={() => scrollTo('title')}>Home</li>
                            <li onClick={() => scrollTo('about')}>About us</li>
                            <li onClick={() => scrollTo('graphicsInfo')}>Our product</li>
                            <li>Pricing</li>
                            <li>Help</li>
                            <hr className="w-[1px] h-[32px] mt-[6px] bg-[#3f3f42] border-none mx-2"/>
                            <li className="w-10 h-10">
                                <FaFacebookSquare size={"100%"} color="#3ac2f8"/>
                            </li>
                            <li className="w-10 h-10" title="Gmail">
                                <SiGmail size={"100%"} color="#ed4337"/>
                            </li>
                            <li className="w-10 h-10" title="Discord support">
                                <FaDiscord size={"100%"} color="#5865f2"/>
                            </li>
                        </ul>
                        <div className="flex gap-3">
                            <button onClick={() => navigate("/login")}>Sign in</button>
                            <button onClick={() => navigate("/register")}>Sign up</button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

const Title = () => {

    return (
        <div id="title" className="rg-title"
        style={{backgroundImage: `url(/home/globe2.jpg)`}}
        >
            <div className="rg-title-inner">
                <span>More powerful then ever before.</span>
                <p>Get started with our lastest graphics panel right now! 🚀</p>
                <button className="shadow-md">View pricing plans</button>
            </div>
            {/* <button>Start your subscription</button> */}
        </div>
    );
};

const Textarea = () => {
    return (
        <div className="w-full h-fit bg-[#f5f5f7] px-[10px] pt-[40px] overflow-hidden">
            <header className="w-full flex flex-col items-center">
                <span className="font-bold text-[4rem]">One panel - many designs!</span>
                Choose between different graphics designs to best suit your look*
            </header>
            <div className="w-full flex items-center justify-center gap-3 h-[500px]">
                <div className="w-[350px] h-fit tilt p-1 bg-[#080300] z-1 ml-[-60px] shadow-xl">
                    <img src="/home/grid.png" className=""/>
                </div>
                <div className="w-[350px] h-fit tilt p-1 ml-[-60px] bg-[#080300] shadow-xl">
                    <img src="/home/vert.png" className=""/>
                </div>
                <div className="w-[350px] h-fit tilt p-1 ml-[-60px] bg-[#080300] shadow-xl">
                    <img src="/home/battle.png" className=""/>
                </div>
                <div className="w-[450px] h-fit bg-cover tilt p-1 ml-[-80px] bg-[#080300] shadow-xl">
                    <img src="/home/hor.png" className="w-full h-full"/>
                </div>
            </div>
            <p className="text-center text-[.8rem] pb-2">* Designs can differ from show due to constant updates.</p>
        </div>
    );
}

const Video = () => {
    return (
        <div className="rg-video">
            <video autoPlay loop muted>
                <source src="/videos/video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

        </div>
    );
};
const About = () => {
    const animation = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                transition: { duration: 1.5, delay: 0.2 },
            });
        } else {
            animation.start({ opacity: 0 });
        }
    }, [animation, inView]);
    return (
        <motion.div
            id="about"
            className="rg-about"
        >
            <span>About us</span>
            <motion.div
                className="rg-about-inner"
                animate={animation}
                initial={{ opacity: 0 }}
                ref={ref}
            >
                <p>A company with more than 17 years of experience.</p>
                <h2>
                    We work in providing race timing service as well as serve as the official reseller of MyLaps in technical sports.
                    We specialize in technical and IT solutions of timing systems.  Our latest solution is the development of data and graphics servers for streaming race results in video graphics - RaceGraphics.
                </h2>
            </motion.div>
        </motion.div>
    );
}

const Clients = () => {

    const clients = ["nolimits.png", "rotax.png", "rallycross.png", "kartsport.jpg"];

    return (
        <div className="rg-clients">
            <span>Meet our customers</span>
            <div className="carousel">
                {clients.map((client, index) => (
                    <img key={index} src={`/home/${client}`} alt={`client ${index + 1}`} className="carousel__image" />
                ))}
            </div>
        </div>
    );
}

const Transition = () => {
    return (
        <div className="rg-transition" style={{backgroundImage: "url(/svg/trans.svg)"}}/>
    );
}

const GraphicsInfo = () => {

    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const cards = [
        {
            title: "Blink rendering engine",
            text: "Our graphics uses heavily optimized <b>Blink</b> open source rendering engine <h4>the same used by Google Chrome</h4> to render graphics in real-time.",
            color: "#dbd9d9",
            icon: <img src="/home/chromium.png" alt="" />
        },
        {
            title: "Real time data from Orbits",
            text: "Thanks to our data stream server, RaceGraphics pull data real time - straight from the timekeeper to ensure up to date action.",
            color: "#dbd9d9",
            icon: <ImConnection size={"100px"} color={"#5858e8"}/>
        },
        {
            title: "You have the controls",
            text: "Customize graphics to best fit your needs! Select your own colors and position the graphics however you like.",
            color: "#dbd9d9",
            icon: <IoGameController size={"100px"} color={"#df3f3f"}/>
        },
        {
            title: "Showcase competitors",
            text: "Upload your competitor images and RaceGraphics will match them with corresponding driver to be shown on screen!",
            color: "#dbd9d9",
            icon: <IoImagesSharp size={"100px"} color={"#2d443c"}/>
        },
        {
            title: "Simple to use",
            text: "Setting up your own RaceGraphics session takes just a few clicks! Simply select your race from available races and click on a graphic you want to show!",
            color: "#dbd9d9",
            icon: <FaRocket size={"100px"} color={"#000000"}/>
        }
    ];

    return (
        <div id="graphicsInfo" className="rg-graphics-info">
            <span>Our product</span>
            <p>Using latest and fastest technologies. 🚀</p>
            <motion.div
                className="rg-graphics-info-cards"
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                    visible: {
                        opacity: 1,
                        transition: { staggerChildren: 0.2 } // Add this line
                    },
                    hidden: { opacity: 0 }
                }}
            >
                {cards.map((card, index) => (
                    <motion.div
                        key={index}
                        className="graphicsInfo-card"
                        ref={ref}
                        animate={controls}
                        initial="hidden"
                        transition={{ duration: 0.5, delay: 1 }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: 50 }
                        }}
                    >
                        <div className="graphicsInfo-card-inner"
                            style={{background: `linear-gradient(to top, #ededed 50%, ${card.color} )`}}>
                            {card.icon}
                            <p>{card.title}</p>
                            <span dangerouslySetInnerHTML={{ __html: card.text }} />
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
}

const Showcase = () => {

    const links = [
        "https://www.youtube.com/watch?v=0x1sdl5Z9d4&ab_channel=RallycrossLV",
        "https://www.youtube.com/watch?v=mwDNz08VePo&ab_channel=RotaxMaxLatvia",
        "https://www.youtube.com/watch?v=kAjredUO5nM&t=4817s&ab_channel=Best4SportTV"
    ];

    return (
        <div className="rg-showcase">
            <span>Showcase</span>
            <p>See us in action! 🎬</p>
            <div className="rg-showcase-inner">
            {
                links.map((item, index) =>
                    <div className="rg-showcase-item" key={`rg_showcase_${index}`}>
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${item.split('=')[1].split('&')[0]}`}
                            title={`YouTube video player ${index}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                )
            }
            </div>
        </div>
    );
}

const Prices = () => {

    const plans = [
        {
            name: "Single token",
            cost: 280,
            amount: 1,
            text: "When you need graphics for a single event.",
            popular: false,
        },
        {
            name: "5 Tokens",
            cost: 1250,
            amount: 5,
            text: "When you need graphics for whole season!",
            popular: true,
        },
        {
            name: "10 Tokens",
            cost: 2200,
            amount: 10,
            text: "When you need graphics for whole season!",
            popular: true,
        },
        {
            name: "20 Tokens",
            cost: 4000,
            amount: 20,
            text: "When you need graphics for whole season!",
            popular: true,
        }
    ];

    return (
        <div
            className="rg-prices"
            style={{backgroundImage: "url(/svg/Maze.svg"}}
        >
            <span>Pricing</span>
            <p>Purchase tokens to redeem them for 24hr graphics access</p>
            <div className="rg-prices-inner">
            {
                plans.map((plan, index) =>
                    <div
                        className={`rg-prices-item ${plan.popular ? "rg-prices-item-popular" : ""}`}
                        key={`rg_prices_${index}`}
                    >
                        <h2>{plan.name}</h2>
                        <h3>{`${plan.cost}€`}</h3>
                        <p>{`Price per token - ${plan.cost / plan.amount}`}</p>
                        <p>{plan.text}</p>
                        {
                            plan.popular &&
                            <div className="rg-pop">Most popular</div>
                        }
                        <button>Contact us!</button>
                    </div>
                )
            }
            </div>
        </div>
    );
}

const Footer = () => {
    return (
        <div className="rg-footer">
            <p>Contact Us</p>
            <h4>Email: racegraphicseu@gmail.com</h4>
            <h6>RaceGraphics™ 2024</h6>
        </div>
    );
}
const Dots = () => {
    return (
        <div className="rg-dots">
            <ul>
                <li onClick={() => scrollTo('title')}/>
                <li onClick={() => scrollTo('about')}/>
                <li onClick={() => {}}/>
                <li onClick={() => {}}/>
            </ul>
        </div>
    );
}

const Message = () => {
    return (
        <aside
            className="w-full h-[70px] fixed bg-[#282829] text-[#ffffffeb] text-[.85rem] font-light
            flex items-center justify-center gap-3 z-10 top-0 text-center"
        >

            <p>This graphics panel version is currently running in demo mode and could contain breaking bugs.</p>
        </aside>
    );
}

const Panel = () => {
    return (
        <div className="w-full h-fit p-[10px] bg-black overflow-hidden text-white flex">
            <div className="w-2/5 flex flex-col justify-center p-3">
                <span className="text-[2rem] font-bold">Next gen control panel</span>
                <p>Our 3rd generation graphics panel with more features than ever, designed in collaboration with streamers to make managing graphics in your stream a breeze.</p>
            </div>
            <div className="w-3/5">
                <img className="w-full h-auto tilt p-1 bg-white"  src="/home/panel.png" />
            </div>
        </div>
    );
}

const Home = () => {

    return (
        <div className="rg-home">
            <div className="rg-home-inner">
                <Message />
                <Navbar />
                <div className="mx-auto max-w-[1920px] w-full grid gap-3 select-none">
                    <Dots />
                    <Title />
                    <Clients />
                    <Textarea />
                    <Panel />
                    <Showcase />
                    <Video />
                    <Prices />
                    <GraphicsInfo />

                    <About />
                    <Footer />
                </div>

            </div>
        </div>
    );
};

export default Home;