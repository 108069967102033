import { TiWeatherPartlySunny } from "react-icons/ti";
import { AiFillDatabase } from "react-icons/ai";
import { BsFillMegaphoneFill } from "react-icons/bs";
import { pageNames } from "../../../../../config/namespaces";
import {
    OpenWeatherSelector,
    AnnouncerDataSelector,
    VerticalScoreboardDataType,
    SetResultsByClass,
    SetStartGridMode,
    BattlesQuickSelectionWidget
} from "./CustomButtonsComponents";

const CustomButtonsConfig = {
    weather: [{
        key: "weather",
        icon: <TiWeatherPartlySunny size={"17px"}/>,
        el: OpenWeatherSelector,
        id: pageNames.WeatherSelector,
        elementEmbedded: false,
    }],
    verticalScoreboard: [{
        key: "VerticalResultsType",
        icon: <AiFillDatabase size={"17px"}/>,
        el: VerticalScoreboardDataType,
        id: pageNames.VerticalScoreboardDataType,
        elementEmbedded: true,
    }],
    announcer: [{
        key: "announcerMessage",
        icon: <BsFillMegaphoneFill size={"17px"}/>,
        el: AnnouncerDataSelector,
        id: pageNames.AnnouncerSelector,
        elementEmbedded: false,
    }],
    resultsByClass: [{
        key: "resultsByClass",
        icon: <AiFillDatabase size={"17px"}/>,
        el: SetResultsByClass,
        id: pageNames.ResultsByClass,
        elementEmbedded: true,
    }],
    startGrid: [{
        key: "startGrid",
        el: SetStartGridMode,
        id: pageNames.StartGrid,
        elementEmbedded: true,
    }],
    battles: [{
        key: "battles",
        el: BattlesQuickSelectionWidget,
        id: pageNames.Battles,
        elementEmbedded: true,
    }]
};

export default CustomButtonsConfig;