import { useEffect, useState, useCallback } from 'react';
import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from './useAuth';

const useTokenHistory = () => {
    const [ tokenHistory, setTokenHistory ] = useState([]);
    const [ tokenLoading, setTokenLoading ] = useState(true);

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const reloadTokenHistory = useCallback(async () => {
        setTokenLoading(true);
        try {
            const response = await axiosPrivate.post('/getTokenHistory', { userId: auth.userId });
            setTokenHistory(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setTokenLoading(false);
        }
    }, [auth.userId, axiosPrivate]);

    useEffect(() => {
        reloadTokenHistory();
    }, [reloadTokenHistory]);

    return { tokenHistory, tokenLoading, reloadTokenHistory };
}

export default useTokenHistory;