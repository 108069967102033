import { useEffect, useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from "./useAuth";

const useGetActiveRaces = () => {

    const [ active , setActive ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {

        const getIsActive = async () => {
            try {
                const response = await axiosPrivate.post('/getActiveRaces', { userId: auth.userId });
                if (response.data.error) {
                    setActive({ error: response.data.error });
                } else {
                    setActive(response.data);
                }

            } catch (err) {
                switch (err.response?.status) {
                    case 401: setActive({ error: "Invalid userId or apiKey provided."}); break;
                    case 402: setActive({ error: "You need to redeem a token to view this page."}); break;
                }
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        getIsActive();

    }, []);

    return { active, loading };
}

export default useGetActiveRaces;
