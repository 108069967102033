import { useSelector } from "react-redux";
import { selectorGraphicsDesc, designData } from "../../../../../../../config/fields";


const GraphicsSelector = ({ socket }) => {

    const config = useSelector(state => state.config);
    const { buttons } = config;

    const handleButtonClick = (button) => {
        socket.emit("update", {
            what: 6,    // button
            data: [
                button[0],
                "enabled",
                true
            ]
        }, (callback) => {
            //MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="w-full h-full p-[10px] text-[#222222]">
            <header className="text-center">
                <span className="text-[1.4rem]">Hidden Graphics</span>
                <p>Here are all unused, but selectable graphics elements. Click on the graphic to activate it and it will show up in your enabled graphics row</p>
            </header>
            <div className="w-full h-[calc(100%-100px)] my-3 px-2 overflow-y-auto
                grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4
            ">
            {
                buttons &&
                Object.entries(buttons).filter((button) => !button[1].enabled ).map((button, index) => {
                    const designs = Object.entries(designData).reduce((acc, [key, value]) => {
                        if (value.graphics.includes(button[0])) {
                            acc.push(key);
                        }
                        return acc;
                    }, []);

                    return (
                        <button
                            className="w-full h-[350px] text-center rounded-[5px] bg-white
                             flex flex-col items-center border-[1px] border-[#e4e4e7] p-[10px] pt-10 shadow-md"
                            onClick={() => {
                                if (designs.length !== 0) {
                                    handleButtonClick(button);
                                }
                            }}
                            key={`available-graphics-bttn-${index}`}
                        >
                            <h3 className="text-[1.2rem]">{button[1].name}</h3>
                            <p>{selectorGraphicsDesc[button[0]]?.description}</p>
                            <hr className="w-full h-[1px] border-none bg-[#222222] my-5"/>
                            <p style={{marginBottom: "5px"}}>Available in designs</p>
                            {
                                designs.length !== 0 ? designs.map((design, index) => {
                                    return (
                                        <span
                                            key={`design-${index}`}
                                            className="py-1 px-6 bg-[#333333] text-white rounded-[5px] mb-1"
                                        >{designData[design].name}</span>
                                    );
                                })
                                :
                                <span
                                    style={{
                                        width: "80%",
                                        background: "repeating-linear-gradient(45deg, #ed4747, #ed4747 10px, #db2c2c 10px, #db2c2c 20px)",
                                        border: "1px solid transparent"
                                    }}
                                >Under construction</span>
                            }
                        </button>
                    );
                })
            }
            </div>
        </div>
    );
}

export default GraphicsSelector;