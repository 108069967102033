import { useSchedule } from "../../../../../context/scheduleProvider";
import { useState } from "react";
import "./DayScheduleWidget.scss";
import { useSelector } from "react-redux";


const DayScheduleShowcaseWidget = () => {
    const { schedule } = useSchedule();
    const config = useSelector(state => state.config);
    const scheduleIndex = config?.scheduleIndex || 0;

    let start = scheduleIndex - 1;
    if (scheduleIndex === 0) {
        start = 0;
    } else if (scheduleIndex === schedule.length - 1) {
        start = schedule.length - 3;
    }

    const itemsToShow = schedule.slice(start, start + 3);

    return (
        <div className="day-schedule-showcase-widget">
            {
                itemsToShow.map((item, index) =>
                    <section key={`schedule_item_${index + start}`}>
                        <div className="day-schedule-showcase-times">
                            <hr/><p>{item?.time}</p>
                        </div>
                        <div className={`day-schedule-showcase-name ${scheduleIndex === index + start ? "selected" : ""}`}>
                            <p>{item?.name}</p>
                        </div>
                    </section>
                )
            }
        </div>
    );
}

const DayScheduleControlsWidget = ({ socket }) => {
    const { schedule, setNewSchedule } = useSchedule();
    const [delay, setDelay] = useState(0);
    const config = useSelector(state => state.config);

    const applyDelay = () => {
        const newSchedule = schedule.map(item => {
            let [hours, minutes] = item.time.split(':').map(Number);
            minutes += delay;
            if (minutes >= 60) {
                hours += Math.floor(minutes / 60);
                minutes %= 60;
            } else if (minutes < 0) {
                hours += Math.floor(minutes / 60);
                minutes = 60 + (minutes % 60);
            }
            hours %= 24;
            const newTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            return { ...item, time: newTime };
        });
        setNewSchedule(newSchedule);
    };

    const setCurrentScheduleItem = (index) => {

        if (index + 1 > schedule.length || index < 0 || !schedule.length) {
            return;
        }
        socket.emit("update", {what: 5, data: ['scheduleIndex', index]}, (response) => {
        });
        socket.emit("update", {what: 5, data: ['scheduleItem', schedule[index]]}, (response) => {
        });
    }

    return (
        <div className="day-schedule-controls-widget">
            <header>Run</header>
            <button onClick={() => setCurrentScheduleItem(config?.scheduleIndex + 1)}>Next</button>
            <button onClick={() => setCurrentScheduleItem(config?.scheduleIndex - 1)}>Previous</button>
            <div className="day-schedule-delay">
                <header>Delay [minutes]</header>
                <input type="number" value={delay} onChange={e => setDelay(Number(e.target.value))} />
                <button onClick={applyDelay}>Apply</button>
            </div>
        </div>
    );
}

const DayScheduler = ({ socket }) => {
    return (
        <div className="day-schedule-wrapper">
            <DayScheduleShowcaseWidget />
            <DayScheduleControlsWidget socket={socket} />
        </div>
    );
}

export default DayScheduler;