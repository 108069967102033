import { useRef, useState, useEffect } from "react";
import axios from "../../../api/axios";

import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import "./Register.scss"

// components
import { Box, BoxHeader } from "../../../components/custom/Box";
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert"
import { Button } from "../../../components/ui/button";

import { Terminal } from 'lucide-react'
import { FaCheck, FaTimes, FaInfoCircle } from "react-icons/fa";


// regex
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const REGISTER_URL = '/register';

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [captchaResponse, setCaptchaResponse] = useState(null);

    const handleCaptchaResponseChange = (response) => {
        console.log(response);
        setCaptchaResponse(response);
    };

    const navigate = useNavigate();

    // for focus
    useEffect(() => {
        userRef?.current?.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !USER_REGEX.test(user) || !PWD_REGEX.test(pwd) || !EMAIL_REGEX.test(email)
        ) {
            setErrMsg("Invalid fields!");
            return;
        }

        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ user, pwd, email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            setSuccess(true);
            // Clear inputs
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
        }
    }


    if ( success ) {
        return (
            <div
                className="w-[100vw] h-[100vh] flex items-center justify-center bg-[#222222] font-geist text-[.9rem]"
                style={{backgroundImage: `url(/svg/Maze.svg)`}}
            >
                <Box className="!w-[400px] h-fit bg-white border-[1px] border-[#e4e4e7] !p-[20px] shadow-lg flex flex-col items-center">
                    <BoxHeader><p className="text-[1.8rem] font-[700] my-2">Almost there!</p></BoxHeader>
                    <hr className="w-full h-[1px] border-none bg-[#e4e4e7]" />
                    <span className="text-center my-2 text-[#555555]">To finish registration you need to verify your e-mail by clicking the link sent to</span>
                    <span className="my-2 py-2 px-4 bg-[#f9f9fa] rounded-sm">{email}</span>
                    <span className="text-center my-2 text-[#555555]">After e-mail verification your request to create an
                        account will be sent to our adminstrators who will need to approve it.</span>
                    <span>This process could take up to 3 days</span>
                    <button
                        className="bg-[#2563eb] text-white mt-5 rounded-[5px] h-[40px] w-full !cursor-pointer"
                        onClick={() => {
                            setEmail('');
                            navigate('/login');
                        }}
                    >Understood, take me back</button>
                </Box>
            </div>
        );
    }

    return (
        <div
            className="w-[100vw] h-[100vh] flex items-center justify-center bg-[#222222] font-geist text-[.9rem]"
            style={{backgroundImage: `url(/svg/Maze.svg)`}}
        >
            <div className="absolute top-[10px] left-[10px]">
                <img className="w-[40px] h-auto" src="/logo/RGLogo-new.png" alt="logo" />
            </div>
           <Box className="bg-[#fff] text-[#222222] !p-[20px] !w-[400px] border-[1px] border-[#e4e4e7] shadow-lg flex flex-col items-center">
                <BoxHeader className="!w-full text-center my-3 text-[2.5rem] font-[700]">Register</BoxHeader>
                <hr className="w-full h-[1px] border-none bg-[#e4e4e7]" />
                <div className="flex gap-[5px] my-2">
                    <p>Already registered?</p>
                    <Link className="underline" to="/login">Login</Link>
                </div>

                <form className="flex flex-col w-[80%]" onSubmit={handleSubmit}>
                    <label htmlFor='username'>
                        Username:
                        <FaCheck className={validName ? "valid" : "hide"} />
                        <FaTimes className={validName || !user ? "hide" : "invalid"} />
                    </label>
                    <input
                        className="px-[5px] w-full h-[40px] bg-[#fbfbfc] border-[1px] border-[#e4e4e7] rounded-md"
                        type='text'
                        id='username'
                        ref={userRef}
                        autoComplete='off'
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        aria-invalid={validName ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUserFocus(true)}
                        onBlur={() => setUserFocus(false)}
                    />
                    <p id='uidnote' className={userFocus && user && !validName ? 'instructions' : 'offscreen'}>
                        <FaInfoCircle color="#0362fc" />
                        4 to 24 characters.<br />
                        Must begin with a letter.<br />
                        Letters, numbers, underscores, hyphens allowed.
                    </p>

                    <label htmlFor='email'>
                        E-mail:
                        <FaCheck className={validEmail ? "valid" : "hide"} />
                        <FaTimes className={validEmail || !email ? "hide" : "invalid"} />
                    </label>
                    <input
                        className="px-[5px] w-full h-[40px] bg-[#fbfbfc] border-[1px] border-[#e4e4e7] rounded-md"
                        type='text'
                        id='email'
                        autoComplete='off'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        aria-invalid={validEmail ? "false" : "true"}
                        aria-describedby="emailnote"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                    />
                    <p id='emailnote' className={emailFocus && email && !validEmail ? 'instructions' : 'offscreen'}>
                        <FaInfoCircle color="#0362fc" />
                        Must be a valid email address.
                    </p>

                    <label htmlFor="password">
                        Password:
                        <FaCheck className={validPwd ? "valid" : "hide"} />
                        <FaTimes className={validPwd || !pwd ? "hide" : "invalid"} />
                    </label>
                    <input
                        className="px-[5px] w-full h-[40px] bg-[#fbfbfc] border-[1px] border-[#e4e4e7] rounded-md"
                        type="password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                        aria-invalid={validPwd ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                    />
                    <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        <FaInfoCircle color="#0362fc" />
                        8 to 24 characters.<br />
                        Must include uppercase and lowercase letters, a number and a special character.<br />
                        Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                    </p>

                    <label htmlFor="confirm_pwd">
                        Confirm Password:
                        <FaCheck className={validMatch && matchPwd ? "valid" : "hide"} />
                        <FaTimes className={validMatch || !matchPwd ? "hide" : "invalid"} />
                    </label>
                    <input
                        className="px-[5px] w-full h-[40px] bg-[#fbfbfc] border-[1px] border-[#e4e4e7] rounded-md"
                        type="password"
                        id="confirm_pwd"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd}
                        required
                        aria-invalid={validMatch ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                    />
                    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        <FaInfoCircle color="#0362fc" />
                        Must match the first password input field.
                    </p>
                    <Button className="bg-[#2563eb] text-white mt-5 rounded-[5px] h-[40px] !cursor-pointer" disabled={!validName || !validPwd || !validMatch || !validEmail ? true : false}>Sign Up</Button>
                </form>
            {
                errMsg &&
                <Alert
                    className="errorAlert"
                    variant="destructive"
                    ref={errRef}
                >
                    <Terminal className="h-4 w-4" />
                    <AlertTitle>Error!</AlertTitle>
                    <AlertDescription>
                        {errMsg}
                    </AlertDescription>
                </Alert>
            }
           </Box>
        </div>
    );


}

export default Register;