import React, { useState, useMemo } from "react";

import "./InteractiveScreen.scss";
import "./InteractiveScreenComponents.scss";

import MessageStore from "../../../../../managers/ConsoleMessageManager";
import { getRaceBackendAddress } from "../../../../../Functions";
import { KeyboardPressListener, EventCountry } from "../HorizontalBar/HorizontalBar";
import { HorizontalAvailableRaces } from "../HorizontalBar/HorizontalBar";
import { HorizontalDataGraph } from "../HorizontalBar/HorizontalBar";
import Switch from "../../../../../components/custom/Switch";

// Icon imports
import { SiDatabricks } from "react-icons/si";
import { SlGrid } from "react-icons/sl";
import { BsToggleOn, BsFiletypeXml } from "react-icons/bs";
import { PiSwordFill } from "react-icons/pi";
import { CgIfDesign } from "react-icons/cg";
import { FaRegCalendarAlt, FaMicrophone } from "react-icons/fa";
import { IoIosColorPalette, IoMdWarning } from "react-icons/io";
import { IoPersonSharp } from "react-icons/io5";
import { FaListOl, FaImagePortrait } from "react-icons/fa6";
import { TbCapture } from "react-icons/tb";
import { VscError } from "react-icons/vsc";

// Component imports
import InteractiveLiveResults from "./components/LiveResults/LiveResults";
import InteractiveEnabledGrid from "./components/EnabledGrid/EnabledGrid";
import InteractiveStartGrid from "./components/StartGrid/StartGrid";
import InteractiveBattles from "./components/BattlesCreator/Battles";
import InteractiveGraphicsDesign from "./components/GraphicsDesigns/Designs";
import InteractiveMyDesigns from "./components/ColorSchemes/ColorDesigns";
import InteractiveCompetitorImages from "./components/CompetitorImages/Images";
import GraphicsSelector from "./components/GraphicsSelector/SelectGraphics";
import { GraphicsPositionChange } from "../InteractiveButtons/CustomButtonsComponents";
import Settings from "../InteractiveScreen/components/Settings/Settings";

import { AnnouncerDataSelector, OpenWeatherSelector, SetResultsByClass, VerticalScoreboardDataType } from "../InteractiveButtons/CustomButtonsComponents";
import { pageNames } from "../../../../../config/namespaces";

// Extra components that are toggled via buttons
import HelpComponent from "./Help";
import AvailableRacesComponent from "./AvailableRaces";
import { useSelector, useDispatch } from "react-redux";
import TokenBalance from "../HorizontalBar/components/TokenBalance";
import useAuth from "../../../../../hooks/useAuth";
import Schedule from "./components/DaySchedule/Schedule";
import Interview from "./components/Interview/Interview";
import XML from "./components/XMLUploader/XML";
import { ConfirmationImageAlgorithm, ConfirmationSiluette } from "../ConfirmAction";

const InteractiveScreenErrors = () => {

    const errors = useSelector(state => state.error.data);

    const warningCount = errors?.filter(err => err.type === 'Warning').length;
    const errorCount = errors?.filter(err => err.type === 'Error').length;

    return (
        <div className="interactive-screen-graphics-information">
            <div className="interactive-screen-graphics-information-inner">
            {
                !errors?.length ? "" :
                errors.map((error, index) =>
                    <span key={"error" + index}>
                        {
                            error.type === "Error" ?
                                <VscError size={"20px"} color="#ed4747"/>
                                :
                                <IoMdWarning size={"20px"} color="#f7980a"/>
                        }
                        <h5>{error.name}</h5>
                    </span>
                )
            }
            </div>
            <div className="interactive-screen-graphics-information-errors">
                {errorCount ? <p style={{background: "#ed4747"}}><VscError size={"20px"}/><h5>{errorCount}</h5></p> : ""}
                {warningCount ? <p style={{background: "#f7980a"}}><IoMdWarning size={"20px"}/><h5>{warningCount}</h5></p> : ""}
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div
            className="interactive-screen-main"
            style={{background: "#0c111c"}}
        >
            <div
                className="interactive-screen-main-selection-row config_load_skeleton"
                style={{width: "250px", marginRight: "10px"}}
            />
            <div className="interactive-screen-main-render-area-wrapper config_load_skeleton"/>
        </div>
    );
}


const InteractiveScreenMain = ({config, socket }) => {

    const page = useSelector(state => state.page);
    const dispatch = useDispatch();

    const [ search ,setSearch ] = useState('');

    const tabConfig = useMemo(() => ({
        View: ["feed", "graphics", "schedule"],
        Select: ["grid", "battles", "track", "interview"],
        Design: ["designs", "colors"],
        Upload: ["images", "xml"],
        Graphics: ["disabled"]
    }), []);

    const tabs = useMemo(() => ({
        [pageNames.LiveFeed]: {component: InteractiveLiveResults, props: {}, icon: <SiDatabricks size={"18px"}/>, name: "Data Feed", id: "feed"},
        [pageNames.ViewEnabled]: {component: InteractiveEnabledGrid, props: {}, icon: <BsToggleOn size={"18px"}/>, name: "Output", id: "graphics"},
        [pageNames.StartGrid]: {component: InteractiveStartGrid, props: {socket}, icon: <SlGrid size={"18px"}/>, name: "Start Grid", id: "grid"},
        [pageNames.PositionBattles]: {component: InteractiveBattles, props: {socket}, icon: <PiSwordFill size={"21px"}/>, name: "Battles", id: "battles"},
        [pageNames.GraphicsStyles]: {component: InteractiveGraphicsDesign, props: {socket}, icon: <CgIfDesign size={"23px"}/>, name: "Design Packages", id: "designs"},
        [pageNames.MyDesigns]: {component: InteractiveMyDesigns, props: {socket}, icon: <IoIosColorPalette size={"18px"}/>, name: "Design Colors", id: "colors"},
        [pageNames.CompetitorImages]: {component: InteractiveCompetitorImages, props: {socket}, icon: <IoPersonSharp size={"18px"}/>, name: "Competitor Images", id: "images"},
        [pageNames.GraphicsSelector]: {component: GraphicsSelector, props: {socket}, icon: <FaListOl size={"23px"}/>, name: "Disabled Graphics", id: "disabled"},
        [pageNames.Schedule]: {component: Schedule, props: {socket}, icon: <FaRegCalendarAlt size={"23px"}/>, name: "Schedule", id: "schedule"},
        [pageNames.Inverview]: {component: Interview, props: {socket}, icon: <FaMicrophone size={"23px"}/>, name: "Interview", id: "interview"},
        [pageNames.XmlDataViewer]: {component: XML, props: {socket}, icon: <BsFiletypeXml  size={"23px"}/>, name: "XML results", id: "xml"},
    }), []);

    // Accessed only by redux SET_PAGE
    const extraTabs = useMemo(() => ({
        [pageNames.Help]: {component: HelpComponent, props: {}},
        [pageNames.AvailableRaces]: {component: AvailableRacesComponent, props: {socket}},
        [pageNames.VerticalScoreboardDataType]: {component: VerticalScoreboardDataType, props: {socket}},
        [pageNames.ResultsByClass]: {component: SetResultsByClass, props: {socket}},
        [pageNames.AnnouncerSelector]: {component: AnnouncerDataSelector, props: {socket}},
        [pageNames.WeatherSelector]: {component: OpenWeatherSelector, props: {socket}},
        [pageNames.GraphicsSelector]: {component: GraphicsSelector, props: {socket}},
        [pageNames.GraphicsPosition]: {component: GraphicsPositionChange, props: {socket}},
        [pageNames.GraphicsColors]: {component: <></>, props: {socket}},
        [pageNames.TokenBalance]: {component: TokenBalance, props: {socket}},
        [pageNames.Settings]: {component: Settings, props: {socket}}
    }), []);

    const Component = tabs[page]?.component || extraTabs[page]?.component;
    const props = tabs[page]?.props || extraTabs[page]?.props;

    // Page config
    const InteractiveScreenMain_config = {
        skeleton: <Skeleton />,
        ret:
        <>
            <div className="interactive-screen-main">
                <div className="interactive-screen-main-selection-row">
                    <div className="interactive-screen-main-selection-row-head">
                        <input
                            type="text"
                            className="!bg-[#f9f9fa] border-[1px] border-[#e4e4e7]"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder="Search..."
                        />
                        <button onClick={() => setSearch('')}>Clear</button>
                    </div>
                    <div className="interactive-screen-main-selection-row-content">
                    {
                        Object.entries(tabConfig)
                            .map(([k, v], idx) => ({
                                key: k,
                                value: Object.entries(tabs)
                                    .filter(([key, value]) =>
                                        v.includes(value.id)
                                        && (value.name.toLowerCase().includes(search.toLowerCase())
                                        || k.toLowerCase().includes(search.toLowerCase())
                                    ))
                            }))
                            .filter(({ value }) => value.length > 0)
                            .map(({ key, value }, idx) =>
                                <React.Fragment key={idx}>
                                    {idx !== 0 && <hr/>}
                                    <header><p>{key}</p></header>
                                    <ul>
                                        {
                                            value.map(([key, value], index) =>
                                                <li
                                                    className={page === key ? "active" : ""}
                                                    onClick={() => {
                                                        dispatch({type: "SET_PAGE", payload: key})
                                                    }}
                                                    key={"tabs_interactive" + index}
                                                ><p>{value.icon}</p><span>{value.name}</span></li>
                                            )
                                        }
                                    </ul>
                                </React.Fragment>
                            )
                    }
                    </div>
                </div>
                <div className="interactive-screen-main-render-area-wrapper">
                    <InteractiveScreenErrors />
                    <div className="interactive-screen-main-render-area-wrapper-inner">
                        <div className="interactive-screen-main-render-area">
                            <Component {...props} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    };

    // If not config is loaded return loading skeleton untill config loads
    return config ? InteractiveScreenMain_config.ret : InteractiveScreenMain_config.skeleton;
};


const RightSidePanel = ({config, socket}) => {
    const [copyButtonText, setCopyButtonText] = useState('Copy');
    const [ openSiluette, setOpenSiluette ] = useState(false);
    const [ openImageAlgorithm , setOpenImageAlgorithm ] = useState(false);
    const { auth } = useAuth();

    const textToCopy = `${getRaceBackendAddress().slice(0, -4)}/graphics/${encodeURIComponent(auth.userId)}`;

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setCopyButtonText('Copied!');
            setTimeout(() => setCopyButtonText('Copy'), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const RightSidePanel_config = {
        skeleton: <div  className="interactive-screen-right-panel config_load_skeleton" style={{width: "290px", marginLeft: "10px"}} />,
        ret: <>
                {
                    openSiluette && <ConfirmationSiluette socket={socket} callback={() => setOpenSiluette(false)}/>
                }
                {
                    openImageAlgorithm && <ConfirmationImageAlgorithm socket={socket} callback={() => setOpenImageAlgorithm(false)}/>
                }
                <div className="interactive-screen-right-panel">
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Listeners</p></header>
                        <KeyboardPressListener config={config} socket={socket}/>
                    </div>
                    <hr/>
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Event country</p></header>
                        <EventCountry config={config} socket={socket} />
                    </div>
                    <hr/>
                    <div className="interactive-screen-right-panel-item">
                        <header>
                            <p>Competitor images</p>
                            <div className="flex gap-2">
                                <button
                                    onClick={() => {
                                        setOpenSiluette(true)
                                    }}
                                    title="Select missing image siluette"
                                    className="w-[30px] h-[30px] flex items-center justify-center bg-[#333333] rounded-md hover:bg-[#5d60ef]"
                                ><FaImagePortrait size={"17px"}/></button>
                                <button
                                    className="w-[30px] h-[30px] flex items-center justify-center bg-[#333333] rounded-md hover:bg-[#5d60ef]"
                                    title="Select image capture algorithm"
                                    onClick={() => setOpenImageAlgorithm(true)}
                                >
                                    <TbCapture size={"17px"}/>
                                </button>
                            </div>
                        </header>
                        <div className="keyboardPressListener-component">
                            <p>Use</p>
                            <Switch rvalue={"Enabled"} lvalue={"Disabled"} onChange={(value) => {
                                socket.emit("update", {what: 5, data: ["useCompetitorImages", value]}, (callback) => {
                                    MessageStore.addMessage(callback);
                                });
                            }} defaultValue={config?.useCompetitorImages}/>
                        </div>
                    </div>
                    <hr />
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Graphics output link</p></header>
                        <button
                            className="interactive-screen-right-panel-item-link"
                            onClick={handleCopy}>{copyButtonText}</button>
                    </div>
                    <hr />
                    <div className="interactive-screen-right-panel-item">
                        <HorizontalAvailableRaces />
                    </div>
                    <hr />
                    <div className="interactive-screen-right-panel-item px-3 mb-2">
                        <div className=" bg-[#222222] rounded-md">
                            <header><p className="!text-white">Datastream</p></header>
                            <HorizontalDataGraph />
                        </div>
                    </div>
                </div>
            </>
    };
    return config ? RightSidePanel_config.ret : RightSidePanel_config.skeleton;
};

const InteractiveScreen = ({config, socket }) => {
    return (
        <div className="interactive-screen">
            {/* <InteractiveScreenEdit config={config} socket={socket} /> */}
            <InteractiveScreenMain config={config} socket={socket} />
            <RightSidePanel config={config} socket={socket}/>
        </div>
    );
};

export default InteractiveScreen;