import useAxiosPrivate from "./useAxiosPrivate";
import { useState } from "react";

const useXmlUpload = () => {
    
    const [ error , setError ] = useState(null);
    const axiosPrivate = useAxiosPrivate();

    const upload = async (formData) => {
        
        if (error) setError(null); // reset error
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        try {
            await axiosPrivate.post('/uploadXml', formData, config);
        } catch (err) {
            alert("Error on upload: " + err);
            setError(err);
        }
    }

    return { upload, error }
}

export default useXmlUpload;