import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Button } from "../../components/ui/button";
import { Terminal } from 'lucide-react'
import { FaInfoCircle } from "react-icons/fa";

const Forgot = () => {

    const [ email, setEmail ] = useState('');
    const [ verifyEmail, setVerifyEmail ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    useEffect(() => {
        setVerifyEmail(EMAIL_REGEX.test(email));
    }, [email]);

    const handleSubmit = async () => {
        try {
            await axios.post('/api/passwordResetRequest', JSON.stringify({ email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSuccess(true);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='w-[100vw] h-[100vh] font-geist color-white flex items-center justify-center gap-[10px] relative text-[.9rem]' style={{backgroundImage: `url(/svg/Maze.svg)`}}>
            <div className="absolute top-[10px] left-[10px]">
                <img className="w-[40px] h-auto" src="/logo/RGLogo-new.png" alt="logo" />
            </div>
            <div className='w-[400px] relative p-[20px] rounded-[7px] bg-white text-[#222222] flex flex-col items-center border-[1px] border-[#e4e4e7] shadow-lg text-center'>
                <h1 className="text-[2rem] font-[700] mb-5" >Reset password</h1>
                <p>Enter your e-mail so we can send you a password reset link!</p>
                <input
                    type="email"
                    placeholder="example@example.com"
                    className="w-full h-[40px] border-[1px] border-[#e4e4e7] bg-[#f9f9fa] rounded-[5px] p-[5px] my-2"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete="off"
                />
                <p className="mt-2"><Link className="underline" to={"/login"}>Go back</Link></p>
                <Button
                    disabled={!verifyEmail ? true : false}
                    className={`w-full h-[40px] text-white rounded-[5px] mt-[10px] ${ success ? 'bg-[#020817]' : 'bg-[#2563eb]'}`}
                    onClick={() => handleSubmit()}
                >{ success ? "Reset link sent!" : "Send reset link"}</Button>
            </div>
        </div>
    );
}

export default Forgot;

export const ResetPassword = () => {

    const [ password, setPassword ] = useState('');
    const [ validPwd, setValidPwd ] = useState(false);
    const [ validMatch, setValidMatch ] = useState(false);
    const [ repeatPassword, setRepeatPassword ] = useState('');
    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState('');
    const { token } = useParams();

    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(password));
        setValidMatch(password === repeatPassword);
    }, [password, repeatPassword])

    const handleSubmit = async () => {

        if (!PWD_REGEX.test(password)) {
            setError("Invalid fields");
            return;
        }

        try {
            await axios.post('/api/passwordReset', JSON.stringify({ password, token }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSuccess(true);
            setError('');
            setPassword('');
            setRepeatPassword('');
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    }

    return (
        <div className='w-[100vw] h-[100vh] font-geist color-white flex items-center justify-center gap-[10px] relative text-[.9rem]' style={{backgroundImage: `url(/svg/Maze.svg)`}}>
            <div className='w-[400px] relative p-[20px] rounded-[7px] bg-white text-[#222222] flex flex-col items-center border-[1px] border-[#e4e4e7] shadow-lg text-center'>
                <h1 className="text-[2rem] font-[700] mb-5" >New password</h1>
                <label htmlFor="pwd1">Enter new password</label>
                <input
                    type="password"
                    id="pwd1"
                    required
                    className="px-[5px] w-full h-[40px] bg-[#fbfbfc] border-[1px] border-[#e4e4e7] rounded-md"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <label htmlFor="pwd2">Repeat password</label>
                <input
                    type="password"
                    id="pwd2"
                    required
                    className="px-[5px] w-full h-[40px] bg-[#fbfbfc] border-[1px] border-[#e4e4e7] rounded-md"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                />
                <p className="text-[.75rem] rounded-sm bg-[#020817] text-white p-[.5rem] mt-2">
                    <FaInfoCircle color="#0362fc" />
                    8 to 24 characters.<br />
                    Must include uppercase and lowercase letters, a number and a special character.<br />
                    Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                </p>
                <button
                    onClick={() => handleSubmit()}
                    disabled={!validPwd || !validMatch ? true : false}
                    className={`w-full h-[40px] text-white rounded-[5px] my-[10px] ${ success ? "bg-[#427a2a]" : "bg-[#2563eb]"} disabled:bg-[#333333]`}
                >{ success ? "Password changed" : (!validPwd || !validMatch) ? "Passwords don't match" : "Change password"}</button>
                {
                    error &&
                    <Alert
                        className="bg-[#fcf1f0]"
                        variant="destructive"
                    >
                        <Terminal className="h-4 w-4" />
                        <AlertTitle>Error!</AlertTitle>
                        <AlertDescription>
                            {error}
                        </AlertDescription>
                    </Alert>
                }
            </div>
        </div>
    );
}