import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './App';
import store from "./redux/store";
import "./globals.css";
import { AuthProvider } from './context/authProvider';
import { MAuthProvider } from './context/mauthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <MAuthProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </MAuthProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

