import { useSelector } from "react-redux";
import React, { useState } from "react";

const Interview = React.memo(({ socket }) => {

    const config = useSelector(state => state.config);
    const data = useSelector(state => state.data);

    const competitors = data?.data?.lastRaceCompetitors;

    const customInterviewData = config?.customInterviewData;
    const competitorInterviewData = config?.competitorInterviewData;

    const [ name, setName ] = useState(customInterviewData?.name || '');
    const [ description, setDescription ] = useState(customInterviewData?.description || '');
    const [search, setSearch] = useState('');

    const filteredCompetitors = competitors?.filter(competitor => {
        const searchString = `${competitor.nr} ${competitor.firstname} ${competitor.lastname}`.toLowerCase();
        return searchString.includes(search.toLowerCase());
    });

    const handleCustomSave = () => {
        socket.emit('update', { what: 5, data: ['customInterviewData', {
            name: name, description: description
        }]}, (response) => {});
    }

    const handleCompetitorSave = (competitor) => {
        socket.emit('update', { what: 5, data: ['competitorInterviewData', competitor]}
        , (response) => {});
    }

    return (
        <div className="w-full h-full flex gap-2 p-3 text-[#222222]">
            {
                competitors ?
                <div className="w-1/2 h-full rounded-md border border-[#e4e4e7] p-3 shadow-md">
                    <p className="text-center text-[1rem]">Competitors</p>
                    <input
                        className="border border-[#e4e4e9] bg-[#f9f9fa] px-1 rounded-md h-[40px] w-full"
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)} placeholder="Search..."
                    />
                    <div className="h-[calc(100%-110px)] w-full overflow-y-auto white-slider">
                    <table className="w-full">
                        <thead>
                            <tr className="h-[30px] border-b border-b-[#e4e4e7]">
                                <th className="text-start">Start nr.</th>
                                <th className="text-start">Name</th>
                                <th className="text-start">Surname</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            filteredCompetitors?.map((comp, index) => {
                                return (
                                    <tr
                                        key={`competitor_interview_${index}`}
                                        className={
                                            `h-[30px] border-b border-b-[#e4e4e7] cursor-pointer ${
                                                comp?.nr === competitorInterviewData?.nr ?
                                                'bg-[#333333] text-white' :
                                                'bg-none text-[#222222]'
                                            }`
                                        }
                                        onClick={() => handleCompetitorSave(comp)}
                                    >
                                        <td>{comp?.nr}</td>
                                        <td>{comp?.firstname}</td>
                                        <td>{comp?.lastname}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                :
                <div className="w-1/2 h-full rounded-md border border-[#e4e4e7] p-3 shadow-md flex flex-col items-center justify-center">
                    <p className="text-[1.3rem] font-bold">Wait up!</p>
                    <span>Competitors selection becomes available after the finish flag</span>
                </div>
            }

            <div className="p-3 h-full w-[calc(50%-8px)] rounded-md border border-[#e4e4e7] text-[#222222] shadow-md">
                <p className="text-center text-[1rem]">Custom inverview</p>
                <div className="flex flex-col items-center gap-2 mt-2">
                    <label htmlFor="name">Name & Surname</label>
                    <input
                        className="bg-[#f9f9fa] border border-[#e4e4e7] rounded-md h-[40px] px-1 w-2/3"
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="description">Description</label>
                    <input
                        className="bg-[#f9f9fa] border border-[#e4e4e7] rounded-md h-[40px] px-1 w-2/3"
                        type="text"
                        name="descripton"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <button
                        className="bg-[#333333] text-white rounded-md h-[40px] w-2/3 mt-2"
                        onClick={handleCustomSave}
                    >Save</button>
                </div>
            </div>
        </div>
    );
});

export default Interview;