import { Box } from "../../../../../../../components/custom/Box";
import useAuth from "../../../../../../../hooks/useAuth";

import useAxiosPrivate from "../../../../../../../hooks/useAxiosPrivate";

import { useState, useEffect } from "react";
import { ConfirmationChangePassword, ConfirmationChangeAssistant } from "../../../ConfirmAction";

const Settings = () => {

    const { auth } = useAuth();
    const [ show, setShow ] = useState(false);
    const [ marshall, setMarshall ] = useState({});
    const [ requestChange, setRequestChange ] = useState(false);
    const [ requestAssistantChange, setRequestAssistantChange ] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const getData = async () => {
            try {
                const resp = await axiosPrivate.get(`/marshall/${auth?.userId}`);
                setMarshall(resp.data);
            } catch (err) {
                console.log(err);
            }
        }
        getData();
    }, []);

    return (
        <>
        {
            requestChange && <ConfirmationChangePassword callback={(v) => setRequestChange(false)} />
        }
        {
            requestAssistantChange && <ConfirmationChangeAssistant callback={(v) => setRequestAssistantChange(false)} data={marshall}/>
        }
        <Box className="text-[#222222] w-full h-full !p-[20px] !rounded-none">
            <div className="w-full flex gap-[5px] items-end mb-[10px]"><p className="text-[1.4rem]">Hello, </p><span className="text-[2rem] font-[700]">{auth?.user}</span></div>
            <div className="w-full h-fit flex items-center justify-between bg-[#f9f9fa] p-[15px]">
                <span>Email</span><p>{auth?.email}</p>
            </div>
            <hr className="w-full h-[1px] border-none bg-[#e4e4e7]"/>
            <div className="w-full h-fit flex items-center justify-between bg-[#f9f9fa] p-[15px]">
                <span>User ID</span>
                <button
                    onClick={() => setShow(prev => !prev)}
                    className="py-[5px] px-[15px] rounded-[4px] bg-[#333333] text-white"
                >{ show ? auth?.userId : "Show User ID"}</button>
            </div>
            <hr className="w-full h-[1px] border-none bg-[#e4e4e7]"/>
            <div className="w-full h-fit flex items-center justify-between bg-[#f9f9fa] p-[15px]">
                <span>Password</span>
                <button
                    className="py-[5px] px-[15px] rounded-[4px] bg-[#333333] text-white"
                    onClick={() => setRequestChange(true)}
                >Request password change</button>
            </div>
            <hr className="w-full h-[1px] border-none bg-[#e4e4e7]"/>
            <div className="w-full h-fit flex items-center justify-between bg-[#f9f9fa] p-[15px]">
                <span>Assistant access</span>
                <button
                    className="py-[5px] px-[15px] rounded-[4px] bg-[#3d993c] text-white cursor-default"
                >{ Object.keys(marshall) ? "Available" : "Unavailable"}</button>
            </div>
            {
                Object.keys(marshall) &&
                <div className="w-full h-fit bg-[#f9f9fa] p-[15px] flex flex-col gap-5">
                    <div className="w-full h-fit flex items-center justify-between ">
                        <span>Assistant details</span>
                        <button
                            onClick={() => setRequestAssistantChange(true)}
                            className="px-[15px] py-2 rounded-md bg-[#333333] text-white"
                        >Request assistant details change</button>
                    </div>
                </div>
            }
        </Box>
        </>
    );
}

export default Settings;