import React from 'react';

const DesignItem = React.memo(({ design, currentDesign, setSelectedDesign, index, max }) => {

    return (
        <div
            className="w-full h-[160px] shrink-0 bg-no-repeat bg-cover flex font-geist mt-2 rounded-[10px] select-none shadow-md"
            style={{backgroundImage: `url(/images/${design.designImage}.png`}}
            id={`design_item_${index}`}
        >
            <div className="w-full h-full bg-[#0c111ce8] relative flex items-center justify-center p-5 rounded-[10px]">
                <div className="absolute top-0 left-3 font-bold text-[3rem]">
                    <p>{design.name}</p>
                </div>
                <button
                    className="h-[40px] px-5 text-white rounded-md cursor-pointer ml-3 absolute bottom-2 left-0 z-10"
                    onClick={() => setSelectedDesign(design.styleName)}
                    style={{background: currentDesign === design.styleName ? "#5d60ef" : "#333645"}}
                >
                    {currentDesign === design.styleName ? "Your current design" : "Select design"}
                </button>
                <div className="w-full h-full flex justify-end">
                    <div className="w-2/3 h-1/2 text-[.75rem] absolute left-3 bottom-4 text-[#d4d4d4]">
                        <span>{design.description}</span>
                    </div>
                    <div className="w-1/4 bg-[#232531] p-2 rounded-md">
                        <span className="mb-2 text-[.9rem]">Design includes</span>
                        <div className='w-full flex gap-1 flex-wrap'>
                            {
                                design.tags.map((tag, index2) =>
                                    <div className="w-[calc(50%-5px)] h-[30px] text-[.75rem] text-center rounded-md flex items-center justify-center" key={`design_tag_${index2}`} style={{background: tag.background, color: tag.color}}>
                                        {tag.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DesignItem;