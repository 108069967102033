import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";

const Activate = () => {

    const { hash } = useParams();
    const [ valid, setValid ] = useState(false);

    useEffect(() => {
        const activate = async () => {
            try {
                await axios.post('/activate', { token: hash });
                setValid(true);
            } catch (err) {
                if (err.response && err.response.status === 402) {
                    // Handle 402 error here
                    console.log('402 error occurred');
                } else {
                    // Handle other errors here
                    console.log(err);
                }
            }
        }

        if (hash) {
            activate();
        }
    }, [hash]);

    if ( valid ) {
        return (
            <div
                className="w-[100vw] h-[100vh] flex items-center justify-center bg-[#222222] font-geist text-[.9rem] font-geist"
                style={{backgroundImage: `url(/svg/Maze.svg)`}}
            >
                <div className="w-fit h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-md text-center">
                    <h1 className="font-[700] text-[1.6rem] mb-2">Email verified!</h1>
                    <p>Wait for an administrator to approve your account.</p>
                    <p>You can now close this page.</p>
                </div>
            </div>
        );
    }

    return null;
};

export default Activate;