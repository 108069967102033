import { createContext, useState } from "react";

const MAuthContext = createContext({});

export const MAuthProvider = ({ children }) => {
    const [mauth, setMAuth] = useState({});

    return (
        <MAuthContext.Provider value={{ mauth, setMAuth }}>
            {children}
        </MAuthContext.Provider>
    )
}

export default MAuthContext;