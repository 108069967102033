import "./TimerWidget.scss";
import { BsStopwatchFill } from "react-icons/bs"

import { motion } from "framer-motion";

const TimerWidget = (props) => {
    var raceTime = "";
    if (["Finish", "None"].includes(props.data?.data?.raceDetails?.flag)) {
        raceTime = "FINISH";
    } else {
        raceTime = props.data?.data?.raceTimer?.name + ": % " + props.data?.data?.raceTimer?.data;
    }
    const raceName = props.data?.data?.raceDetails?.name;

    const palette = props?.config?.palette?.palette;

    switch(props.config?.style) {
        case "v2":
            return (
                    <motion.div
                        className="app__time-v2-main"
                        style={{
                            top: props?.preview ? "30px" : props.config?.buttons?.timer?.top,
                            left:props?.preview ? "30px" : props.config?.buttons?.timer?.left
                        }}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0, scale: 0}}
                    >
                        <motion.div
                            className="app__time-v2-top"
                            initial={{scaleX: 0, opacity: 0}}
                            animate={{scaleX: 1, opacity: 1}}
                            transition={{delay: 0.2, duration: 0.2, bounce: false}}
                            style={{background: palette?.primary}}
                        >

                            <BsStopwatchFill size={20} />
                            <p>{props.data?.data?.raceTime?.elapsed_time}</p>

                        </motion.div>
                        <motion.div
                            className="app__time-v2-mid overflow-hidden"
                            style={{ background: palette?.secondary }}
                            initial={{scaleX: 0, opacity: 0}}
                            animate={{scaleX: 1, opacity: 1}}
                            transition={{delay: 0.3, duration: 0.2, bounce: false}}
                        >
                            <p className={raceName?.length > 22 ? 'scrolling-text' : 'justify-center'}>{raceName}</p>
                        </motion.div>
                        <motion.div
                            className="app__time-v2-bottom"
                            style={{background: palette?.primary}}
                            initial={{y: -30, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{delay: 0.4, duration: 0.2, bounce: false}}
                        >
                        {(
                            () => {
                                if (raceTime.includes("%")) {
                                    const raceTimeDivided = raceTime.split("%");
                                    return (
                                        <div className="app__time-v2-divided">
                                            <h3>{raceTimeDivided[0]}</h3>
                                            <h3>{raceTimeDivided[1]}</h3>
                                        </div>
                                    );
                                }
                                return (<h3>{raceTime}</h3>);
                            }
                        )()}
                        </motion.div>
                    </motion.div>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_timer"
                    style={{
                        top: props?.preview ? "30px" : props.config?.buttons?.timer?.top,
                        left:props?.preview ? "30px" : props.config?.buttons?.timer?.left
                    }}
                >
                    <motion.div
                        className="k1_timer_time_elapsed"
                        initial={{clipPath: 'inset(0 0 0 100%)'}}
                        animate={{clipPath: 'inset(0 0 0 0)', transition: {delay: 0.7, duration: 0.5}}}
                        exit={{clipPath: 'inset(0 0 0 100%)'}}
                    >
                        <BsStopwatchFill size={20} color={"#f7c700"} />
                        {props.data?.data?.raceTime?.elapsed_time}
                    </motion.div>
                    <motion.div
                        className="k1_timer_name"
                        initial={{opacity: 0, scaleX: 0}}
                        animate={{opacity: 1, scaleX: 1, transition: {duration: 0.5}}}
                        exit={{opacity: 0, scaleX: 0, transition: {delay: 0.5}}}
                    >
                        <p className={raceName?.length > 22 ? 'scrolling-text' : ''}>{raceName}</p>
                    </motion.div>
                    <motion.div
                        className="k1_timer_time_current"
                        initial={{clipPath: 'inset(0 100% 0 0)'}}
                        animate={{clipPath: 'inset(0 0 0 0)', transition: {delay: 0.7, duration: 0.5}}}
                        exit={{clipPath: 'inset(0 100% 0 0)'}}
                    >
                    {(
                        () => {
                            if (raceTime.includes("%")) {
                                const raceTimeDivided = raceTime.split("%");
                                return (
                                    <div className="app__time-v2-divided">
                                        <h3>{raceTimeDivided[0]}</h3>
                                        <h3>{raceTimeDivided[1]}</h3>
                                    </div>
                                );
                            }
                            return (<h3>{raceTime}</h3>);
                        }
                    )()}
                    </motion.div>
                </motion.div>
            );
        case "m1":
            return (
                <div
                    className="m1_timer"
                    style={{
                        top: props?.preview ? "30px" : props.config?.buttons?.timer?.top,
                        left:props?.preview ? "30px" : props.config?.buttons?.timer?.left
                    }}
                >
                    <motion.div
                        className="m1_timer_left"
                        initial={{opacity: 0, scaleY: 0}}
                        animate={{opacity: 1, scaleY: 1, transition: {delay: 0.5}}}
                        exit={{opacity: 0, scaleY: 0}}
                    >
                        <BsStopwatchFill size={20} color={"#f7c700"} />
                        {props.data?.data?.raceTime?.elapsed_time}
                    </motion.div>
                    <motion.div
                        className="m1_timer_mid"
                        initial={{opacity: 0, scaleX: 0}}
                        animate={{opacity: 1, scaleX: 1, transition: {duration: 0.5}}}
                        exit={{opacity: 0, scaleX: 0, transition: {delay: 0.5}}}
                    >
                        <p className={raceName?.length > 22 ? 'scrolling-text' : ''}>{raceName}</p>

                    </motion.div>
                    <motion.div
                        className="m1_timer_right"
                        initial={{opacity: 0, scaleY: 0}}
                        animate={{opacity: 1, scaleY: 1, transition: {delay: 0.5}}}
                        exit={{opacity: 0, scaleY: 0}}
                    >
                    {(
                        () => {
                            if (raceTime.includes("%")) {
                                const raceTimeDivided = raceTime.split("%");
                                return (
                                    <div className="app__time-v2-divided">
                                        <h3>{raceTimeDivided[0]}</h3>
                                        <h3>{raceTimeDivided[1]}</h3>
                                    </div>
                                );
                            }
                            return (<h3>{raceTime}</h3>);
                        }
                    )()}
                    </motion.div>
                </div>
            );
    }

};
export default TimerWidget;