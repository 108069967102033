import { useState, useEffect, useCallback } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import useAuth from './useAuth';

const useTokenBalance = () => {
    const [ balance, setBalance ] = useState(0);
    const [ loading, setLoading ] = useState(true);

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const reloadBalance = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axiosPrivate.post('/getBalance', { userId: auth.userId });
            setBalance(response.data.tokens);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [auth.userId, axiosPrivate]);

    useEffect(() => {
        reloadBalance();
    }, [reloadBalance]);

    return { balance, loading, reloadBalance };
}

export default useTokenBalance;