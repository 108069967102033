import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth(); 
    const location = useLocation();

    const ret = {
        ok: <Outlet />,
        unauthorized: <Navigate to="/unauthorized" state={{ from: location }} replace />,
        login: <Navigate to="/login" state={{ from: location }} replace />
    };

    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
        ? ret.ok : auth?.accessToken ? ret.unauthorized : ret.login
    );
}

export default RequireAuth;