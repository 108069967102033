

export const Formation = ({ config }) => {
    return (
        <div 
            className="absolute w-[240px] h-[60px] p-[10px] bg-[#191921]
            rounded-[10px] outline outline-2 outline-[#ea324d] outline-offset-[-5px]
            text-white font-[F1-Bold] text-[1.5rem] flex items-center justify-center"
            style={{
                left: config?.buttons?.Formation?.left,
                top: config?.buttons?.Formation?.top
            }}
        >
            <p>Formation lap</p>
        </div>
    );
}

export const SafetyCar = ({ config }) => {
    return (
        <div
            className="absolute w-[240px] h-[60px] p-[10px] bg-[#191921]
            rounded-[10px] outline outline-3 outline-[#f7d00a] outline-offset-[-5px]
            text-[#f7d00a] font-[F1-Bold] text-[1.5rem] flex items-center justify-center"
            style={{
                left: config?.buttons?.safetyCar?.left,
                top: config?.buttons?.safetyCar?.top
            }}
        >
            <p>Safety car</p>
        </div>
    );
}