import { useEffect, useState } from "react";
import useAuth from "../../../../../../../hooks/useAuth";

const InteractiveEnabledGrid = () => {

    const { auth } = useAuth();

    // .slice(0, -4)
    const link = `http://localhost:3000/graphics/${encodeURIComponent(auth.userId)}`

    const handleResize = () => {
        const iframe = document.getElementById('interactive_main_grid_src');
        const wrapper = document.getElementById('interactive-screen-main-enabled-grid-wrapper');

        const scale = Math.min(
            wrapper.offsetWidth / iframe.offsetWidth,
            wrapper.offsetHeight / iframe.offsetHeight
        );
        iframe.style.transform = `scale(${scale})`;
    };

    useEffect(() => {

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <div
            className="interactive-screen-main-enabled-grid-wrapper"
            id="interactive-screen-main-enabled-grid-wrapper"
        >
            <iframe
                className="interactive-screen-main-enabled-grid-src"
                id="interactive_main_grid_src"
                src={link}
                width="1920"
                height="1080"
                allowTransparency="true"
            />
        </div>
    );
};

export default InteractiveEnabledGrid;