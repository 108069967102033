
import { flagToColor } from "../../../../../Functions";
import useGetActiveRaces from "../../../../../hooks/useGetActiveRaces";
import useSetRaceKey from "../../../../../hooks/useSetRaceKey";

import { FaFlag } from "react-icons/fa6";

const AvailableRacesComponent = () => {

    const { active, loading } = useGetActiveRaces();
    const set = useSetRaceKey();

    const ret = {
        loading:
            <div className="w-full h-full flex flex-col gap-2 items-center justify-center text-[#222222]">
                <div className="border-[32px] rounded-[50%] w-[120px] h-[120px] spinner"/>
                <h4>Loading active race list...</h4>
            </div>,
        noData:
            <div className="w-full h-full flex flex-col gap-2 items-center justify-center text-white">
                <div className="w-fit h-fit p-3 py-12 bg-[#f98a8a] border border-[#ff8181] text-center rounded-md">
                    <p className="text-[1.1rem] font-bold">{active.error ? "Error" : "Looks like nothing is happening right now!"}</p>
                    <span>{
                        active.error || "Try refreshing races from the Races tab to see if any race is available"
                    }</span>
                </div>
            </div>,
        ok:
            <div className="w-full h-full relative p-3 text-[#222222]">
                <header className="w-full h-[100px] text-center">
                    <span className="text-[1.9rem] font-bold">Available races</span>
                    <p className="text-[.9rem]">Browse between currently active races and pick your one</p>
                </header>
                <table className="w-full">
                    <thead className="table-head border-b">
                        <tr>
                            <th>Race Type</th>
                            <th>Championship</th>
                            <th>Track</th>
                            <th>Flag</th>
                            <th className="!text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            active && active?.raceData?.map((race, index) =>
                                <tr
                                    className="h-8 border-b"
                                    key={`race-hor-item${index}`}
                                >
                                    <td>{race?.race_type}</td>
                                    <td>{race?.championship}</td>
                                    <td>{race?.track_name}</td>
                                    <td>
                                        {
                                            race?.flag === "None" ?
                                            <span className="px-2 py-1 rounded-md bg-[#ef4444] text-white">No flag</span>
                                            :
                                            <FaFlag color={flagToColor(race?.flag)} size={"15px"} title={race?.flag}/>
                                        }
                                    </td>
                                    <td>
                                        <button
                                            className="bg-[#5d60ef] text-white w-full h-[30px] rounded-md"
                                            onClick={() => set(race?.token)}
                                        >Select</button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
    }

    return loading ? ret.loading : (active && !active.error) ? ret.ok : ret.noData;
};

export default AvailableRacesComponent;