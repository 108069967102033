

import "./Marshall.scss";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import useMAuth from "../../hooks/useMAuth";
import io from "socket.io-client";
import { getRaceBackendAddress, flagToColor } from "../../Functions";
import axios from "../../api/axios";

import Joker from "./components/Joker/Joker";

const GRAPHICS_SERVER_ADDRESS = getRaceBackendAddress();

const Marshall = () => {

    const { mauth, setMAuth } = useMAuth();
    const navigate = useNavigate();

    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {

        const connectToSocketServer = async () => {
            const sock = await io.connect(GRAPHICS_SERVER_ADDRESS, {
                query: {
                    userType: "Marshall",
                    userId: mauth.userId,
                }
            });
            setSocket(sock);
            setLoading(false);
        }

        connectToSocketServer();
    }, []);

    useEffect(() => {
        const sendLocation = async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        await axios.post('/location', { latitude, longitude });
                    } catch (err) {
                        console.error(err);
                    }
                });
            }
        };

        sendLocation();

        // Then send location every minute
        const intervalId = setInterval(sendLocation, 60000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        socket?.on("getApi", setData);

        // Notify server when Marshall is connected
        socket?.on("connect", () => {
            setTimeout(() => {
                socket.emit("update", {what: 5, data: ["isMarshallConnected", true]}, (callback) => {
                    console.log(callback);
                });
            }, 1000);

        });

        // Cleanup function
        return () => {
            socket?.emit("update", {what: 5, data: ["isMarshallConnected", false]}, (callback) => {
                console.log(callback);
            });
            socket?.off("getApi", setData);
            socket?.off('connect');
        };
    }, [socket]);

    const logout = () => {
        setMAuth({});
        navigate("/login");
    }

    return (
        <div className="w-full h-[100vh] bg-white text-[#222222] px-4 py-4">
            <div className="w-full h-[100px] flex items-center justify-between bg-[#e4e4e7] rounded-[7px] p-[10px]">
                <p>Welcome</p>
                <span>{mauth?.user}</span>
                <div className="w-fit h-[60px] flex items-center gap-[10px] px-4">
                    {
                        !data ? <span>No data received</span> :
                        <>
                            <div className="w-fit h-full px-4 flex items-center bg-[#f9f9fa]">
                                <h3>Race name</h3>
                                <span>{data?.data?.raceDetails?.name}</span>
                            </div>
                            <div className="w-fit h-full px-4 flex items-center bg-[#f9f9fa]">
                                <h3>Flag</h3>
                                <h2 style={{background: flagToColor(data?.data?.raceDetails?.flag)}}/>
                            </div>
                        </>
                    }
                </div>
                <button onClick={() => logout()}><MdLogout size={"40px"}/></button>
            </div>
            <div className="w-full h-[calc(100%-116px)] mt-4 bg-[#f9f9fa] border-[1px] border-[#e4e4e7] rounded-[7px]">
                <Joker data={data} socket={socket}/>
            </div>
        </div>
    );
};

export default Marshall;