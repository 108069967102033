
import "./Console.scss";

import DayScheduler from "./DayScheduleWidgets";

import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FavoriteButtons } from "../InteractiveButtons/Buttons";
import { useButtonValidator } from "../InteractiveButtons/stateValidator";
import MessageStore from "../../../../../managers/ConsoleMessageManager";

import { PiEraserFill } from "react-icons/pi";
import { FaMapMarkedAlt } from "react-icons/fa";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';

const ConsoleComponentChild = ({socket}) => {

    const config = useSelector(state => state.config);
    const [activity, addActivity] = useState([]);
    const [map, toggleMap] = useState(false);

    const mapRef = useRef(null);
    const [coords, setCoords] = useState([]);

    const activityEndRef = useRef(null);

    useEffect(() => {
        activityEndRef.current.scrollTop =  activityEndRef.current.scrollHeight;
    }, [activity]);

    useEffect(() => {
        if (map) {
            const mapInstance = L.map(mapRef.current).setView([0,0], 2);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '',
            }).addTo(mapInstance);

            const onMapClick = (event) => {
                const { lat, lng } = event.latlng;
                setCoords([lat, lng]);
            };
            mapInstance.on('click', onMapClick);
            return () => {
                mapInstance.off("click", onMapClick);
                mapInstance.remove();
            }
        }
    }, [map]);


    useEffect(() => {
        socket.on("jokerLapCallback", (callback) => {
            addActivity(prevActivity => {
                let newActivity = [...prevActivity, callback];
                if (newActivity.length > 8) {
                    newActivity.shift(); // Remove the first item if there are more than 8 activities
                }
                return newActivity;
            });
        })
        return () => socket.off("jokerLapCallback");
    }, []);

    return (
    <div className="console-child-data relative">
        {
            map &&
            <div className="absolute top-[-402px] h-[400px] w-[500px] bg-[#e4e4e7] rounded-md z-999 p-[5px] border-[1px] border-[#222222]">
                <div className="w-full h-full rounded-sm" ref={mapRef} />
            </div>
        }
        <div
            className="!bg-[#020817] p-[8px] w-fit rounded-sm cursor-pointer"
            onClick={() => toggleMap(prev => !prev)}
        >
            <FaMapMarkedAlt size={"15px"}/>
        </div>
        <header className="border-b-[1px] border-[#222222] mb-1">Marshall</header>
        <p
            style={{ background: config?.isMarshallConnected ? "#3d993c" : "#222222" }}
        >{config?.isMarshallConnected ? "Online" : "Offline"}</p>
        <div className="console-child-data-activity" ref={activityEndRef}>
            {
                activity.map((item, index) =>
                    <div
                        className="console-child-data-activity-item"
                        key={`activity_${index}`}
                        style={{background: item?.action === "-" ? "#ed4747" : "#3d993c"}}
                    >
                        <div className="flex gap-[5px]"><p>{item?.action}</p><p>{item?.message}</p></div>
                    </div>
                )
            }
        </div>
    </div>
    );
};


const MessageCreator = (message, index) => {
    const msg = message.message;
    const status = msg.status;
    const longMessage = msg?.parent === 6;

    const isOk = status === 200;

    return (
        <div className={`message-creator ${longMessage ? "message-long" : ""}`} key={`message_${index}`} title={msg.message}>
            <div
                className="message-creator-head"
                style={{background: isOk ? "#3d993c" : "#b53636"}}
            >
                <p>{isOk ? "OK" : "ERROR"}</p>
            </div>
            <div className="message-creator-body">
                <p>{longMessage ? ` > ${msg.message}` : ` ${msg.message}`}</p>
            </div>
        </div>
    );
};

const _ConsoleOut = () => {

    const [messages, setMessages] = useState([]);
    const consoleOutputRef = useRef(null);

    useEffect(() => {
        // Function to call when a new message is added
        const handleNewMessage = () => {
            setMessages(MessageStore.getMessages());
        };

        handleNewMessage();

        // Listen for new messages
        MessageStore.addListener(handleNewMessage);

        // Clean up the listener when the component unmounts
        return () => {
            MessageStore.removeListener(handleNewMessage);
        };
    }, []);

    // Scroll to the bottom whenever the messages state changes
    useEffect(() => {
        consoleOutputRef.current.scrollTop = consoleOutputRef.current.scrollHeight;
    }, [messages]);

    return (
        <div className="console-out" ref={consoleOutputRef}>
            {
                messages.map((message, index) =>
                    <MessageCreator message={message} index={index} key={`msg_console_${index}`}/>
                )
            }
        </div>
    );
}

const Console = () => {

    return (
        <div className="console-small">
            <header>Output<p><PiEraserFill size={"15px"}/></p></header>
            <_ConsoleOut />
            <input className="console-in" type="text" placeholder={`Enter command...` }/>
        </div>
    );
}

const MasterToggle = ({ socket }) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(true);
        socket.emit("update", { what: 6, data: ['master']});
    };

    useEffect(() => {
        if (isActive) {
            const timer = setTimeout(() => {
                setIsActive(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isActive]);

    return (
        <div className={`w-full h-full rounded-[5px] border ${isActive ? 'flash-bttn' : 'bg-[#ef4444]'}`}>
            <button
                className="text-top-bttm w-full h-full flex items-center justify-center text-[1.2rem]"
                onClick={handleClick}
                title="Turn off all graphics with one button"
            >MASTER OFF</button>
        </div>
    );
}
const ConsoleComponent = ({ socket }) => {

    const errors = useButtonValidator();
    const config = useSelector(state => state.config);

    return (
        <div className="console-wrapper">
            <div className="console-component">
                <FavoriteButtons config={config} socket={socket} errors={errors}/>
            </div>
            <div className="w-16 p-2 pb-0 pr-0">
                <MasterToggle socket={socket} />
            </div>
            <div className="day-schedule-placeholder">
                <DayScheduler socket={socket}/>
            </div>
            <div className="console-component-child">
                <ConsoleComponentChild socket={socket} />
            </div>
            <div className="console-component-console">
                <Console />
            </div>
        </div>
    );
};

export default ConsoleComponent;