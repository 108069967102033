import useAuth from "../../../../hooks/useAuth";
import useLogout from "../../../../hooks/useLogout";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { FaEye, FaInfoCircle } from "react-icons/fa";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";

import { useState, useEffect } from "react";

import { Wheel } from "@uiw/react-color";
import ShadeSlider from '@uiw/react-color-shade-slider';
import { hsvaToHex, hexToHsva } from '@uiw/color-convert';

export const Confirmation = ({ message, callback }) => {

    return (
        <div className="confirmator">
            <div className="confirmator-popout">
                <h2>Confirm action</h2>
                <p>{message}</p>
                <div className="confirmator-buttons">
                    <button className="!bg-[#333333]" onClick={() => callback(true)}>Yes</button>
                    <button onClick={() => callback(false)}>No</button>
                </div>
            </div>
        </div>
    );
};

export const ConfirmationChangePassword = ({ callback }) => {
    const [ oldPassword, setOldPassword ] = useState('');
    const [ seeOldPassword, setSeeOldPassword ] = useState(false);

    const [ newPassword, setNewPassword ] = useState('');
    const [ seeNewPassword, setSeeNewPassword ] = useState(false);

    const [ repeatPassword, setRepeatPassword ] = useState('');
    const [ seeRepeatPassword, setSeeRepeatPassword ] = useState(false);

    const [ validPwd, setValidPwd ] = useState(false);
    const [ match, setMatch ] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const logout = useLogout();
    const { auth } = useAuth();
    const [ resp, setResp ] = useState(null);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(newPassword));
        setMatch(newPassword === repeatPassword);
    }, [newPassword, repeatPassword]);

    useEffect(() => {
        setResp('');
    }, [newPassword, repeatPassword, oldPassword]);

    const handleSubmit = async () => {
        if (!PWD_REGEX.test(newPassword)) return;
        if (newPassword !== repeatPassword) return;

        try {
            const response = await axiosPrivate.post(
                '/api/resetPassword',
                { newPassword, oldPassword, userId: auth.userId }
            );
            setResp(response.data);
            setTimeout(async () => {
                await logout();
            }, 1000)

        } catch (err) {
            console.log(err);
            if (err?.response?.status === 402) {
                setResp({msg: "Incorrect password", col: "#e94646"});
            }
        }
    }

    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    return (
        <div className="confirmator">
            <div className="w-[400px] h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-lg">
                <h1 className="text-[1.6rem] font-[700] text-center" >Change password</h1>
                <p className="text-center">Change password for your streamer account.</p>
                <label className='mb-1' htmlFor="oldpwd">Current password</label>
                <div className='flex gap-[5px]'>
                    <Input
                        className="px-[5px] w-[calc(100%-45px)] h-[40px] bg-[#fbfbfc]"
                        type={ seeOldPassword ? 'text' : 'password'}
                        id='password'
                        autoComplete='off'
                        onChange={(e) => setOldPassword(e.target.value)}
                        value={oldPassword}
                        required
                    />
                    <button
                        type="button"
                        className='w-[40px] h-[40px] rounded-md bg-[#020817] text-white flex items-center justify-center'
                        onClick={() => setSeeOldPassword(prev => !prev)}
                    >
                        <FaEye size={"18px"}/>
                    </button>
                </div>
                <label className='mb-1' htmlFor="newpwd1">New password</label>
                <div className='flex gap-[5px]'>
                    <Input
                        className="px-[5px] w-[calc(100%-45px)] h-[40px] bg-[#fbfbfc]"
                        type={ seeNewPassword ? 'text' : 'password'}
                        id='password'
                        autoComplete='off'
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                        required
                    />
                    <button
                        type="button"
                        className='w-[40px] h-[40px] rounded-md bg-[#020817] text-white flex items-center justify-center'
                        onClick={() => setSeeNewPassword(prev => !prev)}
                    >
                        <FaEye size={"18px"}/>
                    </button>
                </div>
                <label className='mb-1' htmlFor="newpwd2">Repeat new password</label>
                <div className='flex gap-[5px] mb-2'>
                    <Input
                        className={`px-[5px] w-[calc(100%-45px)] h-[40px] ${ match ? "bg-[#fbfbfc]" : "bg-[#f8e4e4]"} ${ match ? 'border-[#e4e4e7]' : 'border-[#fba8a8]'}`}
                        type={ seeRepeatPassword ? 'text' : 'password'}
                        id='password'
                        autoComplete='off'
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        value={repeatPassword}
                        required
                    />
                    <button
                        type="button"
                        className='w-[40px] h-[40px] rounded-md bg-[#020817] text-white flex items-center justify-center'
                        onClick={() => setSeeRepeatPassword(prev => !prev)}
                    >
                        <FaEye size={"18px"}/>
                    </button>
                </div>
                <p className="text-[.75rem] rounded-sm bg-[#020817] text-white p-[.5rem] mb-2 relative">
                    <FaInfoCircle color="#0362fc" className="absolute right-2 top-2" size={"17px"}/>
                    8 to 24 characters.<br />
                    Must include uppercase and lowercase letters, a number and a special character.<br />
                    Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                </p>
                <p className="text-[.75rem rounded-sm text-white bg-[#e94646] p-[.5rem] mb-2 text-center">Warning: You will be automaticly signed out after changing your password!</p>
                <Button
                    className={`w-[calc(50%-5px)] mr-[10px] ${resp?.col ? `bg-[${resp.col}]` : ''}`}
                    disabled={(validPwd && match) ? false : true}
                    onClick={() => handleSubmit()}
                >{resp?.msg || 'Save'}</Button>
                <Button
                    className='w-[calc(50%-5px)]'
                    onClick={() => callback(false)}
                >Cancel</Button>
            </div>
        </div>
    );
}

export const ConfirmationChangeAssistant = ({ callback, data }) => {

    const [ name ,setName ] = useState(data?.username);
    const [ password, setPassword ] = useState('');
    const [ seePassword, setSeePassword ] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [ msg, setMsg ] = useState('');

    useEffect(() => {
        setMsg('');
    }, [name, password]);

    const handleSubmit = async () => {
        if (!name || !password) return;

        try {
            const respone = await axiosPrivate.post(
                '/api/resetAssistant',
                { userId: auth?.userId, password: password, username: name }
            );
            setMsg("Settings changed");
            setTimeout(() => { callback(false) }, 1000);
        } catch (err) {
            console.log(err);
            setMsg("Error");
        }
    }

    return (
        <div className="confirmator">
            <div className="w-[400px] h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-lg">
                <h1 className="text-[1.6rem] font-[700] text-center" >Change assistant details</h1>
                <p className="text-center">Change sign-in details for your assistant account.</p>
                <label className='mb-1' htmlFor="oldpwd">Assistant username</label>
                <Input
                    className="px-[5px] w-full h-[40px] bg-[#fbfbfc]"
                    type='text'
                    id='name'
                    autoComplete='off'
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                />
                <label className='mb-1' htmlFor="newpwd1">Assistant password</label>
                <div className='flex gap-[5px] mb-5'>
                    <Input
                        className="px-[5px] w-[calc(100%-45px)] h-[40px] bg-[#fbfbfc]"
                        type={ seePassword ? 'text' : 'password'}
                        id='password'
                        placeholder='Enter new password'
                        autoComplete='off'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                    />
                    <button
                        type="button"
                        className='w-[40px] h-[40px] rounded-md bg-[#020817] text-white flex items-center justify-center'
                        onClick={() => { setSeePassword(prev => !prev) }}
                    >
                        <FaEye size={"18px"}/>
                    </button>
                </div>
                <p className="text-[.75rem] rounded-sm bg-[#020817] text-white p-[.5rem] mb-2">
                    <FaInfoCircle color="#0362fc" size={"17px"}/>
                    While not mandatory, it is still recommended that you use a strong password for your assistant.
                </p>
                <Button
                    disabled={(!name || !password) ? true : false}
                    onClick={() => handleSubmit()}
                    className='w-[calc(50%-5px)]'
                >{msg || "Save"}</Button>
                <Button
                    className='w-[calc(50%-5px)] ml-[10px]'
                    onClick={() => callback(false)}
                >Cancel</Button>
            </div>
        </div>
    );
}

const ColorItem = ({ callback, title, col = '#ffffff' }) => {
    const [color, setColor] = useState(hexToHsva(col || '#ffffff') || '');
    const [hexInput, setHexInput] = useState(col || '#ffffff');
    const [isColorChanged, setIsColorChanged] = useState(false);

    const handleHexInputChange = (event) => {
        const newHexInput = event.target.value;
        const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/.test(newHexInput);

        if (isValidHex) {
            setHexInput(newHexInput);
            setColor(hexToHsva(newHexInput));
            setIsColorChanged(true);
        } else {
            setHexInput(newHexInput);
        }
    };

    const handleColorSelect = () => {
        callback(hsvaToHex(color));
        setIsColorChanged(false);
    };

    return (
        <div className='w-fit h-fit p-[10px] border-[#e4e4e7] border-[1px] rounded-md'>
            <p className='mb-1 text-center w-full py-2'>{title}</p>
            <div className='flex gap-[15px] mb-5 justify-evenly'>
                <Wheel
                    className='border-[2px] border-[#222222] rounded-[50%]'
                    style={{width: 120, height: 120}}
                    width={120}
                    height={120}
                    color={color}
                    onChange={(color) => {
                        setColor({...color, ...color.hsva});
                        setHexInput(hsvaToHex({...color, ...color.hsva}));
                        setIsColorChanged(true);
                    }}
                />
                <ShadeSlider
                    className='border-[1px] border-[#e4e4e7] rounded-sm'
                    hsva={color}
                    style={{width: 30, height: 120}}
                    width={16}
                    height={120}
                    direction="vertical"
                    onChange={(newShade) => {
                        setColor({ ...color, ...newShade });
                        setHexInput(hsvaToHex({ ...color, ...newShade }));
                        setIsColorChanged(true);
                    }}
                />
            </div>
            <div className='flex gap-[5px]'>
                <p
                    className={`w-[40px] h-[40px] rounded-md border-[1px] border-[#e4e4e7]`}
                    style={{ background: hsvaToHex(color) }}
                />
                <Input
                    className={
                        `px-[5px] w-[140px] h-[40px] bg-[#fbfbfc]`
                    }
                    type='text'
                    maxLength={7}
                    value={hexInput}
                    onChange={handleHexInputChange}
                />
                <Button
                    className={`bg-[#333333] hover:bg-[#5d60ef] ${isColorChanged ? 'animate-pulse !bg-red-500' : ''}`}
                    onClick={handleColorSelect}
                >Select</Button>
            </div>
        </div>
    );
}


export const ConfirmationImageAlgorithm = ({ socket, callback }) => {

    const config = useSelector(state => state.config);

    const handleSubmit = (algorithm) => {
        socket?.emit("update", { what: 5, data: ["imageAlgorithm", algorithm]}, (response) => {
            console.log("updated!")
        });
    }

    const algorithms = [
        {
            id: "nr",
            name: "Start number",
            description: "Search for images based on competitor start number. Your competitor image name and competitors start number should match.",
            code: "Number => Number.png",
            warning: "Only use this option if you are 100% sure the start numbers won't change."
        },
        {
            id: "name",
            name: "Firstname + Lastname",
            description: "Search for images based on competitor firstname + lastname.",
            code: "Firstname, Lastname => FirstnameLastname.png"
        }
    ];

    return (
        <div className="confirmator">
            <div className="w-fit h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-lg">
                <h1 className="text-[1.6rem] font-[700] text-center" >Image search algorithm</h1>
                <p className="text-center">Select the algorithm that will be used to search for images.</p>
                <div className="flex gap-2">
                {
                    algorithms.map((item, index) =>
                        <div
                            className="w-[250px] flex flex-col items-center p-3 border border-[#e4e4e7] rounded-md shadow-md mt-5"
                            key={`algo_${index}`}
                        >
                            <p className="text-[1.1rem] font-bold mb-1">{item.name}</p>
                            <p className="mb-1">{item.description}</p>
                            <label className="self-start" htmlFor="example">Example</label>
                            <code
                                className="w-full p-1 bg-[#f9f9fa] rounded-md text-[#222222] text-[.8rem] mb-3 border border-[#e4e4e7]"
                                id="example"
                            >{item.code}</code>
                            {
                                item?.warning && <p className="p-2 w-full bg-[#ffe0e0] border border-[#ff8181] mb-3 rounded-md">{`${item.warning}`}</p>
                            }
                            <Button
                                onClick={() => handleSubmit(item.id)}
                                className={`w-full justify-self-end mt-auto hover:bg-[#5d60ef] ${
                                    config?.imageAlgorithm === item.id ? 'bg-[#5d60ef]' : 'bg-[#333333]'
                                }`}
                            >
                                { config?.imageAlgorithm === item.id ? "Selected" : "Select" }
                            </Button>
                        </div>
                    )
                }
                </div>
                <Button
                    className='w-[25%] bg-[#222222] hover:bg-[#5d60ef] mt-4'
                    onClick={() => callback()}
                >Close</Button>
            </div>
        </div>
    );
}


export const ConfirmationSiluette = ({ socket, callback }) => {

    const config = useSelector(state => state.config);

    const images = [
        '/competitors/Racer.png',
        '/competitors/Biker.png',
        '/competitors/Rider.png'
        // Add more image paths here...
    ];

    const handleSubmit = (path) => {
        socket?.emit("update", { what: 5, data: ["siluette", path]}, (response) => {
            console.log("updated!")
        });
    }

    return (
        <div className="confirmator">
            <div className="w-fit h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-lg">
                <h1 className="text-[1.6rem] font-[700] text-center" >Select siluette</h1>
                <p className="mb-2 max-w-[770px] text-center bg-[#f9f9fa] p-1 rounded-md">If an image is not uploaded for a competitor or system cannot find/load an image for a competitor this placeholder image will take it's place</p>
                <div className="flex gap-2">
                {
                    images.map((image, index) => {
                        const imageName = image.split('/').pop().split('.')[0];
                        return (
                            <div
                                className="w-fit h-fit rounded-md border border-[#e4e4e7] shadow-md"
                                key={`comp_image_traf_${index}`}
                            >
                                <img
                                    className="w-[250px] h-[250px] p-3 pb-0"
                                    src={image}
                                    alt={`Competitor ${index}`}
                                    style={{
                                        backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'20\' viewBox=\'0 0 20 20\'%3E%3Cg fill-rule=\'evenodd\'%3E%3Cg id=\'checker\' fill=\'%23e4e4e7\' fill-opacity=\'0.3\'%3E%3Cpath d=\'M0 0h10v10H0V0zm10 10h10v10H10V10z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
                                        backgroundSize: '20px 20px'
                                    }}
                                />
                                <div className="p-3">
                                    <Button
                                        className={`${config?.siluette == image ? 'bg-[#5d60ef]' : 'bg-[#333333]'} hover:bg-[#5d60ef] w-full`}
                                        onClick={() => handleSubmit(image)}
                                    >{config?.siluette === image ? "Selected" : "Select"}</Button>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
                <Button
                    className='w-[25%] bg-[#222222] hover:bg-[#5d60ef] mt-4'
                    onClick={() => callback()}
                >Close</Button>
            </div>
        </div>
    );
}

export const ConfirmationNewPalette = ({ socket, callback, newPalette = true, colors, reload }) => {

    const [ primary, setPrimary ] = useState(colors?.palette?.primary);
    const [ secondary, setSecondary ] = useState(colors?.palette?.secondary);
    const [ accent, setAccent ] = useState(colors?.palette?.accent);
    const [ even, setEven ] = useState(colors?.palette?.even);
    const [ odd, setOdd ] = useState(colors?.palette?.odd);

    const [ save, setSave ] = useState(false);
    const [ name, setName ] = useState('');

    const handleSubmit = async () => {
        const colorObject = {
            _id: colors?._id,
            name: name ? name : colors?.name,
            colors: { primary: primary, secondary: secondary, accent: accent, even: even, odd: odd }
        };

        try {
            if (newPalette) {
                socket.emit('design', {action: "SAVE", data: colorObject}, (callback) => {
                    reload();
                });
                return;
            }

            socket.emit('design', {action: "EDIT", data: colorObject}, (callback) => {
                reload();
            });

        } catch (err) {
            console.error(err);
        } finally {
            newPalette ? callback(false) : callback(colorObject);
        }
    }

    return (
        <div className="confirmator">
            {
                save ?
                <div className="w-fit h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-lg">
                    <h1 className="text-[1.6rem] font-[700] text-center">One last step</h1>
                    <p className="text-center mb-3">Select a name for your palette</p>
                    <Input
                        className={
                            `px-[5px] w-[220px] h-[40px] bg-[#fbfbfc] mb-3`
                        }
                        type='text'
                        value={name}
                        maxLength={25}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        className='w-[calc(50%-5px)] bg-[#222222] hover:bg-[#5d60ef] mr-[10px]'
                        disabled={name ? false : true}
                        onClick={() => handleSubmit()}
                    >Save</Button>
                    <Button
                        className='w-[calc(50%-5px)] bg-[#222222] hover:bg-[#5d60ef]'
                        onClick={() => callback(false)}
                    >Cancel</Button>
                </div>
                :
                <div className="w-fit h-fit p-[20px] bg-white text-[#222222] rounded-md shadow-lg">
                    <h1 className="text-[1.6rem] font-[700] text-center" >
                        { newPalette ? "Create a new palette" : "Edit palette" }
                    </h1>
                    <p className="text-center mb-3">
                        { newPalette ? "Select a color scheme for your graphics." : "Edit your color scheme." }
                    </p>
                    <div className="w-fit max-w-[870px] h-fit flex flex-wrap gap-[5px] mb-3">
                        <ColorItem title="Primary" callback={(color) => setPrimary(color)} col={primary}/>
                        <ColorItem title="Secondary" callback={(color) => setSecondary(color)} col={secondary}/>
                        <ColorItem title="Accent" callback={(color) => setAccent(color)} col={accent}/>
                        <ColorItem title="Row Even" callback={(color) => setEven(color)} col={even}/>
                        <ColorItem title="Row Odd" callback={(color) => setOdd(color)} col={odd}/>
                    </div>
                    <Button
                        className='w-[25%] bg-[#222222] hover:bg-[#5d60ef]'
                        onClick={() => callback(false)}
                    >Cancel</Button>
                    <Button
                        className='w-[25%] ml-[10px] bg-[#222222] hover:bg-[#5d60ef]'
                        // If new - ask for palette name, else just save.
                        onClick={() => newPalette ? setSave(true) : handleSubmit()}
                    >Save</Button>
                </div>
            }
        </div>
    );
}