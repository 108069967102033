import { useRef, useState, useEffect } from 'react';
import useAuth from "../../hooks/useAuth";
import useMAuth from "../../hooks/useMAuth";

import axios from "../../api/axios";
import { useNavigate, useLocation, Link } from 'react-router-dom';


import { Button } from "../../components/ui/button";
import { GiJesterHat } from "react-icons/gi";
import { Input } from "../../components/ui/input";
import { Terminal } from 'lucide-react';
import { FaEye } from "react-icons/fa";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert"

import "./Login.scss";
import "../../globals.css";

const LOGIN_URL = '/auth';
const MARSHALL_LOGIN_URL = '/mauth';


const Login = () => {

    const { setAuth, persist, setPersist } = useAuth();
    const { setMAuth } = useMAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [seePwd, setSeePwd] = useState(false);

    const [childUser, setChildUser] = useState('');
    const [childPwd, setChildPwd] = useState('');
    const [seePwdChild, setSeePwdChild] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [errMsgMarshall, setErrMsgMarshall] = useState('');
    const [outage, setOutage] = useState('');

    // To focus on username on component load
    useEffect(() => {
        userRef.current.focus();

    }, []);
    // Remove the error message on change
    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);
    // Store persist on change
    useEffect(() => {
        localStorage.setItem('persist', persist);
    }, [persist]);
    // get Outage data
    useEffect(() => {
        const fetchOutage = async () => {
            try {
                const response = await axios.get('/outage');
                setOutage(response.data?.[0] || "");
            } catch (err) {
                console.error(err);
            }
        }
        fetchOutage();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );

            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            const userId = response?.data?.userId;
            const email = response?.data?.email;

            setAuth({ user, roles, accessToken, userId, email });
            setUser('');
            setPwd('');

            navigate("/user/controlPanel");
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server response.');
            } else {
                switch (err.response?.status) {
                    case 402: setErrMsg('Invalid username or password.'); break;
                    case 401: setErrMsg('Unauthorized.'); break;
                    default: setErrMsg('Login failed.');
                }
            }
        }
    }

    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    const handleSubmitMarshall = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                MARSHALL_LOGIN_URL,
                JSON.stringify({ user: childUser, pwd: childPwd }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );

            const accessToken = response?.data?.accessToken;
            const userId = response?.data?.userId;

            setMAuth({ accessToken, userId, user });

            setChildUser('');
            setChildPwd('');

            navigate("/marshall");

        } catch (err) {
            if (!err?.response) {
                setErrMsgMarshall('No server response.');
            } else {
                switch (err.response?.status) {
                    case 402: setErrMsgMarshall('Invalid username or password.'); break;
                    case 401: setErrMsgMarshall('Unauthorized.'); break;
                    default: setErrMsgMarshall('Login failed.');
                }
            }
        }
    }

    return (
        <div className='w-[100vw] h-[100vh] font-geist color-white flex items-center justify-center gap-[10px] relative text-[.9rem]' style={{backgroundImage: `url(/svg/Maze.svg)`}}>
            <div className="absolute top-[10px] left-[10px]">
                <img className="w-[40px] h-auto" src="/logo/RGLogo-new.png" alt="logo" />
            </div>
            <div className='login-container flex justify-center gap-[10px] h-fit w-full'>
                <div className='login-content flex items-center justify-center'>
                    <div className='w-[400px] relative p-[15px] rounded-[7px] bg-white text-[#222222] flex flex-col items-center border-[1px] border-[#e4e4e7] shadow-lg'>
                        <h1 className="text-[2.5rem] font-[700]" >Sign in</h1>
                        <h4>as</h4>
                        <span className='flex items-center gap-[5px] text-[1.2rem]'>
                            <hr className='w-[60px] h-[1px] border-none bg-[#222222]'/>
                            Streamer
                            <hr className='w-[60px] h-[1px] border-none bg-[#222222]'/>
                        </span>
                        <form className="w-[80%]" onSubmit={handleSubmit}>
                            <label htmlFor='username'>Username</label>
                            <Input
                                className="px-[5px] w-full h-[40px] bg-[#fbfbfc]"
                                type='text'
                                id='username'
                                ref={userRef}
                                autoComplete='off'
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />
                            <label htmlFor='password'>Password</label>
                            <div className='flex gap-[5px]'>
                                <Input
                                    className="px-[5px] w-[calc(100%-45px)] h-[40px] bg-[#fbfbfc]"
                                    type={ seePwd ? 'text' : 'password'}
                                    id='password'
                                    ref={userRef}
                                    autoComplete='off'
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />
                                <button
                                    type="button"
                                    className='w-[40px] h-[40px] rounded-md bg-[#020817] text-white flex items-center justify-center'
                                    onClick={() => setSeePwd(prev => !prev)}
                                >
                                    <FaEye size={"18px"}/>
                                </button>
                            </div>
                            <p className='my-2 text-[.8rem] text-[#2563eb]'><Link className="underline" to={"/forgot"}>Forgot password?</Link></p>
                            <div className='flex items-center gap-[5px] my-3'>
                                <input
                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
                                    type='checkbox'
                                    id='persist'
                                    onChange={togglePersist}
                                    checked={persist}
                                />
                                <label
                                    htmlFor="persist"
                                    className='mt-0 ms-2 font-medium text-gray-900'
                                >Remember me</label>
                            </div>
                            <Button
                                className="w-full mb-2"
                                disabled={(!pwd || !user) ? true : false}
                            >Sign in</Button>
                        </form>
                        <p className='mb-2'>Need an account? <Link className="underline" to={"/register"}>Register</Link></p>
                        <div className='Alerts'>
                        {
                            errMsg &&
                            <Alert
                                className="errorAlert mb-2 bg-[#feeeed]"
                                variant="destructive"
                            >
                                <Terminal className="h-4 w-4" />
                                <AlertTitle>Error!</AlertTitle>
                                <AlertDescription>
                                    {errMsg}
                                </AlertDescription>
                            </Alert>
                        }
                        {
                            outage &&
                            <Alert
                                className="outageAlert dark"
                            >
                                <Terminal className="h-4 w-4" />
                                <AlertTitle>Heads up!</AlertTitle>
                                <AlertDescription>
                                    {outage?.message}
                                </AlertDescription>
                            </Alert>
                        }
                        </div>
                    </div>
                </div>
                <div className='login-content flex'>
                    <div className='w-[400px] relative p-[15px] rounded-[7px] bg-white text-[#222222] flex flex-col items-center border-[1px] border-[#e4e4e7] shadow-lg'>
                        <h1 className="text-[2.5rem] font-[700]" >Sign in</h1>
                        <h4>as</h4>
                        <span className='flex items-center gap-[5px] text-[1.2rem]'>
                            <hr className='w-[60px] h-[1px] border-none bg-[#222222]'/>
                            Assistant
                            <hr className='w-[60px] h-[1px] border-none bg-[#222222]'/>
                        </span>
                        <p className='px-4 h-[30px] mt-2 bg-[#020817] text-white rounded-[5px] flex items-center gap-[5px]'><GiJesterHat size={"20px"}/><span>Joker lap functionality</span></p>
                        <form className="w-[80%]" onSubmit={handleSubmitMarshall}>
                            <label>Username</label>
                            <Input
                                type='text'
                                id='username2'
                                className="px-[5px] w-full h-[40px] bg-[#fbfbfc]"
                                autoComplete='off'
                                onChange={(e) => {setChildUser(e.target.value)}}
                                ref={userRef}
                                value={childUser}
                                required
                            />
                            <label>Password</label>
                            <div className='flex gap-[5px]'>
                                <Input
                                    type={ seePwdChild ? 'text' : 'password'}
                                    id='password2'
                                    className="px-[5px] w-[calc(100%-45px)] h-[40px] bg-[#fbfbfc]"
                                    autoComplete='off'
                                    onChange={(e) => {setChildPwd(e.target.value)}}
                                    ref={userRef}
                                    value={childPwd}
                                    required
                                />
                                <button
                                    type="button"
                                    className='w-[40px] h-[40px] rounded-md bg-[#020817] text-white flex items-center justify-center'
                                    onClick={() => setSeePwdChild(prev => !prev)}
                                >
                                    <FaEye size={"18px"}/>
                                </button>
                            </div>
                            <Button
                                className="w-full my-2 mt-[34px]"
                                disabled={(!childPwd || !childUser) ? true : false}
                            >Sign in</Button>
                        </form>
                        {
                            errMsgMarshall &&
                            <Alert
                                className="errorAlert mb-2 bg-[#feeeed]"
                                variant="destructive"
                            >
                                <Terminal className="h-4 w-4" />
                                <AlertTitle>Error!</AlertTitle>
                                <AlertDescription>
                                    {errMsgMarshall}
                                </AlertDescription>
                            </Alert>
                        }
                    </div>
                </div>
            </div>
      </div>
    );
};
export default Login;