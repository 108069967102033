

// Used for graphics to display different classes.
// Changing this array will result of different colors being shown.
export const classNameColors = [
    "#5d60ef",
    "#023a90",
    "#e9679e",
    "#facd06",
    "#f58300",
    "#d9030b"
];

export const classNameColorsM1 = [
    "#1ed4a9",
    "#3640cf",
    "#e9679e",
    "#facd06",
    "#f58300",
    "#d9030b"
];

const makeBackground = {
    light: "#f4f4f4",
    dark: "#050505"
}

export const makeMap = {
    "KTM": {img: "ktm.svg", background: makeBackground.dark},
    "SUZUKI": {img: "suzuki.svg", background: makeBackground.light},
    "HONDA": {img: "honda.svg", background:  makeBackground.dark},
    "KAWASAKI": {img: "kawasaki.jpg", background:  makeBackground.dark},
    "YAMAHA": {img: "yamaha.svg", background:  makeBackground.dark},
    "HUSQVARNA": {img: "husqvarna.png", background:  makeBackground.dark},
    "GAS GAS": {img: "gasgas.png", background:  makeBackground.dark},
    "HUSABERG": {img: "husaberg.png", background: makeBackground.dark}
};

