import { useLocation, Navigate, Outlet } from "react-router-dom";
import useMAuth from "../hooks/useMAuth";

const RequireAuth = () => {
    const { mauth } = useMAuth();
    const location = useLocation();

    const ret = {
        ok: <Outlet />,
        unauthorized: <Navigate to="/unauthorized" state={{ from: location }} replace />,
        login: <Navigate to="/login" state={{ from: location }} replace />
    };

    return mauth?.accessToken ? ret.ok : ret.login;
}

export default RequireAuth;