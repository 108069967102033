
import { WiDaySunny, WiDaySnow,
  WiDayCloudy, WiDayFog, WiDaySprinkle, WiDayRain, WiDayShowers,
  WiDaySnowThunderstorm, WiDayThunderstorm } from "weather-icons-react";

import { BsArrowUpShort, BsArrowDownShort, BsFillCircleFill } from "react-icons/bs";
import { FaFlagCheckered } from "react-icons/fa6";
import { GiJesterHat } from "react-icons/gi";

import { motion } from "framer-motion";

import { Red, Green } from "./Constants";


/*
  ================================================
                  Getters
  ================================================
*/

export const getSuffix = (id) => {
  switch (Number(id)) {
      case 1: return "ST";
      case 2: return "ND";
      case 3: return "RD";
      default: return "TH";
  }
};

export const getJoker = (joker,color, class_name = true) => {
  if (joker) {
    return (
      <motion.div
          className={class_name && "app__vertical-scoreboard2-joker"}
          animate={{x: [-100, 0]}}
      >
        <GiJesterHat color={color} className={class_name ? "app__vertical-scoreboard2-joker-icon" : "joker30px"}/>
      </motion.div>
    );
  }
};
export const getJokerLarge = (joker, color) => {
  if (joker) {
    return (
      <div className="app__v2-joker joker-36">
        <GiJesterHat color={color} className="app__v2-joker-icon"/>
      </div>
    );
  }
};

export const getPositionArrow = (positionChange, size = 20) => {
  if (!positionChange) return;

  switch (positionChange) {
    case "+": return <BsArrowUpShort size={size} color={"#2dcc30"}/>
    case "-": return <BsArrowDownShort size={size} color={"#ff3636"}/>
    case "F": return <FaFlagCheckered size={16} color={"#FFF"}/>
    default:  return <BsFillCircleFill size={10} color={"#FFF"}/>
  }
};

export const getRaceTime = (data) => {
  if (["Finish", "None"].includes(data?.raceDetails?.flag)) {
    return "FINISH";
  }
  if (data?.raceTimer?.data === "1") {
    return "FINAL LAP";
  }
  return `${data?.raceTimer?.name}: %${data?.raceTimer?.data}`;
};

export const getCompetitorTime = (item, raceType, index, fastestLapDriver, topPage) => {
  if (item?.diff) {
    if (["DNF", "DNS", "DQ"].includes(item?.diff)) {
      return item?.diff;
    }
    return `+${item?.diff}`;
  }

  if (raceType === 2 && topPage) {
    return index === 0 ? "Leader" : "-";
  }

  if (raceType !== 2 && parseFloat(item?.best_lap_time?.replaceAll(":", "")) === fastestLapDriver && fastestLapDriver) {
    return item?.best_lap_time.startsWith("00:") ? item?.best_lap_time.substring(3) : item?.best_lap_time;
  }

  return "-";
};

export const getCompetitorTimeByMode = (mode, item, raceType, index, fastestLapDriver, topPage, placeholder) => {
  switch(mode) {
    case "time": return getCompetitorTime(item, raceType, index, fastestLapDriver, placeholder);
    case "speed": return (item?.speed && item?.speed < 1000) ? item?.speed : "-";
    case "gap": {
      const isTopPageAndFirstIndex = topPage && index === 0;
      const isSpecialGap = ["DNF", "DNS", "DQ"].includes(item?.gap);

      if (isTopPageAndFirstIndex) {
        return "Gap";
      }

      if (isSpecialGap) {
        return item?.gap;
      }

      const gap = raceType === 2 ? item?.gap_by_lap : item?.gap_by_time;
      return `+${gap}` || "-";
    }
    case "brand": {
      return (
          <img
            className="!w-full !h-full"
            width={"100%"}
            height={"100%"}
            src={`/makes/${
              item?.make?.charAt(0)?.toUpperCase() + item?.make?.slice(1)?.toLowerCase()
            }.png`}
            onLoad={(event) => event.target.style.display = 'inline-block'}
            onError={(event) => event.target.style.display = 'none'}
          />
      );
    }
    default: return "-";
  }
};

export const getCompetitorDataSidecar = (item, name = "scoreboard", color) => {
  if (item?.isSidecar) {
    return (
      <div className={`app__vertical-${name}-sidecars`}>
          <div className={`app__vertical-${name}-sidecars-flags`}>
              {item?.state ?
                  <img src={`https://flagcdn.com/${item?.state?.toLowerCase()}.svg`} alt="-" />
                  : <div className="img_placeholder" />
              }
              {item?.state2 ?
                  <img src={`https://flagcdn.com/${item?.state2?.toLowerCase()}.svg`} alt="-" />
                  : <div className="img_placeholder" />
              }
          </div>
          <h4
            className={`app__vertical-${name}-sidecars-name`}
            style={{color: color}}
          >
            <p>{item?.firstname?.substring(2, 5)}</p>
            <p>{item?.lastname?.substring(2, 5)}</p>
          </h4>
          <hr/>
      </div>
    );
  }
  return (
    <div className={`app__vertical-${name}-regular`}>
        {item?.state ?
            <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
            : <div className="img_placeholder" />
        }
        <h4
          className="app__vertical-scoreboard2-last-name"
          style={{color: color}}
        >
            {item?.lastname?.substring(0, 3)}
        </h4>
    </div>
  );
};

export const getParsedRaceTime = (raceTime, span = false, name = "app__time2-divided", style = "default") => {

  if (raceTime === "FINISH" && style !== "default")
    return <div
      className="w-full h-full bg-cover flex items-center justify-center font-bold tower_k1_head_inset"
      style={{backgroundImage: "url(/flags/finish.gif)"}}
    />

  if (raceTime.includes("%")) {
    const raceTimeDivided = raceTime.split("%");
    if (span) {
      return (
        <div className={name}>
            <span>{raceTimeDivided[0] || "-"}</span>
            <span>{raceTimeDivided[1] || "-"}</span>
        </div>
      );
    }
    return (
      <div className={name}>
          <h3>{raceTimeDivided[0] || "-"}</h3>
          <h3>{raceTimeDivided[1] || "-"}</h3>
      </div>
    );
  }
  return span ? (<span>{raceTime || "-"}</span>) : (<h3>{raceTime || "-"}</h3>);
};


export const flagToColor = (flag) => {
    switch (flag) {
        case "None": return "#000";
        case "Green":   return Green;
        case "Yellow":  return "#f7e705";
        case "Red":     return Red;
        case "Finish":  return "#d9fbff";
        case "Warmup":  return "#b205f7";
        default: return "#000";
    };
};

export const weatherNumberToData = (number, size = 60, color = '#FFF') => {
  const settings = {size: size, color: color};
  let component, weather_name;

  // yandere dev simulator
  switch (number) {
    case 0:
      component = <WiDaySunny {...settings} />;
      weather_name = "Clear";
      break;
    case 1: case 2: case 3:
      component = <WiDayCloudy {...settings} />;
      weather_name = "Cloudy";
      break;
    case 45: case 48:
      component = <WiDayFog {...settings} />;
      weather_name = "Fog";
      break;
    case 51: case 53: case 55: case 56: case 57:
      component = <WiDaySprinkle {...settings} />;
      weather_name = "Overcast";
      break;
    case 61: case 63: case 65: case 66: case 67:
      component = <WiDayRain {...settings} />;
      weather_name = "Rain";
      break;
    case 71: case 73: case 75: case 77:
      component = <WiDaySnow {...settings} />;
      weather_name = "Snow";
      break;
    case 80: case 81: case 82:
      component = <WiDayShowers {...settings} />;
      weather_name = "Showers";
      break;
    case 85: case 86:
      component = <WiDaySnowThunderstorm {...settings} />;
      weather_name = "Snow storm";
      break;
    case 95: case 96: case 99:
      component = <WiDayThunderstorm {...settings} />;
      weather_name = "Thunderstorm";
      break;
    default:
      component = "";
      weather_name = "";
  };

  return { component, weather_name };
};


// Used for vertical scoreboard
export const getCompetitorUpdateColor = (status) => {
  switch(status) {
    case "+": return "#3ba352";
    case "-": return "#a3433b";
    default: return "#0b0c0f";
  }
};

export const addPlusPrefixIfNeeded = (value) => {
  return ["DQ", "DNF", "DNS"].includes(value) ? value : `+${value}`;
}

// Used only for local testing purposes
export const getRaceBackendAddress = () => {
  const DEV_MODE = true;
  // https://racegraphics.eu:3001  http://localhost:3001
  return DEV_MODE ? "http://localhost:3001" : "https://new.racegraphics.eu:3015";
}

// Image algorithm
export const imageSearchAlgorithm = (algorithm, item) => {
  switch (algorithm) {
    case "nr": return `${item?.nr?.toString()}.png`;
    case "name": return `${item?.firstname?.toLowerCase()}${item?.lastname?.toLowerCase()}.png`;
    default: return null;
  }
}