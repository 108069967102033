
import "./Battles.scss";
import { motion } from "framer-motion";
import { getRaceBackendAddress, imageSearchAlgorithm } from "../../Functions";
import { getDetailsBySidecar } from "../HorizontalScoreboard/HorizontalScoreboard";
import { getSuffix } from "../../Functions";
import { classNameColors } from "../../config/const";

const Battles = ({config, data}) => {

    const palette = config?.palette?.palette;

    switch (config.style) {
        case "v2":
            return (
                <motion.div
                    className="app__battles-main"
                    initial={{opacity: 0, scaleX: 0, translateX: "-50%"}}
                    animate={{opacity: 1, scaleX: 1 ,translateX: "-50%"}}
                    exit={{opacity: 0, scaleX: 0, scaleY: 0, translateX: "-50%"}}
                    transition={{bounce: false}}
                >
                    <motion.div
                        className="app__battles-main-title"
                        initial={{opacity: 0, y: -100}}
                        animate={{opacity: 1, y: 0, transition: {delay: 0.2, bounce: false}}}
                        style={{
                            background: palette?.primary,
                        }}
                    >
                            {`BATTLE FOR ${config?.battleCompetitors?.[0]}${getSuffix(config?.battleCompetitors?.[0])}`}
                    </motion.div>
                    <motion.ol className="app__battles-items">
                        {
                            config?.battleCompetitors?.map((item, index) =>
                                <motion.li
                                    className="app__battles-item"
                                    key={item + ' btl'}
                                >
                                    {
                                        config?.useCompetitorImages &&
                                        <motion.div
                                            className="k1_battle_item_img"
                                            initial={{clipPath: 'inset(100% 0 0 0)'}}
                                            animate={{clipPath: 'inset(0% 0 0 0)', transition: {delay: 0.1}}}
                                            exit={{clipPath: 'inset(100% 0 0 0)'}}
                                        >
                                            <motion.img
                                                key={"k1_battles_image_" + index}
                                                src={
                                                `${getRaceBackendAddress()}/competitor_images/${config?.userId}/${imageSearchAlgorithm(config?.imageAlgorithm, data?.data?.allCompetitors?.[item - 1])}`}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = config?.siluette;
                                                }}
                                                initial={{clipPath: 'inset(100% 0 0 0)', filter: "grayscale(100%)"}}
                                                animate={{
                                                    clipPath: 'inset(0% 0 0 0)',
                                                    filter: "grayscale(0%)",
                                                    transition: {
                                                        clipPath: {delay: 0.2, duration: 0.8},
                                                        filter: {delay: 1, duration: 0.8}
                                                    }
                                                }}
                                            />
                                        </motion.div>
                                    }
                                    <div
                                        className="app__battles-item-top"
                                        style={{ background: palette?.primary }}
                                    >
                                        <div className="app__battles-item-pos">
                                            {item}
                                        </div>
                                        {
                                            !data?.data?.allCompetitors?.[item - 1]?.isSidecar ?
                                            <div className="app__battles-item-flag">
                                                {data?.data?.allCompetitors?.[item - 1]?.state ?
                                                    <img src={`https://flagcdn.com/${data?.data?.allCompetitors?.[item - 1]?.state.toLowerCase()}.svg`} alt="-" />
                                                    : <div className="img_placeholder" />
                                                }
                                            </div>
                                            :
                                            <div className="app__battles-item-flag-sidecar">
                                                {data?.data?.allCompetitors?.[item - 1]?.state ?
                                                    <img src={`https://flagcdn.com/${data?.data?.allCompetitors?.[item - 1]?.state.toLowerCase()}.svg`} alt="-" />
                                                    : <div className="img_placeholder" />
                                                }
                                                {data?.data?.allCompetitors?.[item - 1]?.state2 ?
                                                    <img src={`https://flagcdn.com/${data?.data?.allCompetitors?.[item - 1]?.state2.toLowerCase()}.svg`} alt="-" />
                                                    : <div className="img_placeholder" />
                                                }
                                            </div>
                                        }
                                        {
                                            !data?.data?.allCompetitors?.[item - 1]?.isSidecar ?
                                            <div className="app__battles-item-name">
                                                {`${data?.data?.allCompetitors?.[item - 1]?.lastname}`}
                                            </div>
                                            :
                                            <div className="app__battles-item-sidecar">
                                                <p>{`${data?.data?.allCompetitors?.[item - 1]?.firstname?.slice(2).slice(0, -1)}`}</p>
                                                <p>{`${data?.data?.allCompetitors?.[item - 1]?.lastname?.slice(2)}`}</p>
                                            </div>
                                        }
                                        <div
                                            className="app__battles-item-nr"
                                            style={{ background: palette?.accent }}
                                        >
                                            {`${data?.data?.allCompetitors?.[item - 1]?.nr}`}
                                        </div>
                                    </div>
                                    <div className="app__battles-item-bottom">
                                        {
                                            index === 0 ?
                                            <div
                                                className="app__battles-item-lap-w"
                                                style={{ background: palette?.secondary }}
                                            >
                                                <p>TO LEADER</p>
                                                <span
                                                    style={{color: palette?.accent}}
                                                >{data?.data?.allCompetitors?.[item - 1]?.diff || "LEADER"}</span>
                                            </div>
                                            :
                                            <div className="app__battles-item-info">
                                                <div
                                                    className="app__battles-item-info-left"
                                                    style={{ background: palette?.secondary }}
                                                >
                                                    <p>TO LEADER</p>
                                                    <span
                                                        style={{color: palette?.accent}}
                                                    >{data?.data?.allCompetitors?.[item - 1]?.diff || "-"}</span>
                                                </div>
                                                <hr style={{background: palette?.primary}}/>
                                                <div
                                                    className="app__battles-item-info-right"
                                                    style={{ background: palette?.secondary }}
                                                >
                                                    <p>TO {item - 1}{getSuffix(item - 1)}</p>
                                                    <span
                                                        style={{color: palette?.accent}}
                                                    >{data?.data?.allCompetitors?.[item - 1]?.gap_by_lap || "-"}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </motion.li>
                            )
                        }
                    </motion.ol>

                </motion.div>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_battles"
                >
                    {
                        !config?.useCompetitorImages &&
                        <motion.div
                            className="k1_battles_title"
                            initial={{opacity: 0, scaleX: 0}}
                            animate={{opacity: 1, scaleX: 1, transition: {delay: 0.4}}}
                            exit={{opacity: 0, scaleX: 0}}
                        >
                            {`BATTLE FOR ${config?.battleCompetitors?.[0]}${getSuffix(config?.battleCompetitors?.[0])}`}
                        </motion.div>
                    }
                    <motion.ol
                        className="k1_battles_items"
                        initial={{clipPath: 'inset(0 0 0 100%)'}}
                        animate={{clipPath: 'inset(0 0 0 0%)'}}
                        exit={{clipPath: 'inset(0 0 0 100%)', transition: {delay: 0.3}}}
                    >
                    {
                        config?.battleCompetitors?.map((item, index) =>
                        <motion.li
                            className="k1_battle_item" key={"k2_battle_item_"+index}
                            style={{height: config?.useCompetitorImages ? "290px" : "140px"}}
                        >
                            {
                                config?.useCompetitorImages &&
                                <motion.div
                                    className="k1_battle_item_img"
                                    initial={{clipPath: 'inset(100% 0 0 0)'}}
                                    animate={{clipPath: 'inset(0% 0 0 0)', transition: {delay: 0.1}}}
                                    exit={{clipPath: 'inset(100% 0 0 0)'}}
                                >
                                    <motion.img
                                        key={"k1_battles_image_" + index}
                                        src={
                                            `${getRaceBackendAddress()}/competitor_images/${config?.userId}/${imageSearchAlgorithm(config?.imageAlgorithm, data?.data?.allCompetitors?.[item - 1])}`}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = config?.siluette;
                                        }}
                                        initial={{clipPath: 'inset(100% 0 0 0)', filter: "grayscale(100%)"}}
                                        animate={{
                                            clipPath: 'inset(0% 0 0 0)',
                                            filter: "grayscale(0%)",
                                            transition: {
                                                clipPath: {delay: 0.2, duration: 0.8},
                                                filter: {delay: 1, duration: 0.8}
                                            }
                                        }}
                                    />
                                </motion.div>
                            }
                            <div className="k1_battle_item_head">
                                <div className="k1_battle_pos">
                                    {item}
                                </div>
                                <div className="k1_battle_className" style={{background:
                                    classNameColors?.[config?.competitorClasses?.indexOf(data?.data?.allCompetitors?.[item - 1]?.class)]
                                }}/>
                                <div className="k1_battle_flag">
                                {data?.data?.allCompetitors?.[item - 1]?.state ?
                                    <img src={`https://flagcdn.com/${data?.data?.allCompetitors?.[item - 1]?.state.toLowerCase()}.svg`} alt="-" />
                                    : <div className="img_placeholder" />
                                }
                                </div>
                                <div className="k1_battle_name">
                                    {data?.data?.allCompetitors?.[item - 1]?.lastname}
                                </div>
                                <div className="k1_battle_nr">
                                    {data?.data?.allCompetitors?.[item - 1]?.nr}
                                </div>
                            </div>
                            <div className="k1_battle_item_body">
                                {
                                    index !== 0 &&
                                    <div className="k1_battle_diff">
                                        <p>Gap</p>
                                        <span>{data?.data?.allCompetitors?.[item - 1]?.gap_by_lap || "-"}</span>
                                    </div>
                                }
                                {`+ ${data?.data?.allCompetitors?.[item - 1]?.diff || "-"}`}
                            </div>
                        </motion.li>
                        )
                    }
                    </motion.ol>
                    {
                        config?.useCompetitorImages &&
                        <motion.div
                            className="k1_battles_title"
                            initial={{opacity: 0, scaleX: 0}}
                            animate={{opacity: 1, scaleX: 1, transition: {delay: 0.4}}}
                            exit={{opacity: 0, scaleX: 0}}
                        >
                            {`BATTLE FOR ${config?.battleCompetitors?.[0]}${getSuffix(config?.battleCompetitors?.[0])}`}
                        </motion.div>
                    }
                </motion.div>
            );
        case "m1": {
            return (
                <motion.div className="m1_battles">
                    <motion.div
                        className="m1_battles_title"
                        initial={{opacity: 0, scaleX: 0}}
                        animate={{opacity: 1, scaleX: 1, transition: {delay: 0.4}}}
                        exit={{opacity: 0}}
                    >
                        {`BATTLE FOR ${config?.battleCompetitors?.[0]}${getSuffix(config?.battleCompetitors?.[0])}`}
                    </motion.div>
                    <motion.ol
                        className="m1_battles_items"
                        initial={{clipPath: 'inset(0 0 0 100%)'}}
                        animate={{clipPath: 'inset(0 0 0 0%)'}}
                        exit={{clipPath: 'inset(0 0 0 100%)'}}
                    >
                    {
                        config?.battleCompetitors?.map((item, index) =>
                        <motion.li
                            className="h1_item"
                            key={`h1_key_${item?.position}`}
                        >
                            {
                                config?.useCompetitorImages &&
                                <motion.div className="h1_item_img">
                                    <motion.img
                                        src={
                                            `${getRaceBackendAddress()}/competitor_images/${config?.userId}/${imageSearchAlgorithm(config?.imageAlgorithm, item)}`}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = config?.siluette;
                                        }}
                                        initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                                        animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: { duration: 0.8, delay: 0.8}}}
                                    />
                                </motion.div>
                            }
                            <motion.div className="h1_item_data">
                                <div className="h1_item_data_top_wrapper">
                                    <motion.div
                                        className="h1_item_data_top"
                                        initial={{scaleX: 0, backgroundColor: "#c81111"}}
                                        animate={{scaleX: 1, backgroundColor: "#0c0a0a", transition: { delay: 0.35, duration: 0.2}}}

                                    >
                                        <motion.div
                                            className="h1_item_data_pos"
                                            style={{
                                                backgroundImage: item?.status === "F" ? 'url(/flags/finish.gif)' : "",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: "#000",
                                                    fontSize: "1.5rem",
                                                    textShadow: item?.status !== "F" ? "0 0 0 #000" : "-2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000"
                                                }}
                                            >{item}</p>
                                            {/* {horizontalPosArrowCustom(item?.status)} */}
                                        </motion.div>
                                        <motion.div className="h1_item_data_details">
                                        {
                                            getDetailsBySidecar(data?.data?.allCompetitors?.[item - 1])
                                        }
                                        </motion.div>
                                    </motion.div>
                                </div>
                                <div className="h1_item_data_nr_wrapper">
                                    <motion.div
                                        className="h1_item_data_nr"
                                        initial={{opacity: 0, scaleY: 0, backgroundColor: "#c81111"}}
                                        animate={{opacity: 1, scaleY: 1, backgroundColor: "#222222", transition: { duration: 0.2, delay: 0.1 }}}
                                    >
                                        <p>{data?.data?.allCompetitors?.[item - 1]?.nr}</p>
                                    </motion.div>
                                </div>
                                <div className="h1_item_data_bottom_wrapper">
                                    <motion.div
                                        className="h1_item_data_bottom"
                                        initial={{scaleX: 0, backgroundColor: "#c81111"}}
                                        animate={{scaleX: 1, backgroundColor: "#c0c0c0", transition: { delay: 0.65, duration: 0.2}}}
                                    >
                                        {`+ ${data?.data?.allCompetitors?.[item - 1]?.diff || "-"}`}
                                    </motion.div>
                                </div>
                                {
                                    index !== 0 &&
                                    <motion.div
                                        className="h1_item_data_bottom_gap"
                                        initial={{scaleX: 0}}
                                        animate={{scaleX: 1, transition: { delay: 0.95, duration: 0.2}}}
                                    >
                                        <p>Gap</p>
                                        <span>{data?.data?.allCompetitors?.[item - 1]?.gap_by_lap || "-"}</span>
                                    </motion.div>
                                }
                            </motion.div>
                        </motion.li>
                        )
                    }
                    </motion.ol>
                </motion.div>
            );
        }
    }
};

export default Battles;