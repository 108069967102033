import { AnimatePresence, motion } from "framer-motion";
import "./LeaderWidget.scss";
import { getRaceBackendAddress } from "../../Functions";
import { getDetailsBySidecar } from "../../Graphics/HorizontalScoreboard/HorizontalScoreboard";
import { imageSearchAlgorithm } from "../../Functions";
import { classNameColors } from "../../config/const";

const LeaderWidget = (props) => {

    const palette = props?.config?.palette?.palette;

    switch (props.config?.style) {
        case "v2":
            return (
                <AnimatePresence>
                    <motion.div
                        className="app__leader-v2-main"
                        style={{
                            top: props?.preview ? "30px" : props?.config?.buttons?.raceLeader?.top,
                            left: props?.preview ? "30px" : props?.config?.buttons?.raceLeader?.left
                        }}
                    >
                        <motion.div className="app__leader-v2-title">
                            RACE LEADER
                        </motion.div>
                        <motion.div
                            className="app__leader-v2-content"
                            style={{ background: palette?.primary }}
                        >
                            {
                                props?.config?.useCompetitorImages &&
                                <motion.div className="k1_fastest_img">
                                    <motion.img
                                        src={
                                        `${getRaceBackendAddress()}/competitor_images/${props?.config?.userId}/${imageSearchAlgorithm(props?.config?.imageAlgorithm, props?.data)}`}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = props?.config?.siluette;
                                        }}
                                        initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                                        animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: { duration: 0.8, delay: 0.8}}}
                                    />
                                </motion.div>
                            }
                            <div className="app__leader-v2-nr">
                                {props.data?.nr}
                            </div>
                            {
                                props.data?.isSidecar ?
                                <div className="app__leader-v2-flag-sidecar">
                                    {props.data?.state ?
                                        <img src={`https://flagcdn.com/${props.data?.state.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                    {props.data?.state2 ?
                                        <img src={`https://flagcdn.com/${props.data?.state2.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                </div>
                                :
                                <div className="app__leader-v2-flag">
                                    {props.data?.state ?
                                        <img src={`https://flagcdn.com/${props.data?.state.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                </div>
                            }
                            <div className="app__leader-v2-name">
                                {`${props.data?.firstname} ${props.data?.lastname}`}
                            </div>
                        </motion.div>
                        <motion.div
                            className="app__leader-v2-bottom"
                            style={{ background: palette?.secondary }}
                        >
                            <p>{`GAP FROM 2ND`}</p>
                            <h5 style={{color: palette?.accent}}>{props.fromSecond ? `-${props.fromSecond}` : ""}</h5>
                        </motion.div>
                        <motion.div
                            className="app__leader-v2-bottom"
                            style={{ background: palette?.secondary }}
                        >
                            <p>SPEED</p>
                            <h5 style={{color: palette?.accent}}>{`${props?.data?.speed} KM/H`}</h5>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_fastest"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.raceLeader?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.raceLeader?.left
                    }}
                >
                    {
                        props?.config?.useCompetitorImages &&
                        <motion.div className="k1_fastest_img">
                            <motion.img
                                src={
                                `${getRaceBackendAddress()}/competitor_images/${props?.config?.userId}/${imageSearchAlgorithm(props?.config?.imageAlgorithm, props?.data)}`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = props?.config?.siluette;
                                }}
                                initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                                animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: { duration: 0.8, delay: 0.8}}}
                            />
                        </motion.div>
                    }
                    <motion.div className="k1_fastest_head">
                        <p>RACE LEADER</p>
                    </motion.div>
                    <motion.div className="k1_fastest_body" style={{height: "70px"}}>
                        <div className="k1_fastest_data">
                            <div className="k1_battle_pos">
                                {props?.data?.position}
                            </div>
                            <div className="k1_battle_className" style={{background:
                                classNameColors?.[props?.config?.competitorClasses?.indexOf(props?.data?.class)]
                            }}/>
                            <div className="k1_battle_col">
                                <div className="k1_battle_row">
                                    <div className="k1_battle_name">
                                        <p>{props?.data?.firstname}</p><span>{props?.data?.lastname}</span>
                                    </div>
                                </div>
                                <div className="k1_battle_row">
                                    <div className="k1_fastest_team">
                                        {props?.data?.team}
                                    </div>
                                </div>
                            </div>
                            <div className="k1_battle_nr" style={{background: "#25a931"}}>
                                {props?.data?.nr}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            );
            case "m1":
                return (
                    <motion.li
                        className="h1_item h1_item_fastest"
                        key={`h1_key_${props?.data?.position}`}
                        style={{
                            top: props?.preview ? "30px" : props?.config?.buttons?.raceLeader?.top,
                            left: props?.preview ? "30px" : props?.config?.buttons?.raceLeader?.left
                        }}
                    >
                        {
                            props?.config?.useCompetitorImages &&
                            <motion.div className="h1_item_img">
                                <motion.img
                                    src={
                                        `${getRaceBackendAddress()}/competitor_images/${props?.config?.userId}/${imageSearchAlgorithm(props?.config?.imageAlgorithm, props?.data)}`
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = props?.config?.siluette;
                                    }}
                                    initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                                    animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: { duration: 0.8, delay: 0.8}}}
                                />
                            </motion.div>
                        }
                        <motion.div className="h1_item_data">
                            <div className="h1_item_data_top_wrapper">
                                <motion.div
                                    className="h1_item_data_top"
                                    initial={{scaleX: 0, backgroundColor: "#c81111"}}
                                    animate={{scaleX: 1, backgroundColor: "#0c0a0a", transition: { delay: 0.35, duration: 0.2}}}
                                >
                                    <motion.div className="h1_item_data_pos">
                                        <p>{props?.data?.position}</p>
                                    </motion.div>
                                    <motion.div className="h1_item_data_details">
                                    {
                                        getDetailsBySidecar(props?.data)
                                    }
                                    </motion.div>
                                </motion.div>
                            </div>
                            <div className="h1_item_data_nr_wrapper">
                                <motion.div
                                    className="h1_item_data_nr"
                                    initial={{opacity: 0, scaleY: 0, backgroundColor: "#c81111"}}
                                    animate={{opacity: 1, scaleY: 1, backgroundColor: "#222222", transition: { duration: 0.2, delay: 0.1 }}}
                                >
                                    <p>{props?.data?.nr}</p>
                                </motion.div>
                            </div>
                            <div className="h1_item_data_bottom_wrapper h1_item_fastest_bottom_wrapper">
                                <motion.div
                                    className="h1_item_data_bottom h1_item_leader_bottom"
                                    initial={{scaleX: 0, backgroundColor: "#c81111"}}
                                    animate={{scaleX: 1, backgroundColor: "#c0c0c0", transition: { delay: 0.65, duration: 0.2}}}
                                >
                                    <p>RACE LEADER</p>
                                </motion.div>
                            </div>
                        </motion.div>
                    </motion.li>
                );
    }

};
export default LeaderWidget;